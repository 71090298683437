const resolutionOptions = [
    {
        key: "_x",
        value : "_x",
        text: "Low Resolution"
    },
    {
        key: "_2x",
        value : "_2x",
        text: "Medium Resolution"
    },
    {
        key: "_3x",
        value : "_3x",
        text: "High Resolution"
    },
    {
        key: "_4x",
        value : "_4x",
        text: "HD Resolution"
    }
]

module.exports = resolutionOptions;