const juz = [
  {
    key: 1,
    text: "Juz 1",
    value: 1,
    lower: 1,
    upper : 21
  },
  {
    key: 2,
    text: "Juz 2",
    value: 2,
    lower: 22,
    upper : 41
  },
  {
    key: 3,
    text: "Juz 3",
    value: 3,
    lower: 42,
    upper : 61
  },
  {
    key: 4,
    text: "Juz 4",
    value: 4,
    lower: 62,
    upper : 81
  },
  {
    key: 5,
    text: "Juz 5",
    value: 5,
    lower: 82,
    upper : 101
  },
  {
    key: 6,
    text: "Juz 6",
    value: 6,
    lower: 102,
    upper : 121
  },
  {
    key: 7,
    text: "Juz 7",
    value: 7,
    lower: 122,
    upper : 141
  },
  {
    key: 8,
    text: "Juz 8",
    value: 8,
    lower: 142,
    upper : 161
  },
  {
    key: 9,
    text: "Juz 9",
    value: 9,
    lower: 162,
    upper : 181
  },
  {
    key: 10,
    text: "Juz 10",
    value: 10,
    lower: 182,
    upper : 201
  },
  {
    key: 11,
    text: "Juz 11",
    value: 11,
    lower: 202,
    upper : 221
  },
  {
    key: 12,
    text: "Juz 12",
    value: 12,
    lower: 222,
    upper : 241
  },
  {
    key: 13,
    text: "Juz 13",
    value: 13,
    lower: 242,
    upper : 261
  },
  {
    key: 14,
    text: "Juz 14",
    value: 14,
    lower: 262,
    upper : 281
  },
  {
    key: 15,
    text: "Juz 15",
    value: 15,
    lower: 282,
    upper : 301
  },
  {
    key: 16,
    text: "Juz 16",
    value: 16,
    lower: 302,
    upper : 321
  },
  {
    key: 17,
    text: "Juz 17",
    value: 17,
    lower: 322,
    upper : 341
  },
  {
    key: 18,
    text: "Juz 18",
    value: 18,
    lower: 342,
    upper : 361
  },
  {
    key: 19,
    text: "Juz 19",
    value: 19,
    lower: 362,
    upper : 381
  },
  {
    key: 20,
    text: "Juz 20",
    value: 20,
    lower: 382,
    upper : 401
  },
  {
    key: 21,
    text: "Juz 21",
    value: 21,
    lower: 402,
    upper : 421
  },
  {
    key: 22,
    text: "Juz 22",
    value: 22,
    lower: 422,
    upper : 441
  },
  {
    key: 23,
    text: "Juz 23",
    value: 23,
    lower: 442,
    upper : 461
  },
  {
    key: 24,
    text: "Juz 24",
    value: 24,
    lower: 462,
    upper : 481
  },
  {
    key: 25,
    text: "Juz 25",
    value: 25,
    lower: 482,
    upper : 501
  },
  {
    key: 26,
    text: "Juz 26",
    value: 26,
    lower: 502,
    upper : 521
  },
  {
    key:27,
    text: "Juz 27",
    value: 27,
    lower: 522,
    upper : 541
  },
  {
    key: 28,
    text: "Juz 28",
    value: 28,
    lower: 542,
    upper : 561
  },
  {
    key: 29,
    text: "Juz 29",
    value: 29,
    lower: 562,
    upper : 581
  },
  {
    key: 30,
    text: "Juz 30",
    value: 30,
    lower: 582,
    upper : 604
  }
];

module.exports = juz;
