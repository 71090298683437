import React from "react";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";

function Body() {
  let history = useHistory();

  return (
    <React.Fragment>
      <div className="app-navigation">
      <div className="logo"><a href="https://smartmushaf.com">Smart Mushaf</a></div>
        <div className="surah_name">Disclaimer</div>
      </div>

      <div className="disclaimer">
        <div className="disclaimer-content">
            <h2>Terms and Condition</h2>
          <p>
            All the data (translation, audio) presented in this site are
            obtained from the API provided by an external site{" "}
            <a href="https://alquran.cloud/">https://alquran.cloud</a>. This
            site has no affiliation with the data provider and has no ownership
            for any of the site's content. The Arabic pages are generated from
            the vector files of the Holy Qur'an. We will do our best and
            continuously check to make sure that no image error exists. Please
            refer to a printed physical mushaf if you find any discrepency. This
            site is not responsible/liable in any way shape or form for any content
            presented in this site. This site is for information purpose only. This site only consumes data from external
            source and presents in a way that is easy for user to use. The site also collects data regarding total unique visits, most visited pages etc. By
            visiting the site, you agree to these terms and conditions.
          </p>
          

          <p>
            {" "}
            May Allah Subhanahu wa ta'ala accept our deeds and forgive our
            shortcomings.
          </p>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Body;
