const surahs = [
  { key: 1, text: "Al-Faatiha", value: 1, page: 1 },
  { key: 2, text: "Al-Baqara", value: 2, page: 2 },
  { key: 3, text: "Aal-i-Imraan", value: 3, page: 50 },
  { key: 4, text: "An-Nisaa", value: 4, page: 77 },
  { key: 5, text: "Al-Maaida", value: 5, page: 106 },
  { key: 6, text: "Al-An'aam", value: 6, page: 128 },
  { key: 7, text: "Al-A'raaf", value: 7, page: 151 },
  { key: 8, text: "Al-Anfaal", value: 8, page: 177 },
  { key: 9, text: "At-Tawba", value: 9, page: 187 },
  { key: 10, text: "Yunus", value: 10, page: 208 },
  { key: 11, text: "Hud", value: 11, page: 221 },
  { key: 12, text: "Yusuf", value: 12, page: 235 },
  { key: 13, text: "Ar-Ra'd", value: 13, page: 249 },
  { key: 14, text: "Ibrahim", value: 14, page: 255 },
  { key: 15, text: "Al-Hijr", value: 15, page: 262 },
  { key: 16, text: "An-Nahl", value: 16, page: 267 },
  { key: 17, text: "Al-Israa", value: 17, page: 282 },
  { key: 18, text: "Al-Kahf", value: 18, page: 293 },
  { key: 19, text: "Maryam", value: 19, page: 305 },
  { key: 20, text: "Taa-Haa", value: 20, page: 312 },
  { key: 21, text: "Al-Anbiyaa", value: 21, page: 322 },
  { key: 22, text: "Al-Hajj", value: 22, page: 332 },
  { key: 23, text: "Al-Muminoon", value: 23, page: 342 },
  { key: 24, text: "An-Noor", value: 24, page: 350 },
  { key: 25, text: "Al-Furqaan", value: 25, page: 359 },
  { key: 26, text: "Ash-Shu'araa", value: 26, page: 367 },
  { key: 27, text: "An-Naml", value: 377, page: 377 },
  { key: 28, text: "Al-Qasas", value: 28, page: 385 },
  { key: 29, text: "Al-Ankaboot", value: 29, page: 396 },
  { key: 30, text: "Ar-Room", value: 30, page: 404 },
  { key: 31, text: "Luqman", value: 31, page: 411 },
  { key: 32, text: "As-Sajda", value: 32, page: 415 },
  { key: 33, text: "Al-Ahzaab", value: 33, page: 418 },
  { key: 34, text: "Saba", value: 34, page: 428 },
  { key: 35, text: "Faatir", value: 35, page: 434 },
  { key: 36, text: "Yaseen", value: 36, page: 440 },
  { key: 37, text: "As-Saaffaat", value: 37, page: 446 },
  { key: 38, text: "Saad", value: 38, page: 453 },
  { key: 39, text: "Az-Zumar", value: 39, page: 458 },
  { key: 40, text: "Ghafir", value: 40, page: 467 },
  { key: 41, text: "Fussilat", value: 41, page: 477 },
  { key: 42, text: "Ash-Shura", value: 42, page: 483 },
  { key: 43, text: "Az-Zukhruf", value: 43, page: 489 },
  { key: 44, text: "Ad-Dukhaan", value: 44, page: 496 },
  { key: 45, text: "Al-Jaathiya", value: 45, page: 499 },
  { key: 46, text: "Al-Ahqaf", value: 46, page: 502 },
  { key: 47, text: "Muhammad", value: 47, page: 507 },
  { key: 48, text: "Al-Fath", value: 48, page: 511 },
  { key: 49, text: "Al-Hujuraat", value: 49, page: 515 },
  { key: 50, text: "Qaaf", value: 50, page: 518 },
  { key: 51, text: "Adh-Dhaariyat", value: 51, page: 520 },
  { key: 52, text: "At-Tur", value: 52, page: 523 },
  { key: 53, text: "An-Najm", value: 53, page: 526 },
  { key: 54, text: "Al-Qamar", value: 54, page: 528 },
  { key: 55, text: "Ar-Rahmaan", value: 55, page: 531 },
  { key: 56, text: "Al-Waaqia", value: 56, page: 534 },
  { key: 57, text: "Al-Hadid", value: 57, page: 537 },
  { key: 58, text: "Al-Mujaadila", value: 58, page: 542 },
  { key: 59, text: "Al-Hashr", value: 59, page: 545 },
  { key: 60, text: "Al-Mumtahana", value: 60, page: 549 },
  { key: 61, text: "As-Saff", value: 61, page: 551 },
  { key: 62, text: "Al-Jumu'a", value: 62, page: 553 },
  { key: 63, text: "Al-Munaafiqoon", value: 63, page: 554 },
  { key: 64, text: "At-Taghaabun", value: 64, page: 556 },
  { key: 65, text: "At-Talaaq", value: 65, page: 558 },
  { key: 66, text: "At-Tahrim", value: 66, page: 560 },
  { key: 67, text: "Al-Mulk", value: 67, page: 562 },
  { key: 68, text: "Al-Qalam", value: 68, page: 564 },
  { key: 69, text: "Al-Haaqqa", value: 69, page: 566 },
  { key: 70, text: "Al-Ma'aarij", value: 70, page: 568 },
  { key: 71, text: "Nooh", value: 71, page: 570 },
  { key: 72, text: "Al-Jinn", value: 72, page: 572 },
  { key: 73, text: "Al-Muzzammil", value: 73, page: 574 },
  { key: 74, text: "Al-Muddaththir", value: 74, page: 575 },
  { key: 75, text: "Al-Qiyaama", value: 75, page: 577 },
  { key: 76, text: "Al-Insaan", value: 76, page: 578 },
  { key: 77, text: "Al-Mursalaat", value: 77, page: 580 },
  { key: 78, text: "An-Naba", value: 78, page: 582 },
  { key: 79, text: "An-Naazi'aat", value: 79, page: 583 },
  { key: 80, text: "Abasa", value: 80, page: 585 },
  { key: 81, text: "At-Takwir", value: 81, page: 586 },
  { key: 82, text: "Al-Infitaar", value: 82, page: 587 },
  { key: 83, text: "Al-Mutaffifin", value: 83, page: 587 },
  { key: 84, text: "Al-Inshiqaaq", value: 84, page: 589 },
  { key: 85, text: "Al-Burooj", value: 85, page: 590 },
  { key: 86, text: "At-Taariq", value: 86, page: 591 },
  { key: 87, text: "Al-A'laa", value: 87, page: 591 },
  { key: 88, text: "Al-Ghaashiya", value: 88, page: 592 },
  { key: 89, text: "Al-Fajr", value: 89, page: 593 },
  { key: 90, text: "Al-Balad", value: 90, page: 594 },
  { key: 91, text: "Ash-Shams", value: 91, page: 595 },
  { key: 92, text: "Al-Lail", value: 92, page: 595 },
  { key: 93, text: "Ad-Dhuhaa", value: 93, page: 596 },
  { key: 94, text: "Ash-Sharh", value: 94, page: 596 },
  { key: 95, text: "At-Tin", value: 95, page: 597 },
  { key: 96, text: "Al-Alaq", value: 96, page: 597 },
  { key: 97, text: "Al-Qadr", value: 97, page: 598 },
  { key: 98, text: "Al-Bayyina", value: 98, page: 598 },
  { key: 99, text: "Az-Zalzala", value: 99, page: 599 },
  { key: 100, text: "Al-Aadiyaat", value: 100, page: 599 },
  { key: 101, text: "Al-Qaari'a", value: 101, page: 600 },
  { key: 102, text: "At-Takaathur", value: 102, page: 600 },
  { key: 103, text: "Al-Asr", value: 103, page: 601 },
  { key: 104, text: "Al-Humaza", value: 104, page: 601 },
  { key: 105, text: "Al-Fil", value: 105, page: 601 },
  { key: 106, text: "Quraish", value: 106, page: 602 },
  { key: 107, text: "Al-Maa'un", value: 107, page: 602 },
  { key: 108, text: "Al-Kawthar", value: 108, page: 602 },
  { key: 109, text: "Al-Kaafiroon", value: 109, page: 603 },
  { key: 110, text: "An-Nasr", value: 110, page: 603 },
  { key: 111, text: "Al-Masad", value: 111, page: 603 },
  { key: 112, text: "Al-Ikhlaas", value: 112, page: 604 },
  { key: 113, text: "Al-Falaq", value: 113, page: 604 },
  { key: 114, text: "An-Naas", value: 114, page: 604 },
];

module.exports = surahs;
