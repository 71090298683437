import ImageMapper from "react-image-mapper";
import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Button,
  Checkbox,
  Icon,
  Modal,
  Form,
  Table,
} from "semantic-ui-react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import reciters from "../utility/reciters";
import axios from "axios";
import API from "../utility/api";
import areas from "../utility/areas";
import Footer from "./Footer";
import surahs from "../utility/surahs";
import juz from "../utility/juz";
import page from "../utility/page";
import translations from "../utility/translations";
import { useHistory, useLocation } from "react-router-dom";
import resolutionOptions from "../utility/resolution";
import Home from "./Home";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Body() {
  const QURAN_API = "https://api.quran.com/api/v4/verses"
  let URLQuery = () => {
    
      const { search } = useLocation();
    
      return React.useMemo(() => new URLSearchParams(search), [search]);
    
  };
  let history = useHistory();
  const [hoveredArea, setHoveredArea] = useState(null);
  const [currentAyahIndex, setCurrentAyahIndex] = useState(0);
  const [meaning, setMeaning] = useState({});
  const [playlist, setPlaylist] = useState([]);
  const [currentAyahPlaying, setCurrentAyahPlaying] = useState("");
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [showTranslation, setshowTranslation] = useState(true);
  const [imgWidth, setImgWidth] = useState(1769);
  const [currentSurah, setCurrentSurah] = useState("");
  const [collapseAudio, setCollapseAudio] = useState("block");
  const [audioBodyMaxWidth, setAudioBodyMaxWidth] = useState(600);
  const [collapseAudioMB, setCollapseAudioMB] = useState(10);
  const [currentReciter, setCurrentReciter] = useState("ar.alafasy");
  const [currentTranslation, setCurrentTranslation] = useState("en.hilali");
  const [resolution, setResolution] = useState("_x");
  const [surahNumber, setSurahNumber] = useState(1);
  const [prevsurahNumber, setprevSurahNumber] = useState(1);
  const [pageNumber, setPageNumber] = useState(null);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [showInfo, setshowInfo] = useState(false);
  const [juzNo, setjuzNo] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [bookmarks_list, setBookMarksList] = useState({
    list: [],
  });
  const [currrentBookMarkCustom, setCurrrentBookMarkCustom] = useState("");
  const [historyPossible, setHistoryPossible] = useState(false);
  const [ayahTafsir,setAyahTafsir] = useState("")

  useEffect(()=>{
    if(pageNumber){
      let name = page.filter((page_p)=>{
        return page_p.value === parseInt(pageNumber);
      })
      if(name.length > 0){
        name = name[0]
        name = name.text.split("(");
        name = name[1];
        name = name.split(":");
        name = name[0];
        setCurrentSurah(name);
        let surah_n = surahs.filter((surahpull)=>{
          return surahpull.text == name;
        })
        if(surah_n.length > 0){
          setSurahNumber(surah_n[0].key);

        }
      }

    }
  },[pageNumber])

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  let AyahPlaying = (ayah) => {
    setCurrentAyahPlaying(ayah);
  };

  useEffect(() => {
    if (typeof Storage !== "undefined") {
      setHistoryPossible(true);
      if (localStorage.meta) {
        let from_storage = JSON.parse(localStorage.meta);
        setBookMarksList(from_storage);
      }
    }

    if (window.location.hash === "#/") {
      setshowInfo(true);
    } else {
      setshowInfo(false);
    }
    let current_page = query.get("page_no");
    if (current_page) {
      current_page = parseInt(query.get("page_no"));
      setPageNumber(query.get("page_no"));
      let juz_f = juz.find(
        (item) => current_page >= item.lower && current_page <= item.upper
      );
      setjuzNo(juz_f.value);
    }

    // current translation
    let current_translation = query.get("translation");
    if (current_translation) {
      setCurrentTranslation(current_translation);
    } else {
      current_translation = currentTranslation;
    }

    // current reciter
    let current_reciter = query.get("reciter");
    if (current_reciter) {
      setCurrentReciter(current_reciter);
    } else {
      current_reciter = currentReciter;
    }

    //  curretn rsolution
    let current_resolution = query.get("resolution");
    if (current_resolution) {
      setResolution(current_resolution);
    } else {
      current_resolution = resolution;
    }

    // current show audio player
    let audio_config = query.get("audio_config");
    if (audio_config) {
      setShowAudioPlayer(audio_config === "true");
    } else {
      audio_config = showAudioPlayer;
    }

    // current show translation
    let translation_config = query.get("translation_config");
    if (translation_config) {
      setshowTranslation(translation_config === "true");
    } else {
      translation_config = showTranslation;
    }

    setPlaylist([]);
    setCurrentAyahIndex(0);
    if (window.location.hash !== "#/") {
      axios
        .get(QURAN_API + `/by_page/${current_page}?&translations=131`)
        .then((res) => {
          
            let ayahs = res.data.verses;

            ayahs.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))


            let area_for_page = areas.filter((item) => {
              return item.page === parseInt(current_page);
            });

            let page_coordinates = area_for_page[0];
            let meta = {};
            meta.URL = `https://smartmushaf.com/images/${current_page}.jpg`;
            meta.MAP = {
              name: `page${current_page}`,
              areas: [],
            };
            // meta.translation_name = res.data.data.edition.name;
            ayahs.map((item, index) => {
              let obj = {};
              obj.text = item.translations[0].text.replace(/<.*>/, '');;
              obj.shape = "rect";
              obj.coords = page_coordinates.areas[index];
              obj.name = item.verse_key;
              obj.ayah = item.verse_number;
              obj.ayah_index = index;
              obj.center = scaledCenter(page_coordinates.areas[index]);
              obj.scaledCoords = scaledCoords(page_coordinates.areas[index]);
              meta.MAP.areas.push(obj);
              // setCurrentSurah(item.surah.englishName);
            });
            setMeaning(meta);
            // setSurahNumber(ayahs[0].surah.number);
          
        }).then((nextpagepull) =>{
          setTimeout(()=>{
            axios.get(`https://www.smartmushaf.com/images/${current_page+1}.jpg`)
          },5000)
        })
      axios
        .get(API + `/page/${current_page}/${current_reciter}`)
        .then((res) => {
          if (res.data.code === 200) {
            let playlist_formatted = [];
            res.data.data.ayahs.map((item) => {
              let obj = {};
              obj.title = `Surah ${item.surah.englishName} : ${item.numberInSurah}`;
              obj.src = item.audio;
              playlist_formatted.push(obj);
            });
            setPlaylist(playlist_formatted);
            setCurrentAyahPlaying(playlist_formatted[0].title);
          }
        });
    }
  }, []);

  const [width, setWidth] = useState(
    window.innerWidth > 800 ? 800 : window.innerWidth - 20
  );

  const enterArea = (area) => {
    setCurrentAyahIndex(area.ayah_index);
    setCurrentAyahPlaying(playlist[area.ayah_index].title);
    setHoveredArea(area);
  };

  let scaledCenter = (coords) => {
    let scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
    return [
      (coords[0] * scale + coords[2] * scale) / 2,
      (coords[1] * scale + coords[3] * scale) / 2,
    ];
  };

  let scaledCoords = (coords) => {
    let scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
    return [
      coords[0] * scale,
      coords[1] * scale,
      coords[2] * scale,
      coords[3] * scale,
    ];
  };

  const leaveArea = () => {
    setHoveredArea(null);
  };

  let showPlayer = () => {
    setShowAudioPlayer(true);
  };

  const getTipPosition = (area) => {
    let left_margin = area.center[0];
    if (window.innerWidth < 500) {
      left_margin = 0;
    }
    return {
      top: `${
        area.center[1] + (area.scaledCoords[3] - area.scaledCoords[1] + 20)
      }px`,
      left: `0px`,
    };
  };

  window.addEventListener("resize", function () {
    let screen_width = 800;
    if (window.innerWidth > 800) {
      screen_width = 800;
    } else {
      screen_width = window.innerWidth - 20;
    }
    setWidth(screen_width);
  });

  const handleClickPrevious = () => {
    leaveArea();
    let currentIndex = currentAyahIndex;
    if (currentAyahIndex !== 0) {
      setCurrentAyahIndex(currentIndex - 1);
    }

    if (currentAyahIndex > 0) {
      if (showTranslation) {
        setHoveredArea(meaning.MAP.areas[currentAyahIndex - 1]);
      }
    }

    if (currentAyahIndex > 2) {
      window.scroll({
        top: meaning.MAP.areas[currentAyahIndex - 2].scaledCoords[1] + 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const handleClickNext = () => {
    leaveArea();
    let currentIndex = currentAyahIndex;
    if (currentAyahIndex !== playlist.length - 1) {
      setCurrentAyahIndex(currentIndex + 1);
    }
    if (currentAyahIndex <= playlist.length - 2) {
      if (showTranslation) {
        setHoveredArea(meaning.MAP.areas[currentAyahIndex + 1]);
      }
    }

    if (currentAyahIndex !== 0) {
      window.scroll({
        top: meaning.MAP.areas[currentAyahIndex - 1].scaledCoords[1] + 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const getTafsir = (verse_key) => {
    axios.get(QURAN_API + `by_key/${verse_key}?&tafsirs=168`)
    .then((res)=>{
      if(res.verse.tafsirs.length > 0){
        setAyahTafsir(res.verse.tafsirs[0].text)
      }
    })
  }

  const audioSettingToggle = () => {
    if (playlist.length > 0 && currentAyahPlaying === "") {
      setCurrentAyahPlaying(playlist[0].title);
    }

    if (showAudioPlayer) {
      setShowAudioPlayer(false);
    } else {
      setShowAudioPlayer(true);
    }
  };

  const autotranslationSettingToggle = () => {
    if (showTranslation) {
      setshowTranslation(false);
    } else {
      setshowTranslation(true);
    }
  };

  const audioboxToggle = () => {
    if (collapseAudio === "block") {
      setAudioBodyMaxWidth(75);
      setCollapseAudioMB(0);
      setCollapseAudio("none");
    } else {
      setAudioBodyMaxWidth(600);
      setCollapseAudioMB(10);
      setCollapseAudio("block");
    }
  };

  const selectReciter = (event, { value }) => {
    travel(
      pageNumber,
      currentTranslation,
      value,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const mobileSelectReciter = (e) => {
    travel(
      pageNumber,
      currentTranslation,
      e.target.value,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const [mobileNav, setMobileNav] = useState(
    window.innerWidth < 453 ? "none" : "flex"
  );

  const toggleMobileNav = () => {
    if (mobileNav === "none") {
      setMobileNav("flex");
    } else {
      setMobileNav("none");
    }
  };

  window.addEventListener("resize", function () {
    if (window.innerWidth < 453) {
      setMobileNav("none");
    } else {
      setMobileNav("flex");
    }
  });

  const selectTranslation = (event, { value }) => {
    travel(
      pageNumber,
      value,
      currentReciter,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const mobileSelectTranslation = (e) => {
    travel(
      pageNumber,
      e.target.value,
      currentReciter,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const changeSurah = (event, { value }) => {
    let surah = surahs.find((item) => item.value === value);
    travel(
      surah.page,
      currentTranslation,
      currentReciter,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const mobileChangeSurah = (e) => {
    let surah = surahs.find((item) => item.value === parseInt(e.target.value));
    travel(
      surah.page,
      currentTranslation,
      currentReciter,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const changePage = (event, { value }) => {
    travel(
      value,
      currentTranslation,
      currentReciter,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const mobileChangePage = (e) => {
    travel(
      parseInt(e.target.value),
      currentTranslation,
      currentReciter,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const changeJuz = (event, { value }) => {
    let juz_s = juz.find((item) => item.value === value);
    travel(
      juz_s.lower,
      currentTranslation,
      currentReciter,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const mobileChangeJuz = (e) => {
    let juz_s = juz.find((item) => item.value === parseInt(e.target.value));
    travel(
      juz_s.lower,
      currentTranslation,
      currentReciter,
      resolution,
      showAudioPlayer,
      showTranslation
    );
  };

  const travel = (
    to_page,
    s_translation,
    s_reciter,
    s_resolution,
    audio_config,
    s_translation_config
  ) => {
    history.push("test")
    window.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      "/#/page?page_no=" +
      to_page +
      "&translation=" +
      s_translation +
      "&reciter=" +
      s_reciter +
      "&resolution=" +
      s_resolution +
      "&audio_config=" +
      audio_config +
      "&translation_config=" +
      s_translation_config;
  };

  const NextPage = () => {
    leaveArea();
    let nextPage = parseInt(pageNumber) + 1;
    if (pageNumber !== 604) {
      travel(
        nextPage,
        currentTranslation,
        currentReciter,
        resolution,
        showAudioPlayer,
        showTranslation
      );
    }
  };

  const PreviousPage = () => {
    leaveArea();
    let prevPage = parseInt(pageNumber) - 1;
    if (pageNumber !== 1) {
      travel(
        prevPage,
        currentTranslation,
        currentReciter,
        resolution,
        showAudioPlayer,
        showTranslation
      );
    }
  };

  const changeResolution = (event, { value }) => {
    travel(
      pageNumber,
      currentTranslation,
      currentReciter,
      value,
      showAudioPlayer,
      showTranslation
    );
  };

  const mobileChangeResolution = (e) => {
    travel(
      pageNumber,
      currentTranslation,
      currentReciter,
      e.target.value,
      showAudioPlayer,
      showTranslation
    );
  };

  const imageLoading = () => {
    setImgLoaded(true);
  };

  const setBookMark = (newlist) => {
    if (historyPossible) {
      if (localStorage.surah_meta) {
        alert("last page on " + localStorage.page_no);
      }
      localStorage.meta = JSON.stringify(newlist);
    } else {
      alert(
        "This browser does not support saving bookmark, you will lose bookmarks when you close the browser"
      );
    }
  };

  const currentBookmarkCustomName = (e) => {
    setCurrrentBookMarkCustom(e.target.value);
  };

  const addBookMark = () => {
    let bookmarks_list_c = { ...bookmarks_list };
    let selected_name = currrentBookMarkCustom;
    if (selected_name) {
      selected_name = selected_name.trim();
    } else {
      selected_name = "Page: " + pageNumber;
    }
    let new_mark = {
      page: window.location.href,
      name: selected_name,
      meta: currentAyahPlaying,
    };
    bookmarks_list_c.list.push(new_mark);
    setBookMarksList(bookmarks_list_c);
    setBookMark(bookmarks_list_c);
  };

  const removeBookmark = (item) => {
    let bookmarks_list_c = { ...bookmarks_list };
    let new_list = bookmarks_list_c.list.filter(
      (marks) => marks.name != item.name
    );
    bookmarks_list_c.list = new_list;
    setBookMarksList(bookmarks_list_c);
    setBookMark(bookmarks_list_c);
  };

  return (
    <React.Fragment>
      <div className="body-wrapper">
        <div className="app-navigation">
          <div className="logo">
            <a href="https://smartmushaf.com">Smart Mushaf</a>
          </div>
          <div className="surah_name">Surah {currentSurah}</div>

          <div className="config_options" style={{ display: mobileNav }}>
            <div className="mobile">
              <div className="mobile-surah">
                <label
                  htmlFor="surahs"
                  style={{ marginRight: "5px", fontWeight: "800" }}
                >
                  Surah
                </label>
                <select name="surahs" onChange={mobileChangeSurah}>
                  {surahs
                    ? surahs.map((item, index) => {
                        return (
                          <option
                            value={item.value}
                            key={item.key}
                            selected={item.value === surahNumber}
                          >
                            {item.text}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
              <div className="mobile-juz">
                <label
                  htmlFor="juzz"
                  style={{ marginRight: "5px", fontWeight: "800" }}
                >
                  Juz
                </label>
                <select name="juzz" onChange={mobileChangeJuz}>
                  {surahs && juz
                    ? juz.map((item, index) => {
                        return (
                          <option
                            value={item.value}
                            key={item.key}
                            selected={item.value === juzNo}
                          >
                            {item.text}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
              <div className="mobile-page">
                <label
                  htmlFor="page"
                  style={{ marginRight: "5px", fontWeight: "800" }}
                >
                  Page
                </label>
                <select name="page" onChange={mobileChangePage}>
                  {surahs && page
                    ? page.map((item, index) => {
                        return (
                          <option
                            value={item.value}
                            key={item.key}
                            selected={item.value === parseInt(pageNumber)}
                          >
                            {item.text}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              <div className="mobile-translations">
                <label
                  htmlFor="translation"
                  style={{ marginRight: "5px", fontWeight: "800" }}
                >
                  Translation
                </label>
                <select name="translation" onChange={mobileSelectTranslation}>
                  {translations
                    ? translations.map((item, index) => {
                        return (
                          <option
                            value={item.value}
                            key={item.key}
                            selected={item.value === currentTranslation}
                          >
                            {item.text.length > 20
                              ? item.text.substring(0, 20) + ".."
                              : item.text}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
            <div className="surah">
              {surahs ? (
                <Dropdown
                  placeholder="Surah"
                  selection
                  search
                  options={surahs}
                  value={window.location.hash === "#/" ? "" : parseInt(surahNumber)}
                  onChange={changeSurah}
                />
              ) : (
                ""
              )}
            </div>
            <div className="juz">
              {surahs ? (
                <Dropdown
                  placeholder="Juz"
                  selection
                  options={juz}
                  value={window.location.hash === "#/" ? "" : juzNo}
                  onChange={changeJuz}
                />
              ) : (
                ""
              )}
            </div>
            <div className="page">
              {surahs && page ? (
                <Dropdown
                  placeholder="Page"
                  selection
                  options={page}
                  value={parseInt(pageNumber)}
                  onChange={changePage}
                />
              ) : (
                ""
              )}
            </div>

            <div className="translations">
              {translations ? (
                <Dropdown
                  placeholder="Translation"
                  selection
                  options={translations}
                  onChange={selectTranslation}
                  value={currentTranslation}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="showMoreNav" onClick={toggleMobileNav}>
            &#x2630;
          </div>
        </div>
        {showInfo ? (
          <Home />
        ) : (
          <React.Fragment>
            <div
              className="imgLoader"
              style={{
                display: imgLoaded ? "none" : "flex",
              }}
            >
              <div className="imageLoadingText">
                Image is loading...
              </div>
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>{" "}
            </div>

            {Object.keys(meaning).length > 0 ? (
              <div className="image-container">
                <div className="container">
                  <div className="audio_setting">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ marginRight: "10px" }}>Translation</span>
                      <Checkbox
                        toggle
                        onChange={autotranslationSettingToggle}
                        checked={showTranslation}
                      />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ marginRight: "10px", marginLeft: "10px" }}>
                        Audio
                      </span>
                      <Checkbox
                        toggle
                        onChange={audioSettingToggle}
                        checked={showAudioPlayer}
                      />
                    </div>

                    <div
                      className="bookmark"
                      onClick={openModal}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      <Icon
                        name="star"
                        size="large"
                        color="olive"
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Bookmarks
                    </div>

                    <div className="reciter">
                      {reciters ? (
                        <Dropdown
                          placeholder="Reciter"
                          selection
                          options={reciters}
                          onChange={selectReciter}
                          value={currentReciter}
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mobile-reciter">
                      <label
                        htmlFor="reciter"
                        style={{ marginRight: "5px", fontWeight: "800" }}
                      >
                        Reciter
                      </label>
                      <select name="reciter" onChange={mobileSelectReciter}>
                        {reciters
                          ? reciters.map((item, index) => {
                              return (
                                <option
                                  value={
                                    window.location.hash === "#/"
                                      ? ""
                                      : item.value
                                  }
                                  key={item.key}
                                  selected={item.value === currentReciter}
                                >
                                  {item.text.length > 25
                                    ? item.text.substring(0, 25) + ".."
                                    : item.text}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <ImageMapper
                      src={meaning.URL}
                      map={meaning.MAP}
                      width={width}
                      imgWidth={imgWidth}
                      onClick={(area) => enterArea(area)}
                      onImageClick={(area) => leaveArea(area)}
                      onLoad={imageLoading}
                    />
                  </div>

                  {hoveredArea && showTranslation && (
                    <div
                      className="tooltip"
                      style={{ ...getTipPosition(hoveredArea) }}
                    >
                      <div class="translation-banner-top"></div>
                      <div className="tooltip-content">
                        <div className="tooltip-header">
                          <div>{hoveredArea && hoveredArea.name}</div>
                          <div className="close" onClick={leaveArea}>
                            X
                          </div>
                        </div>
                        <div>{hoveredArea && hoveredArea.text}</div>
                        <div
                          className="bookmark"
                          onClick={openModal}
                          style={{ cursor: "pointer", textAlign: "center" }}
                        >
                          <Icon
                            name="bookmark outline"
                            size="large"
                            color="olive"
                            style={{ marginRight: "5px" }}
                          />{" "}
                          Add Bookmark
                        </div>

                        <div className="ayah-navigation">
                          <Button
                            content="Previous Ayah"
                            icon="left arrow"
                            labelPosition="left"
                            color="olive"
                            size="small"
                            onClick={handleClickPrevious}
                          />
                          <Button
                            content="Next Ayah"
                            icon="right arrow"
                            labelPosition="right"
                            color="olive"
                            size="small"
                            onClick={handleClickNext}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              style={{
                display: imgLoaded ? "block" : "none",
              }}
            >
              <div className="page-navigation">
                <Button
                  content="Previous Page"
                  icon="left arrow"
                  labelPosition="left"
                  color="olive"
                  size="medium"
                  onClick={PreviousPage}
                />
                <Button
                  content="Next Page"
                  icon="right arrow"
                  labelPosition="right"
                  color="olive"
                  size="medium"
                  onClick={NextPage}
                />
              </div>
            </div>

            {showAudioPlayer ? (
              <div className="audioPlayer">
                <div
                  className="audioBody"
                  style={{ maxWidth: `${audioBodyMaxWidth}px` }}
                >
                  <div
                    className="collapse_audio"
                    onClick={audioboxToggle}
                    style={{ marginBottom: `${collapseAudioMB}px` }}
                  >
                    &#x2630;{" "}
                    <span
                      id="minimize-audio"
                      style={{ display: collapseAudio }}
                    >
                      Minimize Audio Player
                    </span>
                  </div>
                  {playlist.length > 0 ? (
                    <div style={{ display: collapseAudio }}>
                      <AudioPlayer
                        header={
                          <div className="audio_setting_player">
                            <div className="audio_meta">
                              {currentAyahPlaying}{" "}
                            </div>

                            <div
                              onClick={audioSettingToggle}
                              className="close-icon"
                            >
                              X
                            </div>
                          </div>
                        }
                        showSkipControls={true}
                        autoPlayAfterSrcChange={true}
                        src={playlist[currentAyahIndex].src}
                        onPlay={(e) => {
                          setCurrentAyahPlaying(
                            playlist[currentAyahIndex].title
                          );
                          if (currentAyahIndex > 0) {
                            if (showTranslation) {
                              let c_area = meaning.MAP.areas[currentAyahIndex];
                              c_area.preFillColor = null;
                            }
                          }
                        }}
                        onClickPrevious={handleClickPrevious}
                        onClickNext={handleClickNext}
                        onEnded={(e) => {
                          if (currentAyahIndex <= playlist.length - 2) {
                            setCurrentAyahIndex(currentAyahIndex + 1);
                            if (showTranslation) {
                              let c_area =
                                meaning.MAP.areas[currentAyahIndex + 1];
                              c_area.preFillColor = "rgba(247, 239, 185,.6)";
                              setHoveredArea(c_area);
                            }
                          }

                          if (currentAyahIndex !== 0) {
                            window.scroll({
                              top:
                                meaning.MAP.areas[currentAyahIndex - 1]
                                  .scaledCoords[1] + 100,
                              left: 0,
                              behavior: "smooth",
                            });
                          }
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        )}
      </div>

      <Modal size="large" open={showModal} onClose={closeModal} closeIcon>
        <Modal.Header>Bookmarks</Modal.Header>
        <Modal.Content scrolling>
          <h3>Bookmark Ayah</h3>
          <p style={{ fontStyle: "italic" }}>
            The bookmarks are saved on your device. If you clear cache the
            bookmarks will be deleted.
          </p>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>Custom Name</label>
                <input
                  placeholder="Custom Name"
                  onChange={currentBookmarkCustomName}
                />
              </Form.Field>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  color: "olive",
                  cursor: "pointer",
                }}
              >
                <Icon name="plus circle" size="big" onClick={addBookMark} />
              </div>
            </Form.Group>
          </Form>
          <h3>Previous Bookmarks</h3>
          <Table celled striped>
            <Table.Body>
              {bookmarks_list.list.length > 0
                ? bookmarks_list.list.map((item, index) => {
                    return (
                      <Table.Row>
                        <Table.Cell>{item.name}</Table.Cell>
                        <Table.Cell>{item.meta}</Table.Cell>
                        <Table.Cell>
                          <a href={item.page} target="_blank">
                            Link
                          </a>
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Icon
                            name="minus circle"
                            size="large"
                            color="red"
                            onClick={() => {
                              removeBookmark(item);
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                : ""}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={closeModal}>
            <Icon name="checkmark" /> OK
          </Button>
        </Modal.Actions>
      </Modal>

      <Footer />
    </React.Fragment>
  );
}

export default Body;
