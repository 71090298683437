const reciters = [
    {
      key: "ar.abdullahbasfar",
      text: "Abdullah Basfar",
      value: "ar.abdullahbasfar",
    },
    {
      key: "ar.abdurrahmaansudais",
      text: "Abdurrahmaan As-Sudais",
      value: "ar.abdurrahmaansudais",
    },
    {
      key: "ar.shaatree",
      text: "Abu Bakr Ash-Shaatree",
      value: "ar.shaatree",
    },
    {
      key: "ar.ahmedajamy",
      text: "Ahmed ibn Ali al-Ajamy",
      value: "ar.ahmedajamy",
    },
    {
      key: "ar.alafasy",
      text: "Mishary Alafasy",
      value: "ar.alafasy",
    },
    {
      key: "ar.hanirifai",
      text: "Hani Rifai",
      value: "ar.hanirifai",
    },
    {
      key: "ar.husary",
      text: "Husary",
      value: "ar.husary",
    },
    {
      key: "ar.hudhaify",
      text: "Hudhaify",
      value: "ar.hudhaify",
    },
    {
      key: "ar.ibrahimakhbar",
      text: "Ibrahim Akhdar",
      value: "ar.ibrahimakhbar",
    },
    {
      key: "ar.mahermuaiqly",
      text: "Maher Al Muaiqly",
      value: "ar.mahermuaiqly",
    },
    {
      key: "ar.minshawi",
      text: "Minshawi",
      value: "ar.minshawi",
    },
    {
      key: "ar.muhammadayyoub",
      text: "Muhammad Ayyoub",
      value: "ar.muhammadayyoub",
    },
    {
      key: "ar.muhammadjibreel",
      text: "Muhammad Jibreel",
      value: "ar.muhammadjibreel",
    },
    {
      key: "ar.saoodshuraym",
      text: "Shuraym",
      value: "ar.saoodshuraym",
    }
  ];
  
  module.exports = reciters;
  