const areas = [
  {
    page: 1,
    areas: [
      [471, 923, 556, 1003],
      [387, 1038, 471, 1118],
      [707, 1145, 785, 1226],
      [328, 1149, 411, 1228],
      [451, 1257, 536, 1344],
      [654, 1375, 737, 1461],
      [538, 1595, 622, 1677],
    ],
  },
  {
    page: 2,
    areas: [
      [1251, 1025, 1333, 1106],
      [1233, 1134, 1312, 1218],
      [1055, 1259, 1135, 1339],
      [707, 1383, 789, 1461],
      [903, 1602, 984, 1682],
    ],
  },
  {
    page: 3,
    areas: [
      [1163, 499, 1302, 624],
      [468, 655, 600, 785],
      [196, 816, 342, 950],
      [1112, 1141, 1255, 1269],
      [517, 1301, 655, 1430],
      [199, 1462, 335, 1590],
      [401, 1624, 540, 1756],
      [462, 1947, 600, 2075],
      [767, 2267, 904, 2399],
      [933, 2430, 1068, 2558],
      [199, 2589, 339, 2723],
    ],
  },
  {
    page: 4,
    areas: [
      [297, 494, 441, 628],
      [818, 654, 965, 787],
      [591, 976, 738, 1113],
      [1178, 1462, 1315, 1592],
      [601, 1626, 736, 1754],
      [1157, 2109, 1297, 2237],
      [988, 2428, 1126, 2560],
      [172, 2587, 313, 2721],
    ],
  },
  {
    page: 6,
    areas: [
      [1479, 816, 1615, 948],
      [267, 979, 411, 1112],
      [1478, 1299, 1614, 1431],
      [850, 1626, 989, 1753],
      [1476, 1948, 1613, 2074],
      [139, 2107, 281, 2236],
      [140, 2431, 278, 2558],
      [138, 2593, 270, 2720],
    ],
  },
  {
    page: 5,
    areas: [
      [176, 821, 322, 952],
      [695, 1633, 832, 1758],
      [172, 1954, 316, 2085],
      [273, 2279, 410, 2408],
      [176, 2602, 316, 2730],
    ],
  },
  {
    page: 7,
    areas: [
      [487, 497, 617, 625],
      [160, 655, 296, 785],
      [713, 979, 849, 1105],
      [876, 1301, 1014, 1429],
      [733, 1463, 870, 1591],
      [794, 1621, 927, 1751],
      [163, 1784, 299, 1912],
      [1457, 2109, 1594, 2235],
      [154, 2111, 294, 2234],
      [1201, 2429, 1328, 2557],
      [156, 2590, 296, 2721],
    ],
  },
  {
    page: 8,
    areas: [
      [1056, 654, 1190, 785],
      [583, 817, 720, 945],
      [1449, 1137, 1587, 1272],
      [164, 1138, 297, 1269],
      [167, 1301, 304, 1428],
      [1449, 1944, 1585, 2074],
      [574, 2108, 710, 2237],
      [624, 2266, 761, 2397],
      [161, 2589, 299, 2719],
    ],
  },
  {
    page: 9,
    areas: [
      [880, 654, 1017, 785],
      [787, 976, 922, 1108],
      [165, 1461, 301, 1590],
      [168, 2587, 306, 2722],
    ],
  },
  {
    page: 10,
    areas: [
      [281, 654, 417, 785],
      [578, 979, 713, 1108],
      [1229, 1300, 1366, 1429],
      [684, 1460, 818, 1592],
      [450, 1625, 585, 1753],
      [308, 1947, 446, 2072],
      [159, 2268, 299, 2398],
      [153, 2590, 291, 2719],
    ],
  },
  {
    page: 11,
    areas: [
      [909, 493, 1042, 625],
      [273, 818, 408, 946],
      [165, 980, 302, 1109],
      [880, 1301, 1015, 1430],
      [1454, 1943, 1592, 2073],
      [1004, 2266, 1138, 2397],
      [161, 2591, 299, 2719],
    ],
  },
  {
    page: 12,
    areas: [
      [167, 334, 306, 465],
      [1163, 655, 1303, 785],
      [1449, 1142, 1580, 1267],
      [783, 1464, 916, 1589],
      [1076, 1784, 1212, 1912],
      [277, 1947, 411, 2073],
      [165, 2592, 302, 2722],
    ],
  },
  {
    page: 13,
    areas: [
      [191, 494, 329, 625],
      [706, 1622, 839, 1752],
      [1213, 1945, 1347, 2074],
      [362, 2428, 494, 2556],
      [186, 2588, 331, 2719],
    ],
  },
  {
    page: 14,
    areas: [
      [184, 666, 301, 782],
      [1466, 1310, 1579, 1424],
      [1171, 1794, 1284, 1908],
      [181, 1959, 297, 2066],
      [179, 2599, 297, 2717],
    ],
  },
  {
    page: 15,
    areas: [
      [175, 496, 311, 624],
      [1444, 817, 1576, 947],
      [256, 1144, 390, 1267],
      [1443, 1624, 1578, 1751],
      [504, 1786, 642, 1912],
      [179, 1956, 296, 2071],
      [1095, 2279, 1208, 2393],
      [172, 2590, 311, 2721],
    ],
  },
  {
    page: 16,
    areas: [
      [589, 1623, 724, 1754],
      [1466, 1943, 1602, 2073],
      [145, 2106, 285, 2238],
      [148, 2591, 292, 2720],
    ],
  },
  {
    page: 17,
    areas: [
      [649, 498, 782, 622],
      [1067, 815, 1201, 945],
      [805, 1137, 941, 1270],
      [1483, 1784, 1615, 1912],
      [1483, 2105, 1623, 2236],
      [1139, 2426, 1278, 2558],
      [132, 2589, 270, 2717],
    ],
  },
  {
    page: 18,
    areas: [
      [861, 817, 989, 946],
      [652, 1304, 780, 1426],
      [155, 1461, 293, 1590],
      [834, 1783, 968, 1913],
      [456, 1945, 593, 2074],
      [518, 2432, 655, 2556],
      [156, 2589, 294, 2719],
    ],
  },
  {
    page: 19,
    areas: [
      [530, 659, 655, 782],
      [931, 979, 1059, 1108],
      [456, 1140, 590, 1266],
      [940, 1465, 1074, 1588],
      [879, 1786, 1018, 1913],
      [1253, 2269, 1384, 2394],
      [171, 2589, 311, 2718],
    ],
  },
  {
    page: 20,
    areas: [
      [702, 496, 835, 623],
      [785, 818, 919, 943],
      [613, 1139, 747, 1267],
      [660, 1461, 799, 1589],
      [757, 1624, 892, 1749],
      [1047, 1945, 1183, 2073],
      [802, 2430, 937, 2555],
      [174, 2590, 312, 2719],
    ],
  },
  {
    page: 21,
    areas: [
      [688, 496, 821, 623],
      [171, 978, 308, 1107],
      [1441, 1461, 1577, 1591],
      [1200, 1624, 1341, 1752],
      [261, 1786, 396, 1914],
      [948, 2433, 1083, 2552],
      [171, 2593, 311, 2719],
    ],
  },
  {
    page: 22,
    areas: [
      [1198, 662, 1336, 789],
      [1078, 1474, 1212, 1600],
      [1117, 2121, 1255, 2252],
      [173, 2611, 312, 2740],
    ],
  },
  {
    page: 23,
    areas: [
      [439, 498, 569, 627],
      [724, 658, 859, 791],
      [841, 986, 977, 1113],
      [908, 1313, 1040, 1441],
      [1134, 1958, 1274, 2090],
      [388, 2287, 522, 2414],
      [518, 2447, 656, 2576],
      [171, 2609, 310, 2738],
    ],
  },
  {
    page: 24,
    areas: [
      [1092, 496, 1229, 633],
      [1457, 826, 1593, 954],
      [1457, 986, 1589, 1116],
      [1063, 1153, 1201, 1279],
      [409, 1477, 546, 1606],
      [1454, 1964, 1594, 2095],
      [795, 2127, 935, 2256],
      [1460, 2447, 1593, 2585],
      [1461, 2613, 1594, 2745],
      [169, 2616, 311, 2745],
    ],
  },

  {
    page: 25,
    areas: [
      [393, 988, 530, 1113],
      [178, 1476, 315, 1601],
      [809, 1801, 941, 1926],
      [183, 2124, 324, 2250],
      [471, 2451, 601, 2574],
      [177, 2608, 320, 2742],
    ],
  },

  {
    page: 26,
    areas: [
      [1190, 661, 1326, 788],
      [1446, 983, 1584, 1113],
      [390, 1148, 527, 1274],
      [1097, 1631, 1235, 1761],
      [540, 2118, 673, 2248],
      [940, 2446, 1077, 2571],
      [181, 2606, 315, 2733],
    ],
  },
  {
    page: 27,
    areas: [
      [726, 1310, 862, 1439],
      [776, 1960, 912, 2089],
      [552, 2121, 688, 2250],
      [437, 2447, 573, 2576],
      [160, 2611, 296, 2740],
    ],
  },

  {
    page: 28,
    areas: [
      [806, 500, 942, 629],
      [1080, 826, 1216, 955],
      [316, 1313, 452, 1442],
      [569, 2291, 705, 2420],
      [179, 2618, 315, 2747],
    ],
  },

  {
    page: 29,
    areas: [
      [809, 1472, 945, 1601],
      [546, 1798, 682, 1927],
      [1113, 2448, 1249, 2577],
      [181, 2610, 317, 2739],
    ],
  },

  {
    page: 30,
    areas: [
      [379, 664, 515, 793],
      [1009, 827, 1145, 956],
      [422, 987, 558, 1116],
      [1271, 1477, 1407, 1606],
      [709, 1640, 845, 1769],
      [175, 2615, 311, 2744],
    ],
  },

  {
    page: 31,
    areas: [
      [965, 823, 1101, 952],
      [1046, 1473, 1182, 1602],
      [173, 1636, 309, 1765],
      [1288, 2285, 1424, 2414],
      [174, 2447, 310, 2576],
      [178, 2610, 314, 2739],
    ],
  },

  {
    page: 32,
    areas: [
      [311, 662, 447, 791],
      [604, 987, 740, 1116],
      [1031, 1312, 1167, 1441],
      [380, 1475, 516, 1604],
      [1002, 1799, 1138, 1928],
      [822, 2123, 958, 2252],
      [1451, 2449, 1587, 2578],
      [178, 2612, 314, 2741],
    ],
  },

  {
    page: 33,
    areas: [
      [402, 503, 538, 632],
      [1458, 987, 1594, 1116],
      [1097, 1794, 1233, 1923],
      [924, 2282, 1060, 2411],
      [168, 2607, 304, 2736],
    ],
  },
  {
    page: 34,
    areas: [
      [648, 658, 784, 787],
      [869, 1800, 1005, 1929],
      [891, 2124, 1027, 2253],
      [171, 2613, 307, 2742],
    ],
  },
  {
    page: 35,
    areas: [
      [183, 660, 319, 789],
      [515, 1475, 651, 1604],
      [176, 1960, 312, 2089],
      [1444, 2445, 1580, 2574],
      [177, 2614, 313, 2743],
    ],
  },
  {
    page: 36,
    areas: [
      [873, 498, 1009, 627],
      [424, 660, 560, 789],
      [819, 826, 955, 955],
      [534, 1473, 670, 1602],
      [1139, 2284, 1275, 2413],
      [185, 2611, 321, 2740],
    ],
  },
  {
    page: 37,
    areas: [
      [150, 988, 286, 1117],
      [955, 1638, 1091, 1767],
      [142, 2612, 278, 2741],
    ],
  },
  {
    page: 38,
    areas: [
      [1434, 824, 1570, 953],
      [965, 1634, 1101, 1763],
      [1438, 2122, 1574, 2251],
      [191, 2611, 327, 2740],
    ],
  },
  {
    page: 39,
    areas: [
      [1240, 500, 1376, 629],
      [1450, 825, 1586, 954],
      [627, 1310, 763, 1439],
      [615, 1471, 751, 1600],
      [509, 1633, 645, 1762],
      [179, 2120, 315, 2249],
      [177, 2283, 313, 2412],
      [184, 2608, 320, 2737],
    ],
  },
  {
    page: 40,
    areas: [
      [330, 1150, 466, 1279],
      [182, 1960, 318, 2089],
      [181, 2609, 317, 2738],
    ],
  },
  {
    page: 41,
    areas: [
      [183, 1313, 319, 1442],
      [1007, 1798, 1143, 1927],
      [695, 2447, 831, 2576],
      [187, 2610, 323, 2739],
    ],
  },
  {
    page: 42,
    areas: [
      [834, 1150, 970, 1279],
      [619, 1472, 755, 1601],
      [990, 2285, 1126, 2414],
      [167, 2610, 303, 2739],
    ],
  },
  {
    page: 43,
    areas: [
      [1117, 822, 1253, 951],
      [554, 1473, 690, 1602],
      [180, 2611, 316, 2740],
    ],
  },
  {
    page: 44,
    areas: [
      [184, 822, 320, 951],
      [739, 1312, 875, 1441],
      [1440, 1798, 1576, 1927],
      [898, 1960, 1034, 2089],
      [189, 2611, 325, 2740],
    ],
  },
  {
    page: 45,
    areas: [
      [829, 825, 965, 954],
      [496, 1475, 632, 1604],
      [1446, 2121, 1582, 2250],
      [173, 2612, 309, 2741],
      [177, 2287, 313, 2416],
    ],
  },
  {
    page: 46,
    areas: [
      [467, 499, 603, 628],
      [838, 986, 974, 1115],
      [1444, 1638, 1580, 1767],
      [921, 2288, 1057, 2417],
      [185, 2615, 321, 2744],
    ],
  },
  {
    page: 47,
    areas: [
      [376, 986, 512, 1115],
      [187, 1144, 323, 1273],
      [970, 1634, 1106, 1763],
      [487, 1795, 623, 1924],
      [460, 2121, 596, 2250],
      [914, 2447, 1050, 2576],
      [183, 2608, 319, 2737],
    ],
  },
  {
    page: 48,
    areas: [[181, 2608, 317, 2737]],
  },
  {
    page: 49,
    areas: [
      [584, 824, 720, 953],
      [769, 1310, 905, 1439],
      [458, 1799, 594, 1928],
      [177, 2609, 313, 2738],
    ],
  },
  {
    page: 50,
    areas: [
      [1360, 724, 1496, 853],
      [604, 724, 740, 853],
      [243, 889, 379, 1018],
      [628, 1211, 764, 1340],
      [720, 1378, 856, 1507],
      [258, 1538, 394, 1667],
      [1006, 2352, 1142, 2481],
      [598, 2518, 734, 2647],
      [159, 2680, 295, 2809],
    ],
  },
  {
    page: 51,
    areas: [
      [535, 500, 671, 629],
      [953, 829, 1089, 958],
      [438, 992, 574, 1121],
      [1183, 1645, 1319, 1774],
      [369, 2135, 505, 2264],
      [161, 2628, 297, 2757],
    ],
  },
  {
    page: 52,
    areas: [
      [1139, 502, 1275, 631],
      [369, 665, 505, 794],
      [622, 992, 758, 1121],
      [707, 1482, 843, 1611],
      [647, 1972, 783, 2101],
      [1060, 2462, 1196, 2591],
      [166, 2626, 302, 2755],
    ],
  },
  {
    page: 53,
    areas: [
      [165, 506, 301, 635],
      [674, 827, 810, 956],
      [1059, 1157, 1195, 1286],
      [416, 1480, 552, 1609],
      [171, 1809, 307, 1938],
      [259, 2298, 395, 2427],
      [167, 2630, 303, 2759],
    ],
  },
  {
    page: 54,
    areas: [
      [697, 667, 833, 796],
      [1454, 990, 1590, 1119],
      [1248, 1159, 1384, 1288],
      [836, 1322, 972, 1451],
      [1191, 1483, 1327, 1612],
      [283, 1650, 419, 1779],
      [726, 2138, 862, 2267],
      [164, 2629, 300, 2758],
    ],
  },
  {
    page: 55,
    areas: [
      [859, 501, 995, 630],
      [404, 829, 540, 958],
      [734, 1156, 870, 1285],
      [377, 1482, 513, 1611],
      [892, 1809, 1028, 1938],
      [879, 1971, 1015, 2100],
      [435, 2300, 571, 2429],
      [162, 2627, 298, 2756],
    ],
  },
  {
    page: 56,
    areas: [
      [170, 341, 306, 470],
      [165, 665, 301, 794],
      [163, 827, 299, 956],
      [169, 1647, 305, 1776],
      [942, 2135, 1078, 2264],
      [876, 2299, 1012, 2428],
      [163, 2626, 299, 2755],
    ],
  },
  {
    page: 57,
    areas: [
      [1081, 504, 1217, 633],
      [1205, 668, 1341, 797],
      [397, 1154, 533, 1283],
      [1000, 1482, 1136, 1611],
      [167, 1645, 303, 1774],
      [287, 1811, 423, 1940],
      [1107, 2135, 1243, 2264],
      [165, 2135, 301, 2264],
      [166, 2625, 302, 2754],
    ],
  },
  {
    page: 58,
    areas: [
      [1095, 504, 1231, 633],
      [159, 502, 295, 631],
      [1106, 1155, 1242, 1284],
      [1162, 1482, 1298, 1611],
      [1217, 1809, 1353, 1938],
      [565, 1973, 701, 2102],
      [1218, 2301, 1354, 2430],
      [366, 2463, 502, 2592],
      [159, 2627, 295, 2756],
    ],
  },
  {
    page: 59,
    areas: [
      [1090, 501, 1226, 630],
      [1056, 829, 1192, 958],
      [1296, 1319, 1432, 1448],
      [1223, 1483, 1359, 1612],
      [177, 1973, 313, 2102],
      [256, 2136, 392, 2265],
      [177, 2626, 313, 2755],
    ],
  },
  {
    page: 60,
    areas: [
      [1098, 828, 1234, 957],
      [932, 1320, 1068, 1449],
      [173, 1481, 309, 1610],
      [175, 2134, 311, 2263],
      [175, 2300, 311, 2429],
      [173, 2627, 309, 2756],
    ],
  },
  {
    page: 61,
    areas: [
      [743, 828, 879, 957],
      [159, 993, 295, 1122],
      [1189, 1481, 1325, 1610],
      [716, 1647, 852, 1776],
      [619, 1809, 755, 1938],
      [378, 1971, 514, 2100],
      [821, 2298, 957, 2427],
      [166, 2627, 302, 2756],
    ],
  },
  {
    page: 62,
    areas: [
      [1004, 504, 1140, 633],
      [1443, 994, 1579, 1123],
      [1140, 1154, 1276, 1283],
      [940, 1320, 1076, 1449],
      [716, 1485, 852, 1614],
      [1447, 1971, 1583, 2100],
      [1091, 2136, 1227, 2265],
      [979, 2461, 1115, 2590],
      [171, 2626, 307, 2755],
    ],
  },
  {
    page: 63,
    areas: [
      [168, 503, 304, 632],
      [1217, 828, 1353, 957],
      [1454, 1481, 1590, 1610],
      [272, 1646, 408, 1775],
      [758, 1973, 894, 2102],
      [592, 2297, 728, 2426],
      [438, 2464, 574, 2593],
      [166, 2626, 302, 2755],
    ],
  },
  {
    page: 64,
    areas: [
      [1452, 502, 1588, 631],
      [856, 992, 992, 1121],
      [343, 1156, 479, 1285],
      [556, 1809, 692, 1938],
      [1060, 2137, 1196, 2266],
      [478, 2463, 614, 2592],
      [170, 2626, 306, 2755],
    ],
  },
  {
    page: 65,
    areas: [
      [170, 504, 306, 633],
      [479, 994, 615, 1123],
      [173, 1482, 309, 1611],
      [169, 1973, 305, 2102],
      [722, 2462, 858, 2591],
      [170, 2626, 306, 2755],
    ],
  },
  {
    page: 66,
    areas: [
      [874, 503, 1010, 632],
      [644, 667, 780, 796],
      [1241, 993, 1377, 1122],
      [1446, 1319, 1582, 1448],
      [508, 1483, 644, 1612],
      [409, 1645, 545, 1774],
      [1449, 1973, 1585, 2102],
      [538, 2135, 674, 2264],
      [1028, 2463, 1164, 2592],
      [1445, 2626, 1581, 2755],
      [172, 2626, 308, 2755],
    ],
  },
  {
    page: 67,
    areas: [
      [521, 503, 657, 632],
      [496, 827, 632, 956],
      [757, 1319, 893, 1448],
      [804, 1646, 940, 1775],
      [1448, 1971, 1584, 2100],
      [166, 1971, 302, 2100],
      [1449, 2301, 1585, 2430],
      [167, 2626, 303, 2755],
    ],
  },
  {
    page: 68,
    areas: [
      [250, 340, 386, 469],
      [859, 667, 995, 796],
      [436, 828, 572, 957],
      [460, 1320, 596, 1449],
      [819, 1809, 955, 1938],
      [563, 2137, 699, 2266],
      [457, 2460, 593, 2589],
      [172, 2626, 308, 2755],
    ],
  },
  {
    page: 69,
    areas: [
      [175, 501, 311, 630],
      [378, 665, 514, 794],
      [885, 1155, 1021, 1284],
      [1442, 2134, 1578, 2263],
      [173, 2625, 309, 2754],
    ],
  },
  {
    page: 70,
    areas: [
      [722, 1481, 858, 1610],
      [301, 1809, 437, 1938],
      [829, 2461, 965, 2590],
      [152, 2627, 288, 2756],
    ],
  },
  {
    page: 71,
    areas: [
      [532, 338, 668, 467],
      [524, 829, 660, 958],
      [561, 1155, 697, 1284],
      [624, 1481, 760, 1610],
      [1462, 1809, 1598, 1938],
      [158, 1807, 294, 1936],
      [152, 2300, 288, 2429],
      [152, 2626, 288, 2755],
    ],
  },
  {
    page: 72,
    areas: [
      [1459, 502, 1595, 631],
      [651, 991, 787, 1120],
      [799, 1317, 935, 1446],
      [334, 1481, 470, 1610],
      [157, 1810, 293, 1939],
      [1206, 2134, 1342, 2263],
      [162, 2299, 298, 2428],
      [156, 2626, 292, 2755],
    ],
  },
  {
    page: 73,
    areas: [
      [617, 501, 753, 630],
      [170, 665, 306, 794],
      [1293, 1156, 1429, 1285],
      [743, 1319, 879, 1448],
      [983, 1644, 1119, 1773],
      [268, 2135, 404, 2264],
      [170, 2625, 306, 2754],
    ],
  },
  {
    page: 74,
    areas: [
      [801, 666, 937, 795],
      [622, 828, 758, 957],
      [169, 1318, 305, 1447],
      [748, 1645, 884, 1774],
      [998, 2135, 1134, 2264],
      [167, 2626, 303, 2755],
    ],
  },
  {
    page: 75,
    areas: [
      [774, 664, 910, 793],
      [428, 993, 564, 1122],
      [364, 1156, 500, 1285],
      [1031, 1481, 1167, 1610],
      [168, 1809, 304, 1938],
      [1268, 2135, 1404, 2264],
      [714, 2463, 850, 2592],
      [166, 2626, 302, 2755],
    ],
  },
  {
    page: 76,
    areas: [
      [167, 946, 303, 1075],
      [442, 1106, 578, 1235],
      [594, 1269, 730, 1398],
      [371, 1596, 507, 1725],
      [959, 2250, 1095, 2379],
      [163, 2413, 299, 2542],
    ],
  },
  {
    page: 77,
    areas: [
      [616, 808, 752, 937],
      [1115, 1129, 1251, 1258],
      [317, 1448, 453, 1577],
      [1166, 1768, 1302, 1897],
      [294, 1927, 430, 2056],
      [158, 2566, 294, 2695],
    ],
  },
  {
    page: 78,
    areas: [
      [1218, 666, 1354, 795],
      [1451, 993, 1587, 1122],
      [166, 1157, 302, 1286],
      [500, 1483, 636, 1612],
      [163, 2626, 299, 2755],
    ],
  },
  {
    page: 79,
    areas: [
      [1447, 1973, 1583, 2102],
      [172, 2300, 308, 2429],
      [171, 2626, 307, 2755],
    ],
  },
  {
    page: 80,
    areas: [
      [1443, 828, 1579, 957],
      [1443, 1154, 1579, 1283],
      [895, 1483, 1031, 1612],
      [577, 1971, 713, 2100],
      [177, 2626, 313, 2755],
    ],
  },
  {
    page: 81,
    areas: [
      [906, 666, 1042, 795],
      [1242, 992, 1378, 1121],
      [1019, 1318, 1155, 1447],
      [173, 2626, 309, 2755],
    ],
  },
  {
    page: 82,
    areas: [
      [1240, 1159, 1376, 1288],
      [1443, 2469, 1579, 2598],
      [181, 2633, 317, 2762],
    ],
  },
  {
    page: 83,
    areas: [
      [601, 504, 737, 633],
      [610, 668, 746, 797],
      [840, 1159, 976, 1288],
      [1284, 1485, 1420, 1614],
      [171, 1650, 307, 1779],
      [1261, 2306, 1397, 2435],
      [167, 2633, 303, 2762],
    ],
  },
  {
    page: 84,
    areas: [
      [685, 1159, 821, 1288],
      [1445, 1650, 1581, 1779],
      [814, 2304, 950, 2433],
      [176, 2633, 312, 2762],
    ],
  },
  {
    page: 85,
    areas: [
      [1319, 668, 1455, 797],
      [522, 832, 658, 961],
      [1136, 1159, 1272, 1288],
      [625, 1323, 761, 1452],
      [1177, 1651, 1313, 1780],
      [961, 2469, 1097, 2598],
      [163, 2633, 299, 2762],
    ],
  },
  {
    page: 86,
    areas: [
      [167, 338, 303, 467],
      [1214, 1160, 1350, 1289],
      [1154, 1652, 1290, 1781],
      [1459, 1977, 1595, 2106],
      [992, 2141, 1128, 2270],
      [974, 2306, 1110, 2435],
      [164, 2634, 300, 2763],
    ],
  },
  {
    page: 87,
    areas: [
      [158, 332, 294, 461],
      [248, 504, 384, 633],
      [157, 832, 293, 961],
      [1463, 1160, 1599, 1289],
      [1010, 1487, 1146, 1616],
      [322, 1814, 458, 1943],
      [1322, 2306, 1458, 2435],
      [159, 2633, 295, 2762],
    ],
  },
  {
    page: 88,
    areas: [
      [1061, 832, 1197, 961],
      [1196, 1158, 1332, 1287],
      [974, 1488, 1110, 1617],
      [896, 1815, 1032, 1944],
      [765, 2305, 901, 2434],
      [177, 2632, 313, 2761],
    ],
  },
  {
    page: 89,
    areas: [
      [617, 668, 753, 797],
      [1028, 831, 1164, 960],
      [163, 832, 299, 961],
      [1022, 1322, 1158, 1451],
      [1146, 1487, 1282, 1616],
      [706, 1652, 842, 1781],
      [1249, 1978, 1385, 2107],
      [1089, 2307, 1225, 2436],
      [163, 2633, 299, 2762],
    ],
  },
  {
    page: 90,
    areas: [
      [1305, 831, 1441, 960],
      [958, 1160, 1094, 1289],
      [329, 1813, 465, 1942],
      [1100, 2470, 1236, 2599],
      [158, 2633, 294, 2762],
    ],
  },
  {
    page: 91,
    areas: [
      [1052, 504, 1188, 633],
      [1447, 996, 1583, 1125],
      [890, 1159, 1026, 1288],
      [172, 1651, 308, 1780],
      [1048, 2141, 1184, 2270],
      [784, 2469, 920, 2598],
      [178, 2633, 314, 2762],
    ],
  },
  {
    page: 92,
    areas: [
      [791, 504, 927, 633],
      [344, 832, 480, 961],
      [161, 1323, 297, 1452],
      [165, 1979, 301, 2108],
      [164, 2633, 300, 2762],
    ],
  },
  {
    page: 93,
    areas: [
      [816, 1488, 952, 1617],
      [347, 1814, 483, 1943],
      [172, 2632, 308, 2761],
    ],
  },
  {
    page: 94,
    areas: [
      [629, 832, 765, 961],
      [766, 996, 902, 1125],
      [923, 1488, 1059, 1617],
      [160, 1651, 296, 1780],
      [159, 1814, 295, 1943],
      [415, 2306, 551, 2435],
      [156, 2632, 292, 2761],
    ],
  },
  {
    page: 95,
    areas: [
      [168, 1488, 304, 1617],
      [469, 1978, 605, 2107],
      [1260, 2469, 1396, 2598],
      [169, 2635, 305, 2764],
    ],
  },
  {
    page: 96,
    areas: [
      [412, 340, 548, 469],
      [1139, 667, 1275, 796],
      [812, 996, 948, 1125],
      [443, 1323, 579, 1452],
      [1258, 1651, 1394, 1780],
      [868, 1815, 1004, 1944],
      [309, 1978, 445, 2107],
      [163, 2634, 299, 2763],
    ],
  },
  {
    page: 97,
    areas: [
      [293, 668, 429, 797],
      [1273, 1159, 1409, 1288],
      [1449, 1487, 1585, 1616],
      [928, 1651, 1064, 1780],
      [776, 1815, 912, 1944],
      [172, 2306, 308, 2435],
      [171, 2469, 307, 2598],
      [175, 2633, 311, 2762],
    ],
  },
  {
    page: 98,
    areas: [
      [559, 667, 695, 796],
      [383, 997, 519, 1126],
      [307, 1323, 443, 1452],
      [338, 1652, 474, 1781],
      [1280, 1979, 1416, 2108],
      [161, 2632, 297, 2761],
    ],
  },
  {
    page: 99,
    areas: [
      [896, 832, 1032, 961],
      [911, 1323, 1047, 1452],
      [495, 1487, 631, 1616],
      [168, 1978, 304, 2107],
      [170, 2141, 306, 2270],
      [998, 2469, 1134, 2598],
      [169, 2633, 305, 2762],
    ],
  },
  {
    page: 100,
    areas: [
      [297, 832, 433, 961],
      [1117, 1488, 1253, 1617],
      [1303, 1814, 1439, 1943],
      [301, 1816, 437, 1945],
      [682, 2142, 818, 2271],
      [148, 2636, 284, 2765],
    ],
  },
  {
    page: 101,
    areas: [
      [169, 833, 305, 962],
      [1320, 1322, 1456, 1451],
      [613, 1486, 749, 1615],
      [472, 1814, 608, 1943],
      [167, 1977, 303, 2106],
      [860, 2470, 996, 2599],
      [177, 2633, 313, 2762],
    ],
  },
  {
    page: 102,
    areas: [
      [1068, 505, 1204, 634],
      [731, 667, 867, 796],
      [683, 1159, 819, 1288],
      [486, 1324, 622, 1453],
      [396, 1650, 532, 1779],
      [167, 2306, 303, 2435],
      [170, 2632, 306, 2761],
    ],
  },
  {
    page: 103,
    areas: [
      [920, 668, 1056, 797],
      [1138, 832, 1274, 961],
      [1091, 1323, 1227, 1452],
      [1456, 1486, 1592, 1615],
      [764, 1651, 900, 1780],
      [1327, 1978, 1463, 2107],
      [339, 2142, 475, 2271],
      [168, 2634, 304, 2763],
    ],
  },
  {
    page: 104,
    areas: [
      [936, 832, 1072, 961],
      [1141, 1160, 1277, 1289],
      [1463, 1487, 1599, 1616],
      [407, 1651, 543, 1780],
      [1468, 1977, 1604, 2106],
      [1042, 2143, 1178, 2272],
      [848, 2305, 984, 2434],
      [164, 2634, 300, 2763],
    ],
  },
  {
    page: 105,
    areas: [
      [527, 1160, 663, 1289],
      [1174, 1651, 1310, 1780],
      [445, 2142, 581, 2271],
      [147, 2306, 283, 2435],
      [153, 2633, 289, 2762],
    ],
  },
  {
    page: 106,
    areas: [
      [162, 908, 298, 1037],
      [1143, 1828, 1279, 1957],
      [161, 2630, 297, 2759],
    ],
  },
  {
    page: 107,
    areas: [
      [138, 1323, 274, 1452],
      [1488, 1979, 1624, 2108],
      [133, 2633, 269, 2762],
    ],
  },
  {
    page: 108,
    areas: [
      [167, 1487, 303, 1616],
      [1193, 1978, 1329, 2107],
      [727, 2470, 863, 2599],
      [161, 2634, 297, 2763],
    ],
  },
  {
    page: 109,
    areas: [
      [1210, 504, 1346, 633],
      [1067, 995, 1203, 1124],
      [312, 1978, 448, 2107],
      [175, 2633, 311, 2762],
    ],
  },
  {
    page: 110,
    areas: [
      [712, 832, 848, 961],
      [1223, 1487, 1359, 1616],
      [1473, 1979, 1609, 2108],
      [160, 2633, 296, 2762],
    ],
  },
  {
    page: 111,
    areas: [
      [788, 832, 924, 961],
      [1168, 1323, 1304, 1452],
      [509, 1651, 645, 1780],
      [1002, 1979, 1138, 2108],
      [1032, 2306, 1168, 2435],
      [159, 2632, 295, 2761],
    ],
  },
  {
    page: 112,
    areas: [
      [377, 504, 513, 633],
      [1157, 833, 1293, 962],
      [1460, 1159, 1596, 1288],
      [674, 1487, 810, 1616],
      [1102, 1815, 1238, 1944],
      [409, 1979, 545, 2108],
      [173, 2143, 309, 2272],
      [172, 2634, 308, 2763],
    ],
  },
  {
    page: 113,
    areas: [
      [294, 995, 430, 1124],
      [1462, 1816, 1598, 1945],
      [802, 1977, 938, 2106],
      [872, 2306, 1008, 2435],
      [161, 2634, 297, 2763],
    ],
  },
  {
    page: 114,
    areas: [
      [970, 503, 1106, 632],
      [1465, 833, 1601, 962],
      [848, 996, 984, 1125],
      [698, 1323, 834, 1452],
      [166, 2634, 302, 2763],
    ],
  },
  {
    page: 115,
    areas: [
      [807, 831, 943, 960],
      [760, 1159, 896, 1288],
      [499, 1978, 635, 2107],
      [174, 2633, 310, 2762],
    ],
  },
  {
    page: 116,
    areas: [
      [386, 668, 522, 797],
      [704, 996, 840, 1125],
      [668, 1979, 804, 2108],
      [373, 2471, 509, 2600],
      [172, 2634, 308, 2763],
    ],
  },
  {
    page: 117,
    areas: [
      [1247, 669, 1383, 798],
      [154, 996, 290, 1125],
      [316, 1324, 452, 1453],
      [1090, 1980, 1226, 2109],
      [463, 2142, 599, 2271],
      [376, 2306, 512, 2435],
      [157, 2634, 293, 2763],
    ],
  },
  {
    page: 118,
    areas: [
      [1238, 504, 1374, 633],
      [235, 669, 371, 798],
      [738, 1160, 874, 1289],
      [1458, 1486, 1594, 1615],
      [739, 1651, 875, 1780],
      [1245, 1979, 1381, 2108],
      [166, 2634, 302, 2763],
    ],
  },
  {
    page: 119,
    areas: [
      [511, 503, 647, 632],
      [1081, 995, 1217, 1124],
      [424, 1323, 560, 1452],
      [1460, 1978, 1596, 2107],
      [854, 2306, 990, 2435],
      [169, 2633, 305, 2762],
    ],
  },
  {
    page: 120,
    areas: [
      [1167, 669, 1303, 798],
      [161, 1159, 297, 1288],
      [531, 1651, 667, 1780],
      [163, 1815, 299, 1944],
      [1113, 2470, 1249, 2599],
      [162, 2633, 298, 2762],
    ],
  },
  {
    page: 121,
    areas: [
      [499, 667, 635, 796],
      [165, 996, 301, 1125],
      [851, 1324, 987, 1453],
      [168, 1652, 304, 1781],
      [1457, 2142, 1593, 2271],
      [172, 2634, 308, 2763],
    ],
  },
  {
    page: 122,
    areas: [
      [1194, 668, 1330, 797],
      [368, 832, 504, 961],
      [779, 1159, 915, 1288],
      [642, 1322, 778, 1451],
      [1010, 1652, 1146, 1781],
      [552, 1815, 688, 1944],
      [166, 2634, 302, 2763],
    ],
  },
  {
    page: 123,
    areas: [
      [445, 504, 581, 633],
      [334, 833, 470, 962],
      [963, 1160, 1099, 1289],
      [1469, 1651, 1605, 1780],
      [954, 1979, 1090, 2108],
      [163, 2634, 299, 2763],
    ],
  },

  {
    page: 124,
    areas: [
      [1164, 668, 1300, 797],
      [1165, 1159, 1301, 1288],
      [1102, 1325, 1238, 1454],
      [959, 1487, 1095, 1616],
      [981, 1815, 1117, 1944],
      [266, 2142, 402, 2271],
      [168, 2306, 304, 2435],
      [172, 2635, 308, 2764],
    ],
  },
  {
    page: 125,
    areas: [
      [1018, 668, 1154, 797],
      [750, 996, 886, 1125],
      [431, 1815, 567, 1944],
      [281, 2307, 417, 2436],
      [150, 2633, 286, 2762],
    ],
  },
  {
    page: 126,
    areas: [
      [801, 505, 937, 634],
      [1232, 1816, 1368, 1945],
      [355, 1979, 491, 2108],
      [1258, 2470, 1394, 2599],
      [165, 2634, 301, 2763],
    ],
  },
  {
    page: 127,
    areas: [
      [1161, 668, 1297, 797],
      [154, 833, 290, 962],
      [231, 1487, 367, 1616],
      [736, 1979, 872, 2108],
      [514, 2143, 650, 2272],
      [152, 2470, 288, 2599],
      [159, 2634, 295, 2763],
    ],
  },

  {
    page: 128,
    areas: [
      [380, 844, 516, 973],
      [1260, 1174, 1396, 1303],
      [760, 1338, 896, 1467],
      [588, 1503, 724, 1632],
      [230, 1667, 366, 1796],
      [1275, 2326, 1411, 2455],
      [535, 2489, 671, 2618],
      [155, 2654, 291, 2783],
    ],
  },
  {
    page: 129,
    areas: [
      [1146, 511, 1282, 640],
      [153, 675, 289, 804],
      [1170, 1004, 1306, 1133],
      [1465, 1498, 1601, 1627],
      [1463, 1663, 1599, 1792],
      [847, 1992, 983, 2121],
      [876, 2156, 1012, 2285],
      [760, 2320, 896, 2449],
      [1320, 2650, 1456, 2779],
      [151, 2650, 287, 2779],
    ],
  },
  {
    page: 130,
    areas: [
      [1259, 840, 1395, 969],
      [382, 1004, 518, 1133],
      [1460, 1334, 1596, 1463],
      [997, 1498, 1133, 1627],
      [1006, 1662, 1142, 1791],
      [997, 1828, 1133, 1957],
      [980, 2321, 1116, 2450],
      [711, 2485, 847, 2614],
      [155, 2650, 291, 2779],
    ],
  },
  {
    page: 131,
    areas: [
      [1063, 511, 1199, 640],
      [1204, 676, 1340, 805],
      [1446, 1005, 1582, 1134],
      [707, 1335, 843, 1464],
      [1445, 1663, 1581, 1792],
      [513, 1828, 649, 1957],
      [1446, 2321, 1582, 2450],
      [166, 2650, 302, 2779],
    ],
  },
  {
    page: 132,
    areas: [
      [1243, 510, 1379, 639],
      [245, 675, 381, 804],
      [173, 1005, 309, 1134],
      [284, 1334, 420, 1463],
      [837, 1663, 973, 1792],
      [501, 1827, 637, 1956],
      [1451, 2156, 1587, 2285],
      [321, 2320, 457, 2449],
      [169, 2650, 305, 2779],
    ],
  },
  {
    page: 133,
    areas: [
      [152, 348, 288, 477],
      [1030, 841, 1166, 970],
      [232, 1004, 368, 1133],
      [702, 1334, 838, 1463],
      [602, 1498, 738, 1627],
      [1085, 1992, 1221, 2121],
      [1462, 2321, 1598, 2450],
      [152, 2650, 288, 2779],
    ],
  },

  {
    page: 134,
    areas: [
      [254, 511, 390, 640],
      [143, 1005, 279, 1134],
      [146, 1169, 282, 1298],
      [141, 1498, 277, 1627],
      [1218, 1991, 1354, 2120],
      [145, 2156, 281, 2285],
      [146, 2650, 282, 2779],
    ],
  },

  {
    page: 135,
    areas: [
      [755, 675, 891, 804],
      [894, 1004, 1030, 1133],
      [637, 1169, 773, 1298],
      [973, 1497, 1109, 1626],
      [1089, 1662, 1225, 1791],
      [134, 1991, 270, 2120],
      [256, 2156, 392, 2285],
      [830, 2320, 966, 2449],
      [136, 2649, 272, 2778],
    ],
  },
  {
    page: 136,
    areas: [
      [756, 511, 892, 640],
      [769, 1333, 905, 1462],
      [685, 1992, 821, 2121],
      [493, 2156, 629, 2285],
      [162, 2649, 298, 2778],
    ],
  },
  {
    page: 137,
    areas: [
      [713, 512, 849, 641],
      [148, 675, 284, 804],
      [933, 1005, 1069, 1134],
      [1246, 1333, 1382, 1462],
      [148, 1499, 284, 1628],
      [617, 1827, 753, 1956],
      [1094, 2321, 1230, 2450],
      [150, 2650, 286, 2779],
    ],
  },
  {
    page: 138,
    areas: [
      [1087, 509, 1223, 638],
      [157, 675, 293, 804],
      [158, 1168, 294, 1297],
      [154, 1333, 290, 1462],
      [1225, 1662, 1361, 1791],
      [657, 1827, 793, 1956],
      [1251, 2156, 1387, 2285],
      [1461, 2485, 1597, 2614],
      [157, 2650, 293, 2779],
    ],
  },
  {
    page: 139,
    areas: [
      [163, 839, 299, 968],
      [769, 1333, 905, 1462],
      [689, 2155, 825, 2284],
      [165, 2649, 301, 2778],
    ],
  },

  {
    page: 140,
    areas: [
      [468, 511, 604, 640],
      [1099, 840, 1235, 969],
      [1476, 1169, 1612, 1298],
      [573, 1333, 709, 1462],
      [958, 2155, 1094, 2284],
      [1174, 2485, 1310, 2614],
      [137, 2648, 273, 2777],
    ],
  },
  {
    page: 141,
    areas: [
      [514, 510, 650, 639],
      [160, 674, 296, 803],
      [642, 1004, 778, 1133],
      [155, 1170, 291, 1299],
      [1190, 1499, 1326, 1628],
      [697, 1664, 833, 1793],
      [1251, 2157, 1387, 2286],
      [1024, 2485, 1160, 2614],
      [158, 2650, 294, 2779],
    ],
  },
  {
    page: 142,
    areas: [
      [943, 675, 1079, 804],
      [1450, 1168, 1586, 1297],
      [459, 1334, 595, 1463],
      [774, 1828, 910, 1957],
      [270, 1991, 406, 2120],
      [472, 2320, 608, 2449],
      [170, 2486, 306, 2615],
      [168, 2649, 304, 2778],
    ],
  },
  {
    page: 143,
    areas: [
      [160, 675, 296, 804],
      [767, 1005, 903, 1134],
      [159, 1334, 295, 1463],
      [564, 1827, 700, 1956],
      [505, 2157, 641, 2286],
      [166, 2649, 302, 2778],
    ],
  },
  {
    page: 144,
    areas: [
      [1105, 840, 1241, 969],
      [881, 1005, 1017, 1134],
      [722, 1170, 858, 1299],
      [980, 1828, 1116, 1957],
      [996, 1991, 1132, 2120],
      [309, 2485, 445, 2614],
      [159, 2649, 295, 2778],
    ],
  },

  {
    page: 145,
    areas: [
      [1143, 510, 1279, 639],
      [384, 839, 520, 968],
      [387, 1004, 523, 1133],
      [1456, 1496, 1592, 1625],
      [765, 2155, 901, 2284],
      [163, 2650, 299, 2779],
    ],
  },

  {
    page: 146,
    areas: [
      [1144, 841, 1280, 970],
      [991, 1334, 1127, 1463],
      [557, 1663, 693, 1792],
      [154, 2320, 290, 2449],
      [153, 2650, 289, 2779],
    ],
  },
  {
    page: 147,
    areas: [
      [160, 676, 296, 805],
      [437, 1499, 573, 1628],
      [952, 2157, 1088, 2286],
      [162, 2649, 298, 2778],
    ],
  },
  {
    page: 148,
    areas: [
      [662, 511, 798, 640],
      [718, 1170, 854, 1299],
      [785, 1334, 921, 1463],
      [342, 1828, 478, 1957],
      [169, 2650, 305, 2779],
    ],
  },
  {
    page: 149,
    areas: [
      [152, 840, 288, 969],
      [1271, 1335, 1407, 1464],
      [1084, 1662, 1220, 1791],
      [876, 1827, 1012, 1956],
      [1457, 2157, 1593, 2286],
      [146, 2649, 282, 2778],
    ],
  },
  {
    page: 150,
    areas: [
      [1145, 839, 1281, 968],
      [1479, 1169, 1615, 1298],
      [595, 1333, 731, 1462],
      [1213, 1662, 1349, 1791],
      [1162, 1826, 1298, 1955],
      [1479, 1992, 1615, 2121],
      [681, 2321, 817, 2450],
      [138, 2649, 274, 2778],
    ],
  },
  {
    page: 151,
    areas: [
      [1299, 680, 1435, 809],
      [678, 844, 814, 973],
      [156, 1007, 292, 1136],
      [1464, 1336, 1600, 1465],
      [1280, 1501, 1416, 1630],
      [1206, 1665, 1342, 1794],
      [158, 1666, 294, 1795],
      [1202, 1996, 1338, 2125],
      [592, 2160, 728, 2289],
      [159, 2323, 295, 2452],
      [160, 2653, 296, 2782],
    ],
  },
  {
    page: 152,
    areas: [
      [1084, 510, 1220, 639],
      [779, 674, 915, 803],
      [1459, 840, 1595, 969],
      [821, 839, 957, 968],
      [1037, 1006, 1173, 1135],
      [262, 1169, 398, 1298],
      [1285, 1498, 1421, 1627],
      [374, 1664, 510, 1793],
      [1182, 2156, 1318, 2285],
      [149, 2156, 285, 2285],
      [153, 2650, 289, 2779],
    ],
  },
  {
    page: 153,
    areas: [
      [1207, 512, 1343, 641],
      [710, 677, 846, 806],
      [907, 840, 1043, 969],
      [771, 1169, 907, 1298],
      [414, 1663, 550, 1792],
      [229, 1991, 365, 2120],
      [303, 2321, 439, 2450],
      [130, 2650, 266, 2779],
    ],
  },
  {
    page: 154,
    areas: [
      [674, 511, 810, 640],
      [1152, 1004, 1288, 1133],
      [521, 1333, 657, 1462],
      [148, 1498, 284, 1627],
      [416, 1828, 552, 1957],
      [1143, 2156, 1279, 2285],
      [149, 2650, 285, 2779],
    ],
  },
  {
    page: 155,
    areas: [
      [162, 840, 298, 969],
      [593, 1170, 729, 1299],
      [1195, 1663, 1331, 1792],
      [840, 1827, 976, 1956],
      [909, 2155, 1045, 2284],
      [165, 2650, 301, 2779],
    ],
  },
  {
    page: 156,
    areas: [
      [953, 676, 1089, 805],
      [836, 841, 972, 970],
      [878, 1169, 1014, 1298],
      [436, 1334, 572, 1463],
      [975, 1663, 1111, 1792],
      [1477, 1991, 1613, 2120],
      [1144, 2321, 1280, 2450],
      [143, 2650, 279, 2779],
    ],
  },
  {
    page: 157,
    areas: [
      [1266, 511, 1402, 640],
      [161, 1004, 297, 1133],
      [672, 1662, 808, 1791],
      [595, 1827, 731, 1956],
      [684, 2156, 820, 2285],
      [162, 2650, 298, 2779],
    ],
  },
  {
    page: 158,
    areas: [
      [148, 511, 284, 640],
      [152, 841, 288, 970],
      [299, 1005, 435, 1134],
      [1466, 1332, 1602, 1461],
      [1137, 1498, 1273, 1627],
      [379, 1663, 515, 1792],
      [598, 1991, 734, 2120],
      [1473, 2320, 1609, 2449],
      [424, 2485, 560, 2614],
      [150, 2649, 286, 2778],
    ],
  },
  {
    page: 159,
    areas: [
      [395, 346, 531, 475],
      [1456, 1002, 1592, 1131],
      [1457, 1334, 1593, 1463],
      [1078, 1828, 1214, 1957],
      [1455, 2156, 1591, 2285],
      [159, 2650, 295, 2779],
    ],
  },
  {
    page: 160,
    areas: [
      [1123, 840, 1259, 969],
      [1136, 1334, 1272, 1463],
      [868, 1498, 1004, 1627],
      [1184, 1827, 1320, 1956],
      [1224, 1991, 1360, 2120],
      [1446, 2321, 1582, 2450],
      [792, 2485, 928, 2614],
      [165, 2650, 301, 2779],
    ],
  },
  {
    page: 161,
    areas: [
      [557, 512, 693, 641],
      [601, 676, 737, 805],
      [146, 840, 282, 969],
      [1479, 1827, 1615, 1956],
      [622, 2320, 758, 2449],
      [148, 2651, 284, 2780],
    ],
  },
  {
    page: 162,
    areas: [
      [1204, 675, 1340, 804],
      [379, 1169, 515, 1298],
      [1473, 1498, 1609, 1627],
      [143, 1498, 279, 1627],
      [873, 1826, 1009, 1955],
      [996, 2156, 1132, 2285],
      [224, 2320, 360, 2449],
      [144, 2649, 280, 2778],
    ],
  },
  {
    page: 163,
    areas: [
      [1174, 675, 1310, 804],
      [1109, 839, 1245, 968],
      [937, 1004, 1073, 1133],
      [591, 1169, 727, 1298],
      [178, 1499, 314, 1628],
      [409, 1991, 545, 2120],
      [1448, 2321, 1584, 2450],
      [172, 2485, 308, 2614],
      [174, 2649, 310, 2778],
    ],
  },
  {
    page: 164,
    areas: [
      [451, 511, 587, 640],
      [364, 675, 500, 804],
      [780, 840, 916, 969],
      [1263, 1005, 1399, 1134],
      [1365, 1169, 1501, 1298],
      [135, 1169, 271, 1298],
      [316, 1333, 452, 1462],
      [1011, 1498, 1147, 1627],
      [595, 1663, 731, 1792],
      [1104, 1827, 1240, 1956],
      [974, 1992, 1110, 2121],
      [135, 2156, 271, 2285],
      [1288, 2486, 1424, 2615],
      [339, 2485, 475, 2614],
      [890, 2650, 1026, 2779],
      [130, 2650, 266, 2779],
    ],
  },
  {
    page: 165,
    areas: [
      [887, 346, 1023, 475],
      [256, 345, 392, 474],
      [351, 676, 487, 805],
      [135, 838, 271, 967],
      [871, 1005, 1007, 1134],
      [1485, 1333, 1621, 1462],
      [598, 1662, 734, 1791],
      [455, 1990, 591, 2119],
      [784, 2484, 920, 2613],
      [131, 2649, 267, 2778],
    ],
  },
  {
    page: 166,
    areas: [
      [886, 676, 1022, 805],
      [540, 840, 676, 969],
      [620, 1169, 756, 1298],
      [1228, 1663, 1364, 1792],
      [810, 1827, 946, 1956],
      [163, 1992, 299, 2121],
      [160, 2651, 296, 2780],
    ],
  },
  {
    page: 167,
    areas: [
      [911, 674, 1047, 803],
      [963, 840, 1099, 969],
      [608, 1004, 744, 1133],
      [989, 1498, 1125, 1627],
      [965, 1991, 1101, 2120],
      [159, 2649, 295, 2778],
    ],
  },
  {
    page: 168,
    areas: [
      [468, 510, 604, 639],
      [1094, 1004, 1230, 1133],
      [968, 1662, 1104, 1791],
      [419, 2320, 555, 2449],
      [1154, 1991, 1290, 2120],
      [153, 2649, 289, 2778],
    ],
  },
  {
    page: 169,
    areas: [
      [1195, 1004, 1331, 1133],
      [584, 1169, 720, 1298],
      [837, 1497, 973, 1626],
      [1464, 1827, 1600, 1956],
      [342, 1992, 478, 2121],
      [147, 2650, 283, 2779],
    ],
  },
  {
    page: 170,
    areas: [
      [551, 839, 687, 968],
      [248, 1827, 384, 1956],
      [159, 2486, 295, 2615],
      [161, 2650, 297, 2779],
    ],
  },
  {
    page: 171,
    areas: [
      [253, 1169, 389, 1298],
      [154, 1662, 290, 1791],
      [1124, 2156, 1260, 2285],
      [163, 2650, 299, 2779],
    ],
  },
  {
    page: 172,
    areas: [
      [153, 511, 289, 640],
      [1467, 1004, 1603, 1133],
      [1468, 1169, 1604, 1298],
      [1110, 1498, 1246, 1627],
      [795, 1827, 931, 1956],
      [625, 2484, 761, 2613],
      [149, 2649, 285, 2778],
    ],
  },
  {
    page: 173,
    areas: [
      [151, 510, 287, 639],
      [605, 1004, 741, 1133],
      [1199, 1333, 1335, 1462],
      [1467, 1497, 1603, 1626],
      [429, 1663, 565, 1792],
      [756, 2320, 892, 2449],
      [486, 2485, 622, 2614],
      [156, 2649, 292, 2778],
    ],
  },
  {
    page: 174,
    areas: [
      [139, 674, 275, 803],
      [589, 1005, 725, 1134],
      [685, 1169, 821, 1298],
      [549, 1334, 685, 1463],
      [1143, 1497, 1279, 1626],
      [1035, 1663, 1171, 1792],
      [636, 1991, 772, 2120],
      [616, 2156, 752, 2285],
      [138, 2649, 274, 2778],
    ],
  },
  {
    page: 175,
    areas: [
      [696, 675, 832, 804],
      [145, 1167, 281, 1296],
      [1035, 1498, 1171, 1627],
      [1479, 1662, 1615, 1791],
      [142, 1663, 278, 1792],
      [959, 1991, 1095, 2120],
      [1076, 2320, 1212, 2449],
      [141, 2649, 277, 2778],
    ],
  },
  {
    page: 176,
    areas: [
      [156, 345, 292, 474],
      [988, 675, 1124, 804],
      [500, 840, 636, 969],
      [634, 1004, 770, 1133],
      [286, 1168, 422, 1297],
      [1011, 1498, 1147, 1627],
      [1170, 1662, 1306, 1791],
      [738, 1992, 874, 2121],
      [505, 2155, 641, 2284],
      [707, 2484, 843, 2613],
      [158, 2650, 294, 2779],
    ],
  },
  {
    page: 177,
    areas: [
      [1266, 1008, 1402, 1137],
      [1225, 1323, 1361, 1452],
      [1268, 1481, 1404, 1610],
      [601, 1639, 737, 1768],
      [139, 1797, 275, 1926],
      [1116, 2112, 1252, 2241],
      [1486, 2586, 1622, 2715],
      [138, 2585, 274, 2714],
    ],
  },
  {
    page: 178,
    areas: [
      [820, 511, 956, 640],
      [1097, 840, 1233, 969],
      [135, 1168, 271, 1297],
      [508, 1662, 644, 1791],
      [1100, 1991, 1236, 2120],
      [1127, 2155, 1263, 2284],
      [615, 2320, 751, 2449],
      [139, 2649, 275, 2778],
    ],
  },
  {
    page: 179,
    areas: [
      [869, 675, 1005, 804],
      [1182, 840, 1318, 969],
      [319, 1169, 455, 1298],
      [1212, 1498, 1348, 1627],
      [1181, 1663, 1317, 1792],
      [975, 1828, 1111, 1957],
      [488, 1991, 624, 2120],
      [1102, 2485, 1238, 2614],
      [159, 2649, 295, 2778],
    ],
  },
  {
    page: 180,
    areas: [
      [643, 675, 779, 804],
      [1473, 1004, 1609, 1133],
      [949, 1169, 1085, 1298],
      [653, 1498, 789, 1627],
      [791, 1827, 927, 1956],
      [1036, 2155, 1172, 2284],
      [882, 2485, 1018, 2614],
      [143, 2649, 279, 2778],
    ],
  },
  {
    page: 181,
    areas: [
      [652, 676, 788, 805],
      [843, 1005, 979, 1134],
      [1137, 1497, 1273, 1626],
      [460, 1826, 596, 1955],
      [604, 2156, 740, 2285],
      [416, 2484, 552, 2613],
      [161, 2649, 297, 2778],
    ],
  },
  {
    page: 182,
    areas: [
      [244, 839, 380, 968],
      [1088, 1663, 1224, 1792],
      [279, 1991, 415, 2120],
      [1112, 2485, 1248, 2614],
      [165, 2650, 301, 2779],
    ],
  },
  {
    page: 183,
    areas: [
      [634, 509, 770, 638],
      [496, 839, 632, 968],
      [457, 1496, 593, 1625],
      [169, 1826, 305, 1955],
      [307, 2156, 443, 2285],
      [163, 2319, 299, 2448],
      [164, 2650, 300, 2779],
    ],
  },
  {
    page: 184,
    areas: [
      [574, 511, 710, 640],
      [141, 840, 277, 969],
      [150, 1005, 286, 1134],
      [1044, 1333, 1180, 1462],
      [615, 1498, 751, 1627],
      [1468, 1828, 1604, 1957],
      [148, 1827, 284, 1956],
      [541, 2485, 677, 2614],
      [152, 2650, 288, 2779],
    ],
  },
  {
    page: 185,
    areas: [
      [887, 511, 1023, 640],
      [645, 840, 781, 969],
      [670, 1003, 806, 1132],
      [467, 1498, 603, 1627],
      [728, 1992, 864, 2121],
      [507, 2321, 643, 2450],
      [408, 2485, 544, 2614],
      [141, 2649, 277, 2778],
    ],
  },
  {
    page: 186,
    areas: [
      [991, 676, 1127, 805],
      [404, 839, 540, 968],
      [319, 1662, 455, 1791],
      [818, 1992, 954, 2121],
      [601, 2320, 737, 2449],
      [146, 2650, 282, 2779],
    ],
  },
  {
    page: 187,
    areas: [
      [141, 505, 277, 634],
      [768, 834, 904, 963],
      [1457, 1492, 1593, 1621],
      [761, 1821, 897, 1950],
      [148, 2313, 284, 2442],
      [136, 2657, 272, 2786],
    ],
  },
  {
    page: 188,
    areas: [
      [1467, 840, 1603, 969],
      [1179, 1169, 1315, 1298],
      [399, 1334, 535, 1463],
      [153, 1498, 289, 1627],
      [425, 1827, 561, 1956],
      [1467, 2321, 1603, 2450],
      [153, 2650, 289, 2779],
    ],
  },
  {
    page: 189,
    areas: [
      [429, 511, 565, 640],
      [1508, 840, 1644, 969],
      [564, 1169, 700, 1298],
      [102, 1497, 238, 1626],
      [587, 1992, 723, 2121],
      [1271, 2484, 1407, 2613],
      [114, 2649, 250, 2778],
    ],
  },
  {
    page: 190,
    areas: [
      [1128, 511, 1264, 640],
      [1266, 676, 1402, 805],
      [294, 1004, 430, 1133],
      [925, 1827, 1061, 1956],
      [632, 2321, 768, 2450],
      [141, 2650, 277, 2779],
    ],
  },
  {
    page: 191,
    areas: [
      [1262, 511, 1398, 640],
      [548, 1005, 684, 1134],
      [1473, 1663, 1609, 1792],
      [750, 2156, 886, 2285],
      [146, 2649, 282, 2778],
    ],
  },
  {
    page: 192,
    areas: [
      [461, 511, 597, 640],
      [545, 840, 681, 969],
      [424, 1498, 560, 1627],
      [1123, 1991, 1259, 2120],
      [149, 2648, 285, 2777],
    ],
  },
  {
    page: 193,
    areas: [
      [616, 839, 752, 968],
      [138, 1333, 274, 1462],
      [1309, 1827, 1445, 1956],
      [131, 2650, 267, 2779],
    ],
  },
  {
    page: 194,
    areas: [
      [135, 510, 271, 639],
      [136, 1004, 272, 1133],
      [686, 1333, 822, 1462],
      [723, 1662, 859, 1791],
      [836, 1992, 972, 2121],
      [584, 2320, 720, 2449],
      [144, 2649, 280, 2778],
    ],
  },
  {
    page: 195,
    areas: [
      [149, 510, 285, 639],
      [1463, 1004, 1599, 1133],
      [997, 1333, 1133, 1462],
      [1466, 1663, 1602, 1792],
      [242, 1991, 378, 2120],
      [1105, 2321, 1241, 2450],
      [155, 2649, 291, 2778],
    ],
  },
  {
    page: 196,
    areas: [
      [157, 511, 293, 640],
      [1070, 840, 1206, 969],
      [588, 1004, 724, 1133],
      [1011, 1333, 1147, 1462],
      [660, 1663, 796, 1792],
      [337, 2156, 473, 2285],
      [157, 2650, 293, 2779],
    ],
  },
  {
    page: 197,
    areas: [
      [598, 511, 734, 640],
      [741, 839, 877, 968],
      [634, 1169, 770, 1298],
      [664, 1498, 800, 1627],
      [757, 1827, 893, 1956],
      [398, 2320, 534, 2449],
      [151, 2650, 287, 2779],
    ],
  },
  {
    page: 198,
    areas: [
      [405, 1005, 541, 1134],
      [874, 1663, 1010, 1792],
      [122, 2156, 258, 2285],
      [131, 2649, 267, 2778],
    ],
  },
  {
    page: 199,
    areas: [
      [594, 511, 730, 640],
      [996, 1333, 1132, 1462],
      [143, 1498, 279, 1627],
      [1473, 1828, 1609, 1957],
      [410, 1992, 546, 2121],
      [1198, 2321, 1334, 2450],
      [150, 2649, 286, 2778],
    ],
  },
  {
    page: 200,
    areas: [
      [676, 676, 812, 805],
      [870, 1168, 1006, 1297],
      [844, 1333, 980, 1462],
      [1150, 1827, 1286, 1956],
      [1466, 2155, 1602, 2284],
      [298, 2320, 434, 2449],
      [159, 2649, 295, 2778],
    ],
  },
  {
    page: 201,
    areas: [
      [1082, 511, 1218, 640],
      [823, 840, 959, 969],
      [272, 1005, 408, 1134],
      [1469, 1498, 1605, 1627],
      [147, 1827, 283, 1956],
      [750, 2321, 886, 2450],
      [145, 2649, 281, 2778],
    ],
  },
  {
    page: 202,
    areas: [
      [442, 840, 578, 969],
      [1067, 1334, 1203, 1463],
      [1453, 1663, 1589, 1792],
      [269, 1828, 405, 1957],
      [397, 2156, 533, 2285],
      [162, 2650, 298, 2779],
    ],
  },
  {
    page: 203,
    areas: [
      [983, 840, 1119, 969],
      [1304, 1334, 1440, 1463],
      [161, 1498, 297, 1627],
      [445, 1828, 581, 1957],
      [876, 2157, 1012, 2286],
      [794, 2485, 930, 2614],
      [166, 2650, 302, 2779],
    ],
  },
  {
    page: 204,
    areas: [
      [1459, 840, 1595, 969],
      [824, 1169, 960, 1298],
      [974, 1663, 1110, 1792],
      [155, 1828, 291, 1957],
      [152, 2650, 288, 2779],
    ],
  },
  {
    page: 205,
    areas: [
      [997, 840, 1133, 969],
      [465, 1169, 601, 1298],
      [1462, 1663, 1598, 1792],
      [300, 1827, 436, 1956],
      [654, 2156, 790, 2285],
      [145, 2650, 281, 2779],
    ],
  },
  {
    page: 206,
    areas: [
      [1240, 839, 1376, 968],
      [1082, 1005, 1218, 1134],
      [156, 1828, 292, 1957],
      [1238, 2321, 1374, 2450],
      [153, 2650, 289, 2779],
    ],
  },
  {
    page: 207,
    areas: [
      [143, 497, 279, 626],
      [1450, 991, 1586, 1120],
      [386, 1155, 522, 1284],
      [510, 1484, 646, 1613],
      [1443, 1978, 1579, 2107],
      [1014, 2305, 1150, 2434],
      [148, 2471, 284, 2600],
    ],
  },
  {
    page: 208,
    areas: [
      [668, 521, 804, 650],
      [1159, 1015, 1295, 1144],
      [1156, 1505, 1292, 1634],
      [770, 2015, 906, 2144],
      [1126, 2499, 1262, 2628],
      [166, 2645, 302, 2774],
    ],
  },
  {
    page: 209,
    areas: [
      [566, 511, 702, 640],
      [648, 675, 784, 804],
      [660, 1004, 796, 1133],
      [1028, 1333, 1164, 1462],
      [361, 1662, 497, 1791],
      [710, 2156, 846, 2285],
      [485, 2485, 621, 2614],
      [130, 2649, 266, 2778],
    ],
  },
  {
    page: 210,
    areas: [
      [401, 840, 537, 969],
      [378, 1169, 514, 1298],
      [877, 1498, 1013, 1627],
      [319, 1991, 455, 2120],
      [1498, 2485, 1634, 2614],
      [116, 2649, 252, 2778],
    ],
  },
  {
    page: 211,
    areas: [
      [1478, 676, 1614, 805],
      [1228, 1333, 1364, 1462],
      [138, 1662, 274, 1791],
      [254, 2485, 390, 2614],
      [138, 2649, 274, 2778],
    ],
  },
  {
    page: 212,
    areas: [
      [349, 510, 485, 639],
      [571, 1004, 707, 1133],
      [416, 1334, 552, 1463],
      [157, 1498, 293, 1627],
      [570, 1827, 706, 1956],
      [706, 2321, 842, 2450],
      [361, 2485, 497, 2614],
      [156, 2648, 292, 2777],
    ],
  },
  {
    page: 213,
    areas: [
      [795, 512, 931, 641],
      [129, 839, 265, 968],
      [1082, 1168, 1218, 1297],
      [977, 1498, 1113, 1627],
      [133, 1662, 269, 1791],
      [128, 1992, 264, 2121],
      [1188, 2320, 1324, 2449],
      [347, 2485, 483, 2614],
      [126, 2650, 262, 2779],
    ],
  },

  {
    page: 214,
    areas: [
      [1097, 509, 1233, 638],
      [872, 676, 1008, 805],
      [1041, 1005, 1177, 1134],
      [359, 1169, 495, 1298],
      [1184, 1498, 1320, 1627],
      [1478, 1663, 1614, 1792],
      [137, 1827, 273, 1956],
      [1204, 2156, 1340, 2285],
      [1185, 2321, 1321, 2450],
      [581, 2486, 717, 2615],
      [132, 2651, 268, 2780],
    ],
  },

  {
    page: 215,
    areas: [
      [1166, 676, 1302, 805],
      [546, 841, 682, 970],
      [1014, 1004, 1150, 1133],
      [1469, 1334, 1605, 1463],
      [1228, 1498, 1364, 1627],
      [1201, 1827, 1337, 1956],
      [1152, 2157, 1288, 2286],
      [152, 2650, 288, 2779],
    ],
  },
  {
    page: 216,
    areas: [
      [1500, 511, 1636, 640],
      [430, 511, 566, 640],
      [801, 840, 937, 969],
      [471, 1005, 607, 1134],
      [660, 1498, 796, 1627],
      [807, 1827, 943, 1956],
      [1209, 2321, 1345, 2450],
      [1156, 2484, 1292, 2613],
      [118, 2650, 254, 2779],
    ],
  },
  {
    page: 217,
    areas: [
      [1065, 839, 1201, 968],
      [160, 1004, 296, 1133],
      [1456, 1498, 1592, 1627],
      [1193, 1827, 1329, 1956],
      [161, 1991, 297, 2120],
      [156, 2157, 292, 2286],
      [1187, 2486, 1323, 2615],
      [158, 2650, 294, 2779],
    ],
  },
  {
    page: 218,
    areas: [
      [542, 346, 678, 475],
      [536, 512, 672, 641],
      [1046, 840, 1182, 969],
      [1194, 1004, 1330, 1133],
      [721, 1334, 857, 1463],
      [474, 1498, 610, 1627],
      [317, 1663, 453, 1792],
      [743, 1828, 879, 1957],
      [517, 2157, 653, 2286],
      [150, 2651, 286, 2780],
    ],
  },
  {
    page: 219,
    areas: [
      [989, 511, 1125, 640],
      [1029, 1005, 1165, 1134],
      [1089, 1169, 1225, 1298],
      [142, 1333, 278, 1462],
      [903, 1827, 1039, 1956],
      [420, 2156, 556, 2285],
      [1469, 2485, 1605, 2614],
      [1472, 2654, 1608, 2783],
      [142, 2652, 278, 2781],
    ],
  },
  {
    page: 220,
    areas: [
      [1249, 675, 1385, 804],
      [240, 840, 376, 969],
      [877, 1169, 1013, 1298],
      [154, 1333, 290, 1462],
      [377, 1663, 513, 1792],
      [1464, 1992, 1600, 2121],
      [919, 2320, 1055, 2449],
      [665, 2485, 801, 2614],
      [149, 2651, 285, 2780],
    ],
  },

  {
    page: 221,
    areas: [
      [945, 601, 1081, 730],
      [309, 928, 445, 1057],
      [163, 1092, 299, 1221],
      [156, 1655, 292, 1784],
      [493, 1819, 629, 1948],
      [1341, 2309, 1477, 2438],
      [340, 2308, 476, 2437],
      [162, 2634, 298, 2763],
    ],
  },
  {
    page: 222,
    areas: [
      [552, 507, 688, 636],
      [885, 1160, 1021, 1289],
      [165, 1487, 301, 1616],
      [1004, 1815, 1140, 1944],
      [161, 1977, 297, 2106],
      [1099, 2305, 1235, 2434],
      [163, 2632, 299, 2761],
    ],
  },
  {
    page: 223,
    areas: [
      [167, 507, 303, 636],
      [701, 833, 837, 962],
      [1461, 1160, 1597, 1289],
      [402, 1324, 538, 1453],
      [272, 1979, 408, 2108],
      [547, 2468, 683, 2597],
      [159, 2631, 295, 2760],
    ],
  },
  {
    page: 224,
    areas: [
      [716, 670, 852, 799],
      [452, 834, 588, 963],
      [710, 998, 846, 1127],
      [992, 1325, 1128, 1454],
      [1496, 1652, 1632, 1781],
      [128, 1651, 264, 1780],
      [1493, 1978, 1629, 2107],
      [1497, 2468, 1633, 2597],
      [129, 2631, 265, 2760],
    ],
  },
  {
    page: 225,
    areas: [
      [1048, 670, 1184, 799],
      [1056, 833, 1192, 962],
      [1087, 1324, 1223, 1453],
      [257, 1487, 393, 1616],
      [420, 1651, 556, 1780],
      [727, 1977, 863, 2106],
      [163, 2141, 299, 2270],
      [713, 2469, 849, 2598],
      [162, 2631, 298, 2760],
    ],
  },
  {
    page: 226,
    areas: [
      [155, 508, 291, 637],
      [1273, 834, 1409, 963],
      [592, 1161, 728, 1290],
      [269, 1325, 405, 1454],
      [153, 1652, 289, 1781],
      [1026, 2142, 1162, 2271],
      [938, 2469, 1074, 2598],
      [159, 2632, 295, 2761],
    ],
  },
  {
    page: 227,
    areas: [
      [175, 507, 311, 636],
      [418, 833, 554, 962],
      [349, 1161, 485, 1290],
      [397, 1487, 533, 1616],
      [711, 1815, 847, 1944],
      [177, 1978, 313, 2107],
      [1160, 2469, 1296, 2598],
      [173, 2632, 309, 2761],
    ],
  },
  {
    page: 228,
    areas: [
      [624, 507, 760, 636],
      [943, 671, 1079, 800],
      [1465, 997, 1601, 1126],
      [1466, 1323, 1602, 1452],
      [778, 1487, 914, 1616],
      [326, 1650, 462, 1779],
      [1005, 1978, 1141, 2107],
      [1463, 2468, 1599, 2597],
      [163, 2631, 299, 2760],
    ],
  },

  {
    page: 229,
    areas: [
      [1165, 670, 1301, 799],
      [1125, 997, 1261, 1126],
      [463, 1160, 599, 1289],
      [319, 1488, 455, 1617],
      [1452, 1815, 1588, 1944],
      [1270, 1978, 1406, 2107],
      [276, 2142, 412, 2271],
      [545, 2469, 681, 2598],
      [172, 2631, 308, 2760],
    ],
  },

  {
    page: 230,
    areas: [
      [1123, 507, 1259, 636],
      [420, 670, 556, 799],
      [139, 834, 275, 963],
      [826, 997, 962, 1126],
      [265, 1160, 401, 1289],
      [1164, 1487, 1300, 1616],
      [1484, 1976, 1620, 2105],
      [1487, 2142, 1623, 2271],
      [272, 2142, 408, 2271],
      [141, 2632, 277, 2761],
    ],
  },
  {
    page: 231,
    areas: [
      [667, 507, 803, 636],
      [664, 670, 800, 799],
      [354, 1161, 490, 1290],
      [151, 1487, 287, 1616],
      [1230, 1814, 1366, 1943],
      [745, 2141, 881, 2270],
      [160, 2632, 296, 2761],
    ],
  },
  {
    page: 232,
    areas: [
      [1272, 671, 1408, 800],
      [1117, 832, 1253, 961],
      [1122, 1162, 1258, 1291],
      [1264, 1489, 1400, 1618],
      [453, 1814, 589, 1943],
      [171, 2142, 307, 2271],
      [335, 2305, 471, 2434],
      [538, 2468, 674, 2597],
      [169, 2632, 305, 2761],
    ],
  },
  {
    page: 233,
    areas: [
      [1229, 507, 1365, 636],
      [1093, 671, 1229, 800],
      [892, 834, 1028, 963],
      [167, 1160, 303, 1289],
      [1179, 1486, 1315, 1615],
      [303, 1650, 439, 1779],
      [811, 1814, 947, 1943],
      [650, 1977, 786, 2106],
      [775, 2141, 911, 2270],
      [1460, 2468, 1596, 2597],
      [167, 2631, 303, 2760],
    ],
  },
  {
    page: 234,
    areas: [
      [161, 506, 297, 635],
      [1464, 996, 1600, 1125],
      [1283, 1162, 1419, 1291],
      [829, 1322, 965, 1451],
      [1101, 1650, 1237, 1779],
      [1460, 1977, 1596, 2106],
      [328, 1977, 464, 2106],
      [456, 2467, 592, 2596],
      [166, 2632, 302, 2761],
    ],
  },
  {
    page: 235,
    areas: [
      [1477, 438, 1613, 567],
      [425, 601, 561, 730],
      [621, 927, 757, 1056],
      [690, 1090, 826, 1219],
      [1481, 1254, 1617, 1383],
      [148, 1419, 284, 1548],
      [691, 1981, 827, 2110],
      [987, 2146, 1123, 2275],
      [1066, 2473, 1202, 2602],
      [147, 2634, 283, 2763],
    ],
  },
  {
    page: 236,
    areas: [
      [592, 507, 728, 636],
      [934, 999, 1070, 1128],
      [1043, 1161, 1179, 1290],
      [326, 1324, 462, 1453],
      [948, 1652, 1084, 1781],
      [1277, 1979, 1413, 2108],
      [1173, 2141, 1309, 2270],
      [1175, 2304, 1311, 2433],
      [422, 2469, 558, 2598],
      [170, 2630, 306, 2759],
    ],
  },
  {
    page: 237,
    areas: [
      [347, 506, 483, 635],
      [905, 671, 1041, 800],
      [653, 997, 789, 1126],
      [709, 1324, 845, 1453],
      [761, 1651, 897, 1780],
      [301, 1814, 437, 1943],
      [379, 2468, 515, 2597],
      [167, 2631, 303, 2760],
    ],
  },
  {
    page: 238,
    areas: [
      [823, 669, 959, 798],
      [420, 998, 556, 1127],
      [1332, 1487, 1468, 1616],
      [1203, 1814, 1339, 1943],
      [1113, 1978, 1249, 2107],
      [645, 2141, 781, 2270],
      [1457, 2469, 1593, 2598],
      [169, 2632, 305, 2761],
    ],
  },
  {
    page: 239,
    areas: [
      [1338, 834, 1474, 963],
      [1127, 1161, 1263, 1290],
      [1464, 1488, 1600, 1617],
      [1296, 1650, 1432, 1779],
      [1218, 1814, 1354, 1943],
      [1181, 2305, 1317, 2434],
      [159, 2633, 295, 2762],
    ],
  },
  {
    page: 240,
    areas: [
      [427, 670, 563, 799],
      [1469, 997, 1605, 1126],
      [925, 1488, 1061, 1617],
      [412, 1815, 548, 1944],
      [1460, 2305, 1596, 2434],
      [166, 2632, 302, 2761],
    ],
  },
  {
    page: 241,
    areas: [
      [173, 344, 309, 473],
      [1223, 670, 1359, 799],
      [259, 998, 395, 1127],
      [1049, 1324, 1185, 1453],
      [625, 1488, 761, 1617],
      [578, 1652, 714, 1781],
      [273, 1978, 409, 2107],
      [282, 2468, 418, 2597],
      [168, 2632, 304, 2761],
    ],
  },
  {
    page: 242,
    areas: [
      [930, 506, 1066, 635],
      [267, 670, 403, 799],
      [375, 834, 511, 963],
      [349, 1161, 485, 1290],
      [507, 1325, 643, 1454],
      [279, 1488, 415, 1617],
      [650, 1815, 786, 1944],
      [725, 1977, 861, 2106],
      [1140, 2141, 1276, 2270],
      [1459, 2469, 1595, 2598],
      [163, 2632, 299, 2761],
    ],
  },
  {
    page: 243,
    areas: [
      [459, 506, 595, 635],
      [344, 996, 480, 1125],
      [1454, 1486, 1590, 1615],
      [286, 1814, 422, 1943],
      [1454, 2469, 1590, 2598],
      [170, 2632, 306, 2761],
    ],
  },
  {
    page: 244,
    areas: [
      [469, 507, 605, 636],
      [842, 670, 978, 799],
      [434, 835, 570, 964],
      [1474, 1161, 1610, 1290],
      [408, 1161, 544, 1290],
      [1476, 1487, 1612, 1616],
      [712, 1978, 848, 2107],
      [1186, 2466, 1322, 2595],
      [147, 2631, 283, 2760],
    ],
  },
  {
    page: 245,
    areas: [
      [1068, 506, 1204, 635],
      [1459, 1160, 1595, 1289],
      [1464, 1488, 1600, 1617],
      [978, 1650, 1114, 1779],
      [898, 1977, 1034, 2106],
      [163, 2141, 299, 2270],
      [689, 2467, 825, 2596],
      [166, 2632, 302, 2761],
    ],
  },
  {
    page: 246,
    areas: [
      [1463, 670, 1599, 799],
      [710, 997, 846, 1126],
      [540, 1160, 676, 1289],
      [1144, 1651, 1280, 1780],
      [771, 1814, 907, 1943],
      [159, 1978, 295, 2107],
      [552, 2303, 688, 2432],
      [1266, 2632, 1402, 2761],
      [158, 2632, 294, 2761],
    ],
  },
  {
    page: 247,
    areas: [
      [279, 507, 415, 636],
      [450, 671, 586, 800],
      [337, 833, 473, 962],
      [968, 1161, 1104, 1290],
      [389, 1814, 525, 1943],
      [700, 2305, 836, 2434],
      [1466, 2631, 1602, 2760],
      [161, 2632, 297, 2761],
    ],
  },
  {
    page: 248,
    areas: [
      [170, 343, 306, 472],
      [938, 670, 1074, 799],
      [1125, 833, 1261, 962],
      [413, 998, 549, 1127],
      [764, 1325, 900, 1454],
      [287, 1814, 423, 1943],
      [1461, 2305, 1597, 2434],
      [173, 2632, 309, 2761],
    ],
  },
  {
    page: 249,
    areas: [
      [785, 838, 921, 967],
      [1149, 1328, 1285, 1457],
      [454, 1655, 590, 1784],
      [153, 2145, 289, 2274],
      [156, 2636, 292, 2765],
    ],
  },
  {
    page: 250,
    areas: [
      [740, 670, 876, 799],
      [1462, 997, 1598, 1126],
      [499, 1162, 635, 1291],
      [663, 1324, 799, 1453],
      [1294, 1650, 1430, 1779],
      [1338, 2141, 1474, 2270],
      [788, 2304, 924, 2433],
      [166, 2631, 302, 2760],
    ],
  },
  {
    page: 251,
    areas: [
      [1177, 670, 1313, 799],
      [628, 833, 764, 962],
      [412, 1488, 548, 1617],
      [158, 2141, 294, 2270],
      [151, 2632, 287, 2761],
    ],
  },
  {
    page: 252,
    areas: [
      [1148, 507, 1284, 636],
      [1469, 671, 1605, 800],
      [935, 834, 1071, 963],
      [588, 1160, 724, 1289],
      [1068, 1487, 1204, 1616],
      [1472, 1652, 1608, 1781],
      [1115, 1977, 1251, 2106],
      [319, 2140, 455, 2269],
      [699, 2466, 835, 2595],
      [154, 2627, 290, 2756],
    ],
  },
  {
    page: 253,
    areas: [
      [1307, 508, 1443, 637],
      [160, 833, 296, 962],
      [684, 1649, 820, 1778],
      [1289, 1977, 1425, 2106],
      [645, 2467, 781, 2596],
      [163, 2631, 299, 2760],
    ],
  },
  {
    page: 254,
    areas: [
      [1076, 670, 1212, 799],
      [153, 998, 289, 1127],
      [320, 1325, 456, 1454],
      [150, 1651, 286, 1780],
      [158, 1815, 294, 1944],
      [928, 2142, 1064, 2271],
      [1242, 2469, 1378, 2598],
      [136, 2632, 272, 2761],
    ],
  },
  {
    page: 255,
    areas: [
      [150, 437, 286, 566],
      [151, 1164, 287, 1293],
      [630, 1490, 766, 1619],
      [472, 1818, 608, 1947],
      [1471, 2307, 1607, 2436],
      [150, 2635, 286, 2764],
    ],
  },
  {
    page: 256,
    areas: [
      [513, 833, 649, 962],
      [1144, 1161, 1280, 1290],
      [766, 1323, 902, 1452],
      [463, 1977, 599, 2106],
      [153, 2632, 289, 2761],
    ],
  },
  {
    page: 257,
    areas: [
      [1472, 833, 1608, 962],
      [1467, 1161, 1603, 1290],
      [1106, 1488, 1242, 1617],
      [514, 1650, 650, 1779],
      [740, 1813, 876, 1942],
      [1068, 1978, 1204, 2107],
      [916, 2307, 1052, 2436],
      [161, 2631, 297, 2760],
    ],
  },
  {
    page: 258,
    areas: [
      [753, 508, 889, 637],
      [1467, 671, 1603, 800],
      [568, 1161, 704, 1290],
      [1470, 2140, 1606, 2269],
      [1220, 2467, 1356, 2596],
      [158, 2632, 294, 2761],
    ],
  },
  {
    page: 259,
    areas: [
      [663, 507, 799, 636],
      [1487, 834, 1623, 963],
      [1164, 1161, 1300, 1290],
      [819, 1323, 955, 1452],
      [1256, 1487, 1392, 1616],
      [589, 1650, 725, 1779],
      [503, 1977, 639, 2106],
      [490, 2468, 626, 2597],
      [142, 2632, 278, 2761],
    ],
  },
  {
    page: 260,
    areas: [
      [295, 507, 431, 636],
      [983, 835, 1119, 964],
      [147, 996, 283, 1125],
      [145, 1487, 281, 1616],
      [840, 1815, 976, 1944],
      [143, 1978, 279, 2107],
      [1306, 2305, 1442, 2434],
      [1204, 2468, 1340, 2597],
      [135, 2631, 271, 2760],
    ],
  },
  {
    page: 261,
    areas: [
      [1328, 507, 1464, 636],
      [1219, 998, 1355, 1127],
      [1060, 1324, 1196, 1453],
      [1477, 1650, 1613, 1779],
      [1195, 1816, 1331, 1945],
      [818, 1977, 954, 2106],
      [916, 2142, 1052, 2271],
      [1009, 2305, 1145, 2434],
      [803, 2469, 939, 2598],
      [160, 2632, 296, 2761],
    ],
  },
  {
    page: 262,
    areas: [
      [414, 658, 550, 787],
      [628, 821, 764, 950],
      [444, 983, 580, 1112],
      [653, 1147, 789, 1276],
      [929, 1310, 1065, 1439],
      [960, 1474, 1096, 1603],
      [1115, 1637, 1251, 1766],
      [1179, 1800, 1315, 1929],
      [154, 1800, 290, 1929],
      [488, 1963, 624, 2092],
      [675, 2127, 811, 2256],
      [1044, 2289, 1180, 2418],
      [1479, 2455, 1615, 2584],
      [1482, 2616, 1618, 2745],
      [151, 2617, 287, 2746],
    ],
  },
  {
    page: 263,
    areas: [
      [157, 328, 293, 457],
      [657, 490, 793, 619],
      [989, 655, 1125, 784],
      [564, 818, 700, 947],
      [755, 980, 891, 1109],
      [574, 1143, 710, 1272],
      [1246, 1470, 1382, 1599],
      [151, 1471, 287, 1600],
      [155, 1633, 291, 1762],
      [625, 1797, 761, 1926],
      [823, 1960, 959, 2089],
      [1258, 2123, 1394, 2252],
      [864, 2285, 1000, 2414],
      [853, 2450, 989, 2579],
      [1294, 2613, 1430, 2742],
      [149, 2613, 285, 2742],
    ],
  },
  {
    page: 264,
    areas: [
      [441, 326, 577, 455],
      [252, 492, 388, 621],
      [865, 654, 1001, 783],
      [1344, 817, 1480, 946],
      [394, 816, 530, 945],
      [1141, 981, 1277, 1110],
      [418, 981, 554, 1110],
      [147, 1145, 283, 1274],
      [677, 1308, 813, 1437],
      [1227, 1471, 1363, 1600],
      [980, 1634, 1116, 1763],
      [152, 1635, 288, 1764],
      [299, 1796, 435, 1925],
      [841, 1961, 977, 2090],
      [149, 1961, 285, 2090],
      [1487, 2287, 1623, 2416],
      [144, 2286, 280, 2415],
      [472, 2450, 608, 2579],
      [943, 2614, 1079, 2743],
      [151, 2613, 287, 2742],
    ],
  },
  {
    page: 265,
    areas: [
      [298, 328, 434, 457],
      [709, 490, 845, 619],
      [706, 655, 842, 784],
      [857, 817, 993, 946],
      [938, 981, 1074, 1110],
      [1486, 1144, 1622, 1273],
      [477, 1144, 613, 1273],
      [850, 1306, 986, 1435],
      [1168, 1471, 1304, 1600],
      [272, 1470, 408, 1599],
      [923, 1634, 1059, 1763],
      [1230, 1798, 1366, 1927],
      [303, 1798, 439, 1927],
      [906, 2125, 1042, 2254],
      [707, 2288, 843, 2417],
      [1230, 2450, 1366, 2579],
      [291, 2451, 427, 2580],
      [1097, 2614, 1233, 2743],
      [151, 2614, 287, 2743],
    ],
  },
  {
    page: 266,
    areas: [
      [709, 327, 845, 456],
      [1280, 491, 1416, 620],
      [494, 491, 630, 620],
      [444, 654, 580, 783],
      [1050, 819, 1186, 948],
      [433, 817, 569, 946],
      [1115, 980, 1251, 1109],
      [152, 980, 288, 1109],
      [636, 1143, 772, 1272],
      [1063, 1308, 1199, 1437],
      [1476, 1471, 1612, 1600],
      [397, 1470, 533, 1599],
      [1023, 1634, 1159, 1763],
      [153, 1634, 289, 1763],
      [527, 1959, 663, 2088],
      [1177, 2123, 1313, 2252],
      [1318, 2286, 1454, 2415],
      [419, 2450, 555, 2579],
      [987, 2614, 1123, 2743],
      [155, 2613, 291, 2742],
    ],
  },
  {
    page: 267,
    areas: [
      [726, 276, 862, 405],
      [1285, 440, 1421, 569],
      [744, 439, 880, 568],
      [1153, 604, 1289, 733],
      [354, 604, 490, 733],
      [392, 766, 528, 895],
      [723, 931, 859, 1060],
      [1128, 1094, 1264, 1223],
      [153, 1094, 289, 1223],
      [1479, 1820, 1615, 1949],
      [561, 1983, 697, 2112],
      [354, 2147, 490, 2276],
      [393, 2311, 529, 2440],
      [1477, 2637, 1613, 2766],
      [156, 2637, 292, 2766],
    ],
  },
  {
    page: 268,
    areas: [
      [550, 510, 686, 639],
      [151, 674, 287, 803],
      [1220, 999, 1356, 1128],
      [463, 1164, 599, 1293],
      [161, 1490, 297, 1619],
      [1473, 1980, 1609, 2109],
      [469, 2144, 605, 2273],
      [157, 2634, 293, 2763],
    ],
  },
  {
    page: 269,
    areas: [
      [989, 509, 1125, 638],
      [1482, 673, 1618, 802],
      [264, 672, 400, 801],
      [153, 836, 289, 965],
      [570, 999, 706, 1128],
      [418, 1163, 554, 1292],
      [565, 1328, 701, 1457],
      [1479, 1653, 1615, 1782],
      [895, 1817, 1031, 1946],
      [847, 1979, 983, 2108],
      [985, 2308, 1121, 2437],
      [157, 2634, 293, 2763],
    ],
  },
  {
    page: 270,
    areas: [
      [733, 673, 869, 802],
      [691, 999, 827, 1128],
      [390, 1163, 526, 1292],
      [1486, 1652, 1622, 1781],
      [476, 1816, 612, 1945],
      [1113, 2144, 1249, 2273],
      [433, 2468, 569, 2597],
      [157, 2633, 293, 2762],
    ],
  },
  {
    page: 271,
    areas: [
      [1463, 837, 1599, 966],
      [749, 1327, 885, 1456],
      [165, 1490, 301, 1619],
      [166, 1818, 302, 1947],
      [1026, 2144, 1162, 2273],
      [1104, 2308, 1240, 2437],
      [1267, 2634, 1403, 2763],
      [164, 2635, 300, 2764],
    ],
  },
  {
    page: 272,
    areas: [
      [859, 510, 995, 639],
      [1485, 835, 1621, 964],
      [421, 1000, 557, 1129],
      [745, 1162, 881, 1291],
      [957, 1326, 1093, 1455],
      [1483, 1653, 1619, 1782],
      [713, 1817, 849, 1946],
      [919, 1978, 1055, 2107],
      [572, 2144, 708, 2273],
      [479, 2305, 615, 2434],
      [249, 2469, 385, 2598],
      [153, 2634, 289, 2763],
    ],
  },
  {
    page: 273,
    areas: [
      [367, 346, 503, 475],
      [1300, 674, 1436, 803],
      [1492, 837, 1628, 966],
      [1496, 999, 1632, 1128],
      [545, 1163, 681, 1292],
      [1492, 1487, 1628, 1616],
      [920, 1818, 1056, 1947],
      [905, 2144, 1041, 2273],
      [1170, 2471, 1306, 2600],
      [141, 2634, 277, 2763],
    ],
  },
  {
    page: 274,
    areas: [
      [1021, 511, 1157, 640],
      [146, 673, 282, 802],
      [651, 999, 787, 1128],
      [296, 1163, 432, 1292],
      [1244, 1654, 1380, 1783],
      [287, 1815, 423, 1944],
      [1048, 2306, 1184, 2435],
      [147, 2633, 283, 2762],
    ],
  },

  {
    page: 275,
    areas: [
      [709, 509, 845, 638],
      [804, 672, 940, 801],
      [828, 1164, 964, 1293],
      [412, 1654, 548, 1783],
      [291, 1981, 427, 2110],
      [1462, 2471, 1598, 2600],
      [169, 2634, 305, 2763],
    ],
  },
  {
    page: 276,
    areas: [
      [1473, 835, 1609, 964],
      [664, 1327, 800, 1456],
      [1120, 1489, 1256, 1618],
      [716, 1653, 852, 1782],
      [1471, 1980, 1607, 2109],
      [1182, 2143, 1318, 2272],
      [330, 2470, 466, 2599],
      [162, 2634, 298, 2763],
    ],
  },
  {
    page: 277,
    areas: [
      [601, 509, 737, 638],
      [770, 999, 906, 1128],
      [1476, 1488, 1612, 1617],
      [871, 1817, 1007, 1946],
      [162, 2306, 298, 2435],
      [160, 2634, 296, 2763],
    ],
  },
  {
    page: 278,
    areas: [
      [1287, 672, 1423, 801],
      [531, 837, 667, 966],
      [895, 1163, 1031, 1292],
      [565, 1489, 701, 1618],
      [617, 1654, 753, 1783],
      [290, 1818, 426, 1947],
      [1476, 2143, 1612, 2272],
      [1477, 2471, 1613, 2600],
      [158, 2634, 294, 2763],
    ],
  },
  {
    page: 279,
    areas: [
      [1260, 673, 1396, 802],
      [860, 835, 996, 964],
      [1466, 1162, 1602, 1291],
      [168, 1489, 304, 1618],
      [410, 1817, 546, 1946],
      [708, 2144, 844, 2273],
      [662, 2307, 798, 2436],
      [167, 2634, 303, 2763],
    ],
  },
  {
    page: 280,
    areas: [
      [564, 510, 700, 639],
      [324, 999, 460, 1128],
      [1155, 1327, 1291, 1456],
      [169, 1489, 305, 1618],
      [1019, 1981, 1155, 2110],
      [425, 2307, 561, 2436],
      [1088, 2470, 1224, 2599],
      [176, 2634, 312, 2763],
    ],
  },
  {
    page: 281,
    areas: [
      [140, 510, 276, 639],
      [1487, 835, 1623, 964],
      [1486, 1000, 1622, 1129],
      [1490, 1162, 1626, 1291],
      [998, 1325, 1134, 1454],
      [889, 1653, 1025, 1782],
      [136, 1980, 272, 2109],
      [894, 2307, 1030, 2436],
      [1488, 2633, 1624, 2762],
      [136, 2633, 272, 2762],
    ],
  },
  {
    page: 282,
    areas: [
      [969, 1002, 1105, 1131],
      [161, 1167, 297, 1296],
      [155, 1329, 291, 1458],
      [777, 1656, 913, 1785],
      [936, 1984, 1072, 2113],
      [159, 2147, 295, 2276],
      [158, 2637, 294, 2766],
    ],
  },
  {
    page: 283,
    areas: [
      [1276, 510, 1412, 639],
      [166, 673, 302, 802],
      [161, 837, 297, 966],
      [161, 999, 297, 1128],
      [393, 1490, 529, 1619],
      [1162, 1817, 1298, 1946],
      [1468, 1980, 1604, 2109],
      [1309, 2307, 1445, 2436],
      [641, 2469, 777, 2598],
      [162, 2633, 298, 2762],
    ],
  },
  {
    page: 284,
    areas: [
      [416, 510, 552, 639],
      [1032, 836, 1168, 965],
      [615, 999, 751, 1128],
      [1478, 1325, 1614, 1454],
      [152, 1325, 288, 1454],
      [381, 1817, 517, 1946],
      [1306, 2141, 1442, 2270],
      [698, 2306, 834, 2435],
      [455, 2469, 591, 2598],
      [154, 2633, 290, 2762],
    ],
  },
  {
    page: 285,
    areas: [
      [1292, 510, 1428, 639],
      [703, 673, 839, 802],
      [400, 836, 536, 965],
      [1162, 1164, 1298, 1293],
      [1297, 1327, 1433, 1456],
      [898, 1653, 1034, 1782],
      [1301, 1980, 1437, 2109],
      [873, 2143, 1009, 2272],
      [147, 2307, 283, 2436],
      [1182, 2634, 1318, 2763],
      [145, 2634, 281, 2763],
    ],
  },
  {
    page: 286,
    areas: [
      [638, 509, 774, 638],
      [155, 673, 291, 802],
      [156, 836, 292, 965],
      [1476, 1164, 1612, 1293],
      [510, 1163, 646, 1292],
      [465, 1490, 601, 1619],
      [1286, 1817, 1422, 1946],
      [1467, 2143, 1603, 2272],
      [325, 2306, 461, 2435],
      [155, 2469, 291, 2598],
      [153, 2633, 289, 2762],
    ],
  },
  {
    page: 287,
    areas: [
      [768, 346, 904, 475],
      [1127, 836, 1263, 965],
      [829, 1000, 965, 1129],
      [1207, 1326, 1343, 1455],
      [450, 1489, 586, 1618],
      [1027, 1816, 1163, 1945],
      [457, 1979, 593, 2108],
      [155, 2306, 291, 2435],
      [153, 2632, 289, 2761],
    ],
  },
  {
    page: 288,
    areas: [
      [1197, 672, 1333, 801],
      [160, 999, 296, 1128],
      [720, 1325, 856, 1454],
      [1014, 1653, 1150, 1782],
      [747, 1816, 883, 1945],
      [1310, 2306, 1446, 2435],
      [1047, 2470, 1183, 2599],
      [164, 2634, 300, 2763],
    ],
  },
  {
    page: 289,
    areas: [
      [637, 509, 773, 638],
      [1225, 835, 1361, 964],
      [985, 1161, 1121, 1290],
      [736, 1488, 872, 1617],
      [652, 1816, 788, 1945],
      [487, 1979, 623, 2108],
      [853, 2306, 989, 2435],
      [748, 2471, 884, 2600],
      [154, 2634, 290, 2763],
    ],
  },
  {
    page: 290,
    areas: [
      [538, 509, 674, 638],
      [265, 670, 401, 799],
      [790, 1000, 926, 1129],
      [371, 1163, 507, 1292],
      [928, 1489, 1064, 1618],
      [959, 1653, 1095, 1782],
      [290, 1817, 426, 1946],
      [1479, 2143, 1615, 2272],
      [1321, 2306, 1457, 2435],
      [711, 2470, 847, 2599],
      [143, 2632, 279, 2761],
    ],
  },
  {
    page: 291,
    areas: [
      [232, 346, 368, 475],
      [277, 671, 413, 800],
      [1137, 999, 1273, 1128],
      [1138, 1163, 1274, 1292],
      [716, 1327, 852, 1456],
      [133, 1490, 269, 1619],
      [961, 1980, 1097, 2109],
      [428, 2144, 564, 2273],
      [663, 2470, 799, 2599],
      [133, 2633, 269, 2762],
    ],
  },
  {
    page: 292,
    areas: [
      [165, 672, 301, 801],
      [640, 997, 776, 1126],
      [162, 1327, 298, 1456],
      [772, 1653, 908, 1782],
      [711, 1980, 847, 2109],
      [947, 2307, 1083, 2436],
      [860, 2469, 996, 2598],
      [160, 2633, 296, 2762],
    ],
  },
  {
    page: 293,
    areas: [
      [141, 275, 277, 404],
      [145, 440, 281, 569],
      [812, 767, 948, 896],
      [1062, 929, 1198, 1058],
      [1210, 1093, 1346, 1222],
      [1118, 1419, 1254, 1548],
      [138, 1583, 274, 1712],
      [141, 2145, 277, 2274],
      [411, 2472, 547, 2601],
      [1249, 2637, 1385, 2766],
      [137, 2636, 273, 2765],
    ],
  },
  {
    page: 294,
    areas: [
      [703, 512, 839, 641],
      [261, 675, 397, 804],
      [1480, 1001, 1616, 1130],
      [469, 1001, 605, 1130],
      [151, 1165, 287, 1294],
      [818, 1491, 954, 1620],
      [849, 1655, 985, 1784],
      [924, 1818, 1060, 1947],
      [375, 1982, 511, 2111],
      [345, 2307, 481, 2436],
      [152, 2635, 288, 2764],
    ],
  },
  {
    page: 295,
    areas: [
      [1464, 674, 1600, 803],
      [686, 1164, 822, 1293],
      [761, 1653, 897, 1782],
      [1021, 2471, 1157, 2600],
      [167, 2634, 303, 2763],
    ],
  },

  {
    page: 296,
    areas: [
      [592, 838, 728, 967],
      [650, 1491, 786, 1620],
      [936, 1655, 1072, 1784],
      [1466, 1982, 1602, 2111],
      [1466, 2145, 1602, 2274],
      [1001, 2471, 1137, 2600],
      [166, 2633, 302, 2762],
    ],
  },

  {
    page: 297,
    areas: [
      [1226, 838, 1362, 967],
      [851, 1328, 987, 1457],
      [346, 1490, 482, 1619],
      [426, 1981, 562, 2110],
      [882, 2308, 1018, 2437],
      [600, 2472, 736, 2601],
      [157, 2633, 293, 2762],
    ],
  },

  {
    page: 298,
    areas: [
      [1359, 511, 1495, 640],
      [826, 674, 962, 803],
      [1485, 1001, 1621, 1130],
      [355, 1002, 491, 1131],
      [938, 1328, 1074, 1457],
      [1379, 1655, 1515, 1784],
      [144, 1655, 280, 1784],
      [456, 1981, 592, 2110],
      [490, 2144, 626, 2273],
      [728, 2307, 864, 2436],
      [146, 2634, 282, 2763],
    ],
  },
  {
    page: 299,
    areas: [
      [743, 510, 879, 639],
      [388, 674, 524, 803],
      [939, 1001, 1075, 1130],
      [783, 1492, 919, 1621],
      [951, 1982, 1087, 2111],
      [1465, 2308, 1601, 2437],
      [552, 2469, 688, 2598],
      [162, 2634, 298, 2763],
    ],
  },
  {
    page: 300,
    areas: [
      [768, 511, 904, 640],
      [685, 838, 821, 967],
      [274, 1165, 410, 1294],
      [338, 1655, 474, 1784],
      [172, 1980, 308, 2109],
      [1227, 2307, 1363, 2436],
      [451, 2471, 587, 2600],
      [170, 2634, 306, 2763],
    ],
  },

  {
    page: 301,
    areas: [
      [1212, 510, 1348, 639],
      [1134, 838, 1270, 967],
      [1264, 1001, 1400, 1130],
      [764, 1165, 900, 1294],
      [645, 1328, 781, 1457],
      [1205, 1492, 1341, 1621],
      [253, 1490, 389, 1619],
      [265, 1654, 401, 1783],
      [1464, 1981, 1600, 2110],
      [601, 2145, 737, 2274],
      [966, 2307, 1102, 2436],
      [954, 2471, 1090, 2600],
      [157, 2636, 293, 2765],
    ],
  },
  {
    page: 302,
    areas: [
      [328, 347, 464, 476],
      [1459, 674, 1595, 803],
      [647, 1002, 783, 1131],
      [293, 1164, 429, 1293],
      [455, 1493, 591, 1622],
      [1460, 1819, 1596, 1948],
      [1453, 1981, 1589, 2110],
      [405, 2471, 541, 2600],
      [168, 2635, 304, 2764],
    ],
  },
  {
    page: 303,
    areas: [
      [398, 348, 534, 477],
      [1461, 511, 1597, 640],
      [1213, 838, 1349, 967],
      [1012, 1002, 1148, 1131],
      [704, 1165, 840, 1294],
      [287, 1165, 423, 1294],
      [1191, 1492, 1327, 1621],
      [333, 1492, 469, 1621],
      [1346, 1654, 1482, 1783],
      [936, 1818, 1072, 1947],
      [1351, 2145, 1487, 2274],
      [1195, 2309, 1331, 2438],
      [1466, 2634, 1602, 2763],
      [159, 2636, 295, 2765],
    ],
  },

  {
    page: 304,
    areas: [
      [1357, 511, 1493, 640],
      [1152, 678, 1288, 807],
      [168, 673, 304, 802],
      [1348, 1001, 1484, 1130],
      [679, 1165, 815, 1294],
      [1304, 1327, 1440, 1456],
      [1112, 1491, 1248, 1620],
      [437, 1654, 573, 1783],
      [466, 1817, 602, 1946],
      [337, 1980, 473, 2109],
      [938, 2144, 1074, 2273],
      [243, 2307, 379, 2436],
      [164, 2634, 300, 2763],
    ],
  },

  {
    page: 305,
    areas: [
      [1093, 678, 1229, 807],
      [179, 677, 315, 806],
      [780, 841, 916, 970],
      [1289, 1168, 1425, 1297],
      [470, 1331, 606, 1460],
      [500, 1495, 636, 1624],
      [1445, 1821, 1581, 1950],
      [514, 1985, 650, 2114],
      [1282, 2312, 1418, 2441],
      [569, 2476, 705, 2605],
      [180, 2639, 316, 2768],
    ],
  },
  {
    page: 306,
    areas: [
      [179, 349, 315, 478],
      [588, 512, 724, 641],
      [969, 675, 1105, 804],
      [1030, 838, 1166, 967],
      [875, 1001, 1011, 1130],
      [367, 1165, 503, 1294],
      [651, 1328, 787, 1457],
      [634, 1491, 770, 1620],
      [522, 1655, 658, 1784],
      [804, 1980, 940, 2109],
      [1038, 2143, 1174, 2272],
      [179, 2308, 315, 2437],
      [180, 2471, 316, 2600],
      [172, 2633, 308, 2762],
    ],
  },
  {
    page: 307,
    areas: [
      [180, 511, 316, 640],
      [1328, 838, 1464, 967],
      [1204, 1000, 1340, 1129],
      [1123, 1164, 1259, 1293],
      [1357, 1328, 1493, 1457],
      [828, 1491, 964, 1620],
      [1145, 1654, 1281, 1783],
      [1049, 1818, 1185, 1947],
      [1018, 1982, 1154, 2111],
      [597, 2144, 733, 2273],
      [724, 2308, 860, 2437],
      [401, 2472, 537, 2601],
      [179, 2635, 315, 2764],
    ],
  },
  {
    page: 308,
    areas: [
      [1480, 511, 1616, 640],
      [305, 512, 441, 641],
      [586, 674, 722, 803],
      [302, 837, 438, 966],
      [1355, 1165, 1491, 1294],
      [1320, 1329, 1456, 1458],
      [881, 1491, 1017, 1620],
      [257, 1655, 393, 1784],
      [144, 1817, 280, 1946],
      [799, 2144, 935, 2273],
      [145, 2308, 281, 2437],
      [142, 2470, 278, 2599],
      [144, 2635, 280, 2764],
    ],
  },
  {
    page: 309,
    areas: [
      [455, 348, 591, 477],
      [943, 511, 1079, 640],
      [779, 674, 915, 803],
      [720, 838, 856, 967],
      [1021, 1001, 1157, 1130],
      [481, 1000, 617, 1129],
      [613, 1490, 749, 1619],
      [1465, 1817, 1601, 1946],
      [1021, 1981, 1157, 2110],
      [838, 2145, 974, 2274],
      [743, 2308, 879, 2437],
      [1011, 2472, 1147, 2601],
      [170, 2635, 306, 2764],
    ],
  },
  {
    page: 310,
    areas: [
      [1002, 510, 1138, 639],
      [1184, 674, 1320, 803],
      [1119, 838, 1255, 967],
      [680, 1001, 816, 1130],
      [666, 1165, 802, 1294],
      [1052, 1328, 1188, 1457],
      [1121, 1490, 1257, 1619],
      [1244, 1654, 1380, 1783],
      [262, 1818, 398, 1947],
      [344, 1980, 480, 2109],
      [1054, 2472, 1190, 2601],
      [175, 2634, 311, 2763],
    ],
  },

  {
    page: 311,
    areas: [
      [1472, 510, 1608, 639],
      [368, 511, 504, 640],
      [349, 674, 485, 803],
      [960, 836, 1096, 965],
      [1042, 1001, 1178, 1130],
      [1164, 1165, 1300, 1294],
      [1228, 1328, 1364, 1457],
      [153, 1328, 289, 1457],
      [583, 1491, 719, 1620],
      [1107, 1654, 1243, 1783],
      [1099, 1819, 1235, 1948],
      [296, 1818, 432, 1947],
      [1067, 1982, 1203, 2111],
      [652, 2144, 788, 2273],
      [1471, 2307, 1607, 2436],
      [557, 2308, 693, 2437],
      [481, 2472, 617, 2601],
      [1120, 2635, 1256, 2764],
      [150, 2634, 286, 2763],
    ],
  },
  {
    page: 312,
    areas: [
      [1178, 442, 1314, 571],
      [684, 605, 820, 734],
      [154, 768, 290, 897],
      [1346, 1331, 1482, 1460],
      [484, 1332, 620, 1461],
      [1220, 1495, 1356, 1624],
      [159, 1494, 295, 1623],
      [794, 1657, 930, 1786],
      [591, 1822, 727, 1951],
      [908, 1985, 1044, 2114],
      [1263, 2147, 1399, 2276],
      [438, 2149, 574, 2278],
      [910, 2474, 1046, 2603],
      [155, 2475, 291, 2604],
      [161, 2638, 297, 2767],
    ],
  },
  {
    page: 313,
    areas: [
      [751, 349, 887, 478],
      [625, 512, 761, 641],
      [483, 675, 619, 804],
      [432, 838, 568, 967],
      [1006, 1002, 1142, 1131],
      [376, 1165, 512, 1294],
      [1273, 1328, 1409, 1457],
      [418, 1327, 554, 1456],
      [493, 1493, 629, 1622],
      [304, 1655, 440, 1784],
      [1042, 1818, 1178, 1947],
      [284, 1819, 420, 1948],
      [991, 1982, 1127, 2111],
      [543, 1982, 679, 2111],
      [1335, 2144, 1471, 2273],
      [940, 2146, 1076, 2275],
      [296, 2145, 432, 2274],
      [1376, 2309, 1512, 2438],
      [875, 2307, 1011, 2436],
      [389, 2309, 525, 2438],
      [1369, 2472, 1505, 2601],
      [920, 2471, 1056, 2600],
      [341, 2472, 477, 2601],
      [955, 2635, 1091, 2764],
      [149, 2635, 285, 2764],
    ],
  },
  {
    page: 314,
    areas: [
      [835, 347, 971, 476],
      [638, 675, 774, 804],
      [144, 1165, 280, 1294],
      [987, 1328, 1123, 1457],
      [1269, 1491, 1405, 1620],
      [548, 1492, 684, 1621],
      [959, 1655, 1095, 1784],
      [1207, 1818, 1343, 1947],
      [1475, 1981, 1611, 2110],
      [1289, 2308, 1425, 2437],
      [1340, 2472, 1476, 2601],
      [632, 2473, 768, 2602],
      [895, 2635, 1031, 2764],
      [148, 2635, 284, 2764],
    ],
  },
  {
    page: 315,
    areas: [
      [156, 346, 292, 475],
      [284, 674, 420, 803],
      [333, 837, 469, 966],
      [297, 1001, 433, 1130],
      [668, 1163, 804, 1292],
      [783, 1327, 919, 1456],
      [1314, 1655, 1450, 1784],
      [1470, 1818, 1606, 1947],
      [446, 1817, 582, 1946],
      [957, 2143, 1093, 2272],
      [1282, 2307, 1418, 2436],
      [336, 2470, 472, 2599],
      [155, 2634, 291, 2763],
    ],
  },
  {
    page: 316,
    areas: [
      [281, 348, 417, 477],
      [1466, 674, 1602, 803],
      [601, 674, 737, 803],
      [1194, 838, 1330, 967],
      [606, 1002, 742, 1131],
      [773, 1164, 909, 1293],
      [966, 1655, 1102, 1784],
      [1170, 1982, 1306, 2111],
      [724, 2145, 860, 2274],
      [641, 2308, 777, 2437],
      [416, 2470, 552, 2599],
      [166, 2633, 302, 2762],
    ],
  },
  {
    page: 317,
    areas: [
      [529, 511, 665, 640],
      [610, 675, 746, 804],
      [1228, 839, 1364, 968],
      [272, 1001, 408, 1130],
      [625, 1328, 761, 1457],
      [709, 1492, 845, 1621],
      [1091, 1656, 1227, 1785],
      [1195, 1817, 1331, 1946],
      [1241, 1982, 1377, 2111],
      [1270, 2470, 1406, 2599],
      [157, 2634, 293, 2763],
    ],
  },

  {
    page: 318,
    areas: [
      [1008, 510, 1144, 639],
      [873, 674, 1009, 803],
      [1322, 1003, 1458, 1132],
      [1465, 1165, 1601, 1294],
      [499, 1165, 635, 1294],
      [1049, 1328, 1185, 1457],
      [1341, 1655, 1477, 1784],
      [503, 1654, 639, 1783],
      [378, 1981, 514, 2110],
      [341, 2472, 477, 2601],
      [163, 2635, 299, 2764],
    ],
  },
  {
    page: 319,
    areas: [
      [1223, 513, 1359, 642],
      [1458, 674, 1594, 803],
      [373, 675, 509, 804],
      [537, 838, 673, 967],
      [880, 1001, 1016, 1130],
      [655, 1165, 791, 1294],
      [915, 1329, 1051, 1458],
      [161, 1328, 297, 1457],
      [777, 1491, 913, 1620],
      [1461, 1819, 1597, 1948],
      [1343, 1980, 1479, 2109],
      [1341, 2145, 1477, 2274],
      [1183, 2309, 1319, 2438],
      [903, 2472, 1039, 2601],
      [167, 2635, 303, 2764],
    ],
  },
  {
    page: 320,
    areas: [
      [435, 510, 571, 639],
      [410, 675, 546, 804],
      [1480, 1002, 1616, 1131],
      [1012, 1164, 1148, 1293],
      [148, 1165, 284, 1294],
      [640, 1328, 776, 1457],
      [1301, 1655, 1437, 1784],
      [145, 1818, 281, 1947],
      [630, 1982, 766, 2111],
      [533, 2309, 669, 2438],
      [1320, 2635, 1456, 2764],
      [149, 2635, 285, 2764],
    ],
  },
  {
    page: 321,
    areas: [
      [333, 347, 469, 476],
      [1321, 674, 1457, 803],
      [402, 838, 538, 967],
      [488, 1002, 624, 1131],
      [249, 1328, 385, 1457],
      [669, 1654, 805, 1783],
      [1465, 1981, 1601, 2110],
      [1033, 2145, 1169, 2274],
      [856, 2472, 992, 2601],
      [163, 2635, 299, 2764],
    ],
  },

  {
    page: 322,
    areas: [
      [171, 678, 307, 807],
      [1242, 1004, 1378, 1133],
      [1178, 1331, 1314, 1460],
      [932, 1494, 1068, 1623],
      [1457, 1822, 1593, 1951],
      [1457, 1985, 1593, 2114],
      [663, 2149, 799, 2278],
      [504, 2312, 640, 2441],
      [171, 2475, 307, 2604],
      [169, 2637, 305, 2766],
    ],
  },

  {
    page: 323,
    areas: [
      [1207, 510, 1343, 639],
      [137, 511, 273, 640],
      [1303, 837, 1439, 966],
      [517, 837, 653, 966],
      [419, 1001, 555, 1130],
      [652, 1165, 788, 1294],
      [547, 1328, 683, 1457],
      [1495, 1656, 1631, 1785],
      [737, 1817, 873, 1946],
      [1222, 1982, 1358, 2111],
      [1490, 2145, 1626, 2274],
      [1192, 2308, 1328, 2437],
      [225, 2309, 361, 2438],
      [137, 2635, 273, 2764],
    ],
  },

  {
    page: 324,
    areas: [
      [1049, 510, 1185, 639],
      [863, 675, 999, 804],
      [960, 837, 1096, 966],
      [1483, 1164, 1619, 1293],
      [632, 1328, 768, 1457],
      [598, 1654, 734, 1783],
      [1258, 1981, 1394, 2110],
      [1089, 2144, 1225, 2273],
      [785, 2306, 921, 2435],
      [596, 2471, 732, 2600],
      [141, 2634, 277, 2763],
    ],
  },

  {
    page: 325,
    areas: [
      [988, 675, 1124, 804],
      [854, 839, 990, 968],
      [848, 1001, 984, 1130],
      [1062, 1329, 1198, 1458],
      [523, 1492, 659, 1621],
      [1101, 1817, 1237, 1946],
      [245, 1982, 381, 2111],
      [607, 2308, 743, 2437],
      [165, 2634, 301, 2763],
    ],
  },

  {
    page: 326,
    areas: [
      [1137, 510, 1273, 639],
      [501, 674, 637, 803],
      [1466, 1165, 1602, 1294],
      [1201, 1327, 1337, 1456],
      [967, 1491, 1103, 1620],
      [1246, 1654, 1382, 1783],
      [1171, 1817, 1307, 1946],
      [1076, 1981, 1212, 2110],
      [153, 1980, 289, 2109],
      [281, 2144, 417, 2273],
      [710, 2308, 846, 2437],
      [1465, 2633, 1601, 2762],
      [159, 2633, 295, 2762],
    ],
  },

  {
    page: 327,
    areas: [
      [1469, 510, 1605, 639],
      [155, 512, 291, 641],
      [489, 675, 625, 804],
      [598, 837, 734, 966],
      [841, 1001, 977, 1130],
      [487, 1164, 623, 1293],
      [509, 1327, 645, 1456],
      [315, 1491, 451, 1620],
      [1269, 1817, 1405, 1946],
      [1206, 1981, 1342, 2110],
      [1241, 2143, 1377, 2272],
      [156, 2144, 292, 2273],
      [437, 2307, 573, 2436],
      [359, 2469, 495, 2598],
      [154, 2633, 290, 2762],
    ],
  },
  {
    page: 328,
    areas: [
      [1249, 674, 1385, 803],
      [1238, 1001, 1374, 1130],
      [1466, 1165, 1602, 1294],
      [598, 1328, 734, 1457],
      [1273, 1654, 1409, 1783],
      [161, 1818, 297, 1947],
      [158, 2144, 294, 2273],
      [993, 2471, 1129, 2600],
      [164, 2635, 300, 2764],
    ],
  },
  {
    page: 329,
    areas: [
      [565, 511, 701, 640],
      [170, 674, 306, 803],
      [169, 1000, 305, 1129],
      [1461, 1328, 1597, 1457],
      [1460, 1492, 1596, 1621],
      [809, 1818, 945, 1947],
      [455, 1981, 591, 2110],
      [1461, 2309, 1597, 2438],
      [165, 2634, 301, 2763],
    ],
  },
  {
    page: 330,
    areas: [
      [447, 511, 583, 640],
      [173, 674, 309, 803],
      [171, 838, 307, 967],
      [678, 1165, 814, 1294],
      [672, 1328, 808, 1457],
      [171, 1491, 307, 1620],
      [1143, 1982, 1279, 2111],
      [408, 2145, 544, 2274],
      [172, 2308, 308, 2437],
      [417, 2471, 553, 2600],
      [173, 2634, 309, 2763],
    ],
  },
  {
    page: 331,
    areas: [
      [1223, 511, 1359, 640],
      [1455, 838, 1591, 967],
      [1457, 1165, 1593, 1294],
      [678, 1327, 814, 1456],
      [1040, 1491, 1176, 1620],
      [1451, 1654, 1587, 1783],
      [873, 1818, 1009, 1947],
      [173, 1981, 309, 2110],
      [1449, 2308, 1585, 2437],
      [257, 2307, 393, 2436],
      [168, 2470, 304, 2599],
    ],
  },
  {
    page: 332,
    areas: [
      [1280, 659, 1416, 788],
      [1472, 1148, 1608, 1277],
      [1092, 1310, 1228, 1439],
      [817, 1475, 953, 1604],
      [147, 2617, 283, 2746],
    ],
  },
  {
    page: 333,
    areas: [
      [1466, 511, 1602, 640],
      [1305, 674, 1441, 803],
      [1092, 839, 1228, 968],
      [324, 1001, 460, 1130],
      [405, 1165, 541, 1294],
      [1045, 1655, 1181, 1784],
      [426, 1817, 562, 1946],
      [157, 1981, 293, 2110],
      [374, 2307, 510, 2436],
      [157, 2634, 293, 2763],
    ],
  },
  {
    page: 334,
    areas: [
      [1456, 511, 1592, 640],
      [517, 838, 653, 967],
      [881, 1491, 1017, 1620],
      [797, 1817, 933, 1946],
      [1240, 1981, 1376, 2110],
      [586, 1981, 722, 2110],
      [1462, 2308, 1598, 2437],
      [164, 2635, 300, 2764],
    ],
  },
  {
    page: 335,
    areas: [
      [1465, 511, 1601, 640],
      [155, 837, 291, 966],
      [1240, 1328, 1376, 1457],
      [430, 1491, 566, 1620],
      [1025, 1982, 1161, 2111],
      [437, 2145, 573, 2274],
      [154, 2634, 290, 2763],
    ],
  },
  {
    page: 336,
    areas: [
      [1498, 675, 1634, 804],
      [1501, 836, 1637, 965],
      [1278, 1001, 1414, 1130],
      [738, 1328, 874, 1457],
      [1088, 1655, 1224, 1784],
      [933, 2143, 1069, 2272],
      [450, 2471, 586, 2600],
      [124, 2634, 260, 2763],
    ],
  },
  {
    page: 337,
    areas: [
      [1283, 510, 1419, 639],
      [1317, 1165, 1453, 1294],
      [964, 1491, 1100, 1620],
      [819, 1655, 955, 1784],
      [149, 1654, 285, 1783],
      [659, 1980, 795, 2109],
      [712, 2307, 848, 2436],
      [146, 2634, 282, 2763],
    ],
  },
  {
    page: 338,
    areas: [
      [499, 511, 635, 640],
      [1489, 837, 1625, 966],
      [369, 837, 505, 966],
      [139, 1000, 275, 1129],
      [1484, 1328, 1620, 1457],
      [332, 1655, 468, 1784],
      [341, 1981, 477, 2110],
      [1242, 2472, 1378, 2601],
      [134, 2636, 270, 2765],
    ],
  },
  {
    page: 339,
    areas: [
      [481, 511, 617, 640],
      [164, 675, 300, 804],
      [1132, 1166, 1268, 1295],
      [936, 1328, 1072, 1457],
      [1087, 1654, 1223, 1783],
      [1464, 1981, 1600, 2110],
      [158, 2145, 294, 2274],
      [645, 2472, 781, 2601],
      [159, 2635, 295, 2764],
    ],
  },
  {
    page: 340,
    areas: [
      [792, 675, 928, 804],
      [159, 839, 295, 968],
      [175, 1165, 311, 1294],
      [447, 1328, 583, 1457],
      [172, 1492, 308, 1621],
      [664, 1818, 800, 1947],
      [1209, 2145, 1345, 2274],
      [172, 2634, 308, 2763],
    ],
  },
  {
    page: 341,
    areas: [
      [926, 821, 1062, 950],
      [979, 985, 1115, 1114],
      [295, 1147, 431, 1276],
      [153, 1310, 289, 1439],
      [153, 1637, 289, 1766],
      [151, 2454, 287, 2583],
    ],
  },
  {
    page: 342,
    areas: [
      [1029, 471, 1165, 600],
      [147, 470, 283, 599],
      [646, 633, 782, 762],
      [1271, 796, 1407, 925],
      [336, 796, 472, 925],
      [143, 960, 279, 1089],
      [398, 1123, 534, 1252],
      [763, 1287, 899, 1416],
      [1229, 1450, 1365, 1579],
      [580, 1449, 716, 1578],
      [798, 1613, 934, 1742],
      [1096, 1776, 1232, 1905],
      [214, 1776, 350, 1905],
      [627, 2266, 763, 2395],
      [1303, 2430, 1439, 2559],
      [337, 2430, 473, 2559],
      [148, 2592, 284, 2721],
    ],
  },
  {
    page: 343,
    areas: [
      [1155, 512, 1291, 641],
      [614, 674, 750, 803],
      [443, 838, 579, 967],
      [1132, 1163, 1268, 1292],
      [326, 1163, 462, 1292],
      [1066, 1490, 1202, 1619],
      [408, 1819, 544, 1948],
      [524, 1981, 660, 2110],
      [1089, 2146, 1225, 2275],
      [164, 2635, 300, 2764],
    ],
  },
  {
    page: 344,
    areas: [
      [1014, 512, 1150, 641],
      [1259, 675, 1395, 804],
      [341, 675, 477, 804],
      [945, 839, 1081, 968],
      [640, 1002, 776, 1131],
      [1272, 1490, 1408, 1619],
      [1462, 1655, 1598, 1784],
      [1461, 1818, 1597, 1947],
      [603, 1817, 739, 1946],
      [536, 1982, 672, 2111],
      [372, 2144, 508, 2273],
      [1003, 2307, 1139, 2436],
      [163, 2307, 299, 2436],
      [1198, 2634, 1334, 2763],
      [163, 2634, 299, 2763],
    ],
  },
  {
    page: 345,
    areas: [
      [631, 348, 767, 477],
      [770, 675, 906, 804],
      [766, 838, 902, 967],
      [806, 1002, 942, 1131],
      [972, 1163, 1108, 1292],
      [1493, 1327, 1629, 1456],
      [312, 1327, 448, 1456],
      [1496, 1654, 1632, 1783],
      [1165, 1819, 1301, 1948],
      [1305, 1981, 1441, 2110],
      [1318, 2145, 1454, 2274],
      [505, 2145, 641, 2274],
      [920, 2308, 1056, 2437],
      [1494, 2471, 1630, 2600],
      [427, 2471, 563, 2600],
      [979, 2634, 1115, 2763],
      [131, 2634, 267, 2763],
    ],
  },
  {
    page: 346,
    areas: [
      [148, 348, 284, 477],
      [416, 512, 552, 641],
      [155, 675, 291, 804],
      [1285, 1002, 1421, 1131],
      [1473, 1166, 1609, 1295],
      [522, 1166, 658, 1295],
      [427, 1329, 563, 1458],
      [1004, 1491, 1140, 1620],
      [1049, 1655, 1185, 1784],
      [1477, 1818, 1613, 1947],
      [1293, 1982, 1429, 2111],
      [984, 2309, 1120, 2438],
      [1087, 2472, 1223, 2601],
      [142, 2471, 278, 2600],
      [143, 2634, 279, 2763],
    ],
  },
  {
    page: 347,
    areas: [
      [1239, 511, 1375, 640],
      [1148, 675, 1284, 804],
      [1026, 838, 1162, 967],
      [827, 1001, 963, 1130],
      [1092, 1165, 1228, 1294],
      [713, 1328, 849, 1457],
      [1199, 1492, 1335, 1621],
      [1251, 1655, 1387, 1784],
      [906, 1817, 1042, 1946],
      [947, 1981, 1083, 2110],
      [1484, 2144, 1620, 2273],
      [1482, 2307, 1618, 2436],
      [425, 2308, 561, 2437],
      [1134, 2634, 1270, 2763],
      [145, 2634, 281, 2763],
    ],
  },
  {
    page: 348,
    areas: [
      [623, 347, 759, 476],
      [309, 673, 445, 802],
      [373, 837, 509, 966],
      [903, 1001, 1039, 1130],
      [1204, 1164, 1340, 1293],
      [154, 1165, 290, 1294],
      [154, 1327, 290, 1456],
      [458, 1490, 594, 1619],
      [1049, 1654, 1185, 1783],
      [1258, 1818, 1394, 1947],
      [404, 1981, 540, 2110],
      [158, 2144, 294, 2273],
      [325, 2307, 461, 2436],
      [1241, 2633, 1377, 2762],
      [151, 2635, 287, 2764],
    ],
  },
  {
    page: 349,
    areas: [
      [256, 344, 392, 473],
      [239, 508, 375, 637],
      [519, 670, 655, 799],
      [1140, 832, 1276, 961],
      [423, 997, 559, 1126],
      [129, 1159, 265, 1288],
      [237, 1323, 373, 1452],
      [646, 1487, 782, 1616],
      [971, 1650, 1107, 1779],
      [1122, 1812, 1258, 1941],
      [1033, 1977, 1169, 2106],
      [731, 2140, 867, 2269],
      [1200, 2467, 1336, 2596],
      [125, 2467, 261, 2596],
    ],
  },
  {
    page: 350,
    areas: [
      [1468, 668, 1604, 797],
      [805, 995, 941, 1124],
      [1243, 1321, 1379, 1450],
      [1237, 1649, 1373, 1778],
      [1314, 1811, 1450, 1940],
      [159, 1976, 295, 2105],
      [328, 2138, 464, 2267],
      [1461, 2465, 1597, 2594],
      [154, 2464, 290, 2593],
      [152, 2629, 288, 2758],
    ],
  },

  {
    page: 351,
    areas: [
      [840, 676, 976, 805],
      [276, 838, 412, 967],
      [930, 1165, 1066, 1294],
      [157, 1330, 293, 1459],
      [613, 1655, 749, 1784],
      [1467, 1982, 1603, 2111],
      [157, 1983, 293, 2112],
      [434, 2144, 570, 2273],
      [329, 2472, 465, 2601],
      [155, 2634, 291, 2763],
    ],
  },
  {
    page: 352,
    areas: [
      [864, 837, 1000, 966],
      [1061, 1326, 1197, 1455],
      [162, 1490, 298, 1619],
      [1455, 1818, 1591, 1947],
      [1291, 1981, 1427, 2110],
      [463, 2307, 599, 2436],
      [163, 2633, 299, 2762],
    ],
  },
  {
    page: 353,
    areas: [
      [1329, 675, 1465, 804],
      [153, 838, 289, 967],
      [508, 1165, 644, 1294],
      [152, 2636, 288, 2765],
    ],
  },
  {
    page: 354,
    areas: [
      [148, 509, 284, 638],
      [1478, 1491, 1614, 1620],
      [763, 1654, 899, 1783],
      [757, 2471, 893, 2600],
      [149, 2635, 285, 2764],
    ],
  },
  {
    page: 355,
    areas: [
      [157, 512, 293, 641],
      [918, 838, 1054, 967],
      [154, 1164, 290, 1293],
      [397, 1654, 533, 1783],
      [404, 1980, 540, 2109],
      [602, 2144, 738, 2273],
      [153, 2634, 289, 2763],
    ],
  },

  {
    page: 356,
    areas: [
      [170, 347, 306, 476],
      [785, 838, 921, 967],
      [444, 1001, 580, 1130],
      [769, 1328, 905, 1457],
      [596, 1491, 732, 1620],
      [1035, 1654, 1171, 1783],
      [172, 1817, 308, 1946],
      [313, 2144, 449, 2273],
      [1452, 2471, 1588, 2600],
      [171, 2635, 307, 2764],
    ],
  },
  {
    page: 357,
    areas: [
      [981, 674, 1117, 803],
      [164, 1326, 300, 1455],
      [1279, 1653, 1415, 1782],
      [700, 1818, 836, 1947],
      [165, 2634, 301, 2763],
    ],
  },
  {
    page: 358,
    areas: [
      [1013, 674, 1149, 803],
      [1130, 1166, 1266, 1295],
      [166, 2635, 302, 2764],
    ],
  },
  {
    page: 359,
    areas: [
      [769, 932, 905, 1061],
      [430, 1422, 566, 1551],
      [158, 1749, 294, 1878],
      [1466, 2477, 1602, 2606],
      [154, 2640, 290, 2769],
    ],
  },
  {
    page: 360,
    areas: [
      [996, 675, 1132, 804],
      [1457, 1003, 1593, 1132],
      [814, 1166, 950, 1295],
      [312, 1330, 448, 1459],
      [377, 1656, 513, 1785],
      [322, 1983, 458, 2112],
      [1268, 2311, 1404, 2440],
      [238, 2472, 374, 2601],
      [168, 2635, 304, 2764],
    ],
  },
  {
    page: 361,
    areas: [
      [290, 348, 426, 477],
      [169, 511, 305, 640],
      [245, 676, 381, 805],
      [982, 1003, 1118, 1132],
      [719, 1165, 855, 1294],
      [693, 1492, 829, 1621],
      [305, 1819, 441, 1948],
      [170, 2146, 306, 2275],
      [169, 2637, 305, 2766],
    ],
  },
  {
    page: 362,
    areas: [
      [1466, 675, 1602, 804],
      [1128, 838, 1264, 967],
      [1137, 1001, 1273, 1130],
      [1096, 1167, 1232, 1296],
      [1280, 1329, 1416, 1458],
      [1057, 1493, 1193, 1622],
      [632, 1655, 768, 1784],
      [1149, 1819, 1285, 1948],
      [479, 1983, 615, 2112],
      [341, 2146, 477, 2275],
      [1266, 2472, 1402, 2601],
      [166, 2637, 302, 2766],
    ],
  },
  {
    page: 363,
    areas: [
      [163, 348, 299, 477],
      [881, 675, 1017, 804],
      [561, 839, 697, 968],
      [293, 1002, 429, 1131],
      [479, 1329, 615, 1458],
      [507, 1491, 643, 1620],
      [628, 1655, 764, 1784],
      [821, 1983, 957, 2112],
      [431, 2145, 567, 2274],
      [335, 2472, 471, 2601],
      [161, 2636, 297, 2765],
    ],
  },
  {
    page: 364,
    areas: [
      [763, 512, 899, 641],
      [1470, 839, 1606, 968],
      [590, 839, 726, 968],
      [164, 1002, 300, 1131],
      [914, 1329, 1050, 1458],
      [607, 1492, 743, 1621],
      [415, 1655, 551, 1784],
      [792, 1819, 928, 1948],
      [647, 1981, 783, 2110],
      [1096, 2309, 1232, 2438],
      [773, 2471, 909, 2600],
      [161, 2635, 297, 2764],
    ],
  },
  {
    page: 365,
    areas: [
      [721, 349, 857, 478],
      [391, 511, 527, 640],
      [1126, 838, 1262, 967],
      [1279, 1164, 1415, 1293],
      [648, 1329, 784, 1458],
      [268, 1492, 404, 1621],
      [1194, 1818, 1330, 1947],
      [336, 1983, 472, 2112],
      [584, 2145, 720, 2274],
      [1476, 2471, 1612, 2600],
      [592, 2472, 728, 2601],
      [150, 2636, 286, 2765],
    ],
  },
  {
    page: 366,
    areas: [
      [1294, 675, 1430, 804],
      [1262, 838, 1398, 967],
      [1262, 1165, 1398, 1294],
      [1282, 1328, 1418, 1457],
      [1087, 1491, 1223, 1620],
      [709, 1654, 845, 1783],
      [1046, 1981, 1182, 2110],
      [465, 2145, 601, 2274],
      [684, 2310, 820, 2439],
      [174, 2472, 310, 2601],
    ],
  },
  {
    page: 367,
    areas: [
      [1327, 508, 1463, 637],
      [566, 508, 702, 637],
      [1050, 670, 1186, 799],
      [947, 834, 1083, 963],
      [976, 998, 1112, 1127],
      [1141, 1162, 1277, 1291],
      [1349, 1325, 1485, 1454],
      [259, 1325, 395, 1454],
      [934, 1487, 1070, 1616],
      [1245, 1651, 1381, 1780],
      [581, 1652, 717, 1781],
      [1198, 1815, 1334, 1944],
      [1219, 1978, 1355, 2107],
      [236, 1977, 372, 2106],
      [472, 2141, 608, 2270],
      [750, 2304, 886, 2433],
      [1484, 2469, 1620, 2598],
      [142, 2469, 278, 2598],
      [149, 2631, 285, 2760],
    ],
  },
  {
    page: 368,
    areas: [
      [768, 348, 904, 477],
      [521, 511, 657, 640],
      [849, 674, 985, 803],
      [1476, 838, 1612, 967],
      [1482, 1002, 1618, 1131],
      [710, 1002, 846, 1131],
      [1272, 1164, 1408, 1293],
      [150, 1165, 286, 1294],
      [235, 1329, 371, 1458],
      [235, 1491, 371, 1620],
      [889, 1655, 1025, 1784],
      [1224, 1818, 1360, 1947],
      [342, 1818, 478, 1947],
      [893, 1982, 1029, 2111],
      [1334, 2146, 1470, 2275],
      [1213, 2309, 1349, 2438],
      [1478, 2471, 1614, 2600],
      [547, 2472, 683, 2601],
      [998, 2634, 1134, 2763],
      [147, 2635, 283, 2764],
    ],
  },
  {
    page: 369,
    areas: [
      [548, 348, 684, 477],
      [356, 512, 492, 641],
      [902, 674, 1038, 803],
      [1494, 837, 1630, 966],
      [1262, 1000, 1398, 1129],
      [1491, 1164, 1627, 1293],
      [791, 1165, 927, 1294],
      [135, 1165, 271, 1294],
      [1053, 1327, 1189, 1456],
      [505, 1655, 641, 1784],
      [1099, 1818, 1235, 1947],
      [1137, 1982, 1273, 2111],
      [1290, 2146, 1426, 2275],
      [362, 2146, 498, 2275],
      [1128, 2308, 1264, 2437],
      [578, 2308, 714, 2437],
      [1490, 2472, 1626, 2601],
      [688, 2471, 824, 2600],
      [135, 2472, 271, 2601],
      [781, 2633, 917, 2762],
      [137, 2635, 273, 2764],
    ],
  },
  {
    page: 370,
    areas: [
      [241, 348, 377, 477],
      [889, 512, 1025, 641],
      [155, 675, 291, 804],
      [1011, 839, 1147, 968],
      [154, 838, 290, 967],
      [964, 1001, 1100, 1130],
      [1277, 1164, 1413, 1293],
      [465, 1164, 601, 1293],
      [1199, 1327, 1335, 1456],
      [280, 1327, 416, 1456],
      [699, 1491, 835, 1620],
      [1302, 1654, 1438, 1783],
      [614, 1655, 750, 1784],
      [1134, 1817, 1270, 1946],
      [286, 1818, 422, 1947],
      [889, 1982, 1025, 2111],
      [1471, 2146, 1607, 2275],
      [794, 2145, 930, 2274],
      [1471, 2309, 1607, 2438],
      [644, 2310, 780, 2439],
      [1291, 2472, 1427, 2601],
      [1468, 2635, 1604, 2764],
      [156, 2635, 292, 2764],
    ],
  },
  {
    page: 371,
    areas: [
      [652, 348, 788, 477],
      [1301, 512, 1437, 641],
      [452, 512, 588, 641],
      [1292, 674, 1428, 803],
      [614, 675, 750, 804],
      [1323, 839, 1459, 968],
      [681, 838, 817, 967],
      [1466, 1001, 1602, 1130],
      [694, 1002, 830, 1131],
      [1178, 1164, 1314, 1293],
      [472, 1165, 608, 1294],
      [1280, 1328, 1416, 1457],
      [558, 1328, 694, 1457],
      [1175, 1492, 1311, 1621],
      [441, 1491, 577, 1620],
      [1150, 1653, 1286, 1782],
      [611, 1654, 747, 1783],
      [158, 1654, 294, 1783],
      [683, 1818, 819, 1947],
      [1090, 1981, 1226, 2110],
      [308, 1982, 444, 2111],
      [1029, 2145, 1165, 2274],
      [161, 2146, 297, 2275],
      [1040, 2309, 1176, 2438],
      [456, 2308, 592, 2437],
      [442, 2471, 578, 2600],
      [1237, 2636, 1373, 2765],
      [155, 2635, 291, 2764],
    ],
  },

  {
    page: 372,
    areas: [
      [682, 348, 818, 477],
      [1210, 511, 1346, 640],
      [553, 511, 689, 640],
      [1487, 674, 1623, 803],
      [222, 675, 358, 804],
      [1035, 838, 1171, 967],
      [1068, 1001, 1204, 1130],
      [1480, 1165, 1616, 1294],
      [837, 1164, 973, 1293],
      [1104, 1329, 1240, 1458],
      [291, 1327, 427, 1456],
      [1163, 1491, 1299, 1620],
      [308, 1491, 444, 1620],
      [1213, 1654, 1349, 1783],
      [588, 1655, 724, 1784],
      [561, 1817, 697, 1946],
      [1164, 1982, 1300, 2111],
      [142, 1982, 278, 2111],
      [751, 2145, 887, 2274],
      [139, 2145, 275, 2274],
      [743, 2309, 879, 2438],
      [143, 2309, 279, 2438],
      [1078, 2472, 1214, 2601],
      [1487, 2635, 1623, 2764],
      [139, 2636, 275, 2765],
    ],
  },
  {
    page: 373,
    areas: [
      [919, 347, 1055, 476],
      [378, 348, 514, 477],
      [241, 511, 377, 640],
      [946, 674, 1082, 803],
      [291, 676, 427, 805],
      [798, 837, 934, 966],
      [152, 837, 288, 966],
      [988, 1001, 1124, 1130],
      [993, 1163, 1129, 1292],
      [152, 1163, 288, 1292],
      [1057, 1329, 1193, 1458],
      [150, 1326, 286, 1455],
      [616, 1491, 752, 1620],
      [1477, 1655, 1613, 1784],
      [731, 1655, 867, 1784],
      [1154, 1818, 1290, 1947],
      [343, 1818, 479, 1947],
      [254, 1982, 390, 2111],
      [411, 2145, 547, 2274],
      [631, 2308, 767, 2437],
      [1274, 2472, 1410, 2601],
      [975, 2635, 1111, 2764],
      [152, 2635, 288, 2764],
    ],
  },
  {
    page: 374,
    areas: [
      [888, 348, 1024, 477],
      [1484, 511, 1620, 640],
      [882, 512, 1018, 641],
      [263, 511, 399, 640],
      [153, 674, 289, 803],
      [799, 839, 935, 968],
      [700, 1002, 836, 1131],
      [934, 1165, 1070, 1294],
      [149, 1165, 285, 1294],
      [802, 1327, 938, 1456],
      [149, 1328, 285, 1457],
      [973, 1491, 1109, 1620],
      [448, 1493, 584, 1622],
      [853, 1656, 989, 1785],
      [1291, 1819, 1427, 1948],
      [502, 1818, 638, 1947],
      [1086, 1981, 1222, 2110],
      [325, 1981, 461, 2110],
      [1209, 2143, 1345, 2272],
      [590, 2145, 726, 2274],
      [585, 2308, 721, 2437],
      [948, 2471, 1084, 2600],
      [149, 2472, 285, 2601],
      [151, 2635, 287, 2764],
    ],
  },
  {
    page: 375,
    areas: [
      [529, 346, 665, 475],
      [1123, 510, 1259, 639],
      [1204, 675, 1340, 804],
      [1116, 839, 1252, 968],
      [358, 838, 494, 967],
      [151, 1002, 287, 1131],
      [534, 1165, 670, 1294],
      [1154, 1328, 1290, 1457],
      [445, 1327, 581, 1456],
      [1290, 1492, 1426, 1621],
      [436, 1490, 572, 1619],
      [1350, 1655, 1486, 1784],
      [707, 1655, 843, 1784],
      [1020, 1818, 1156, 1947],
      [152, 1818, 288, 1947],
      [559, 1982, 695, 2111],
      [932, 2145, 1068, 2274],
      [1275, 2307, 1411, 2436],
      [313, 2309, 449, 2438],
      [1061, 2472, 1197, 2601],
      [382, 2472, 518, 2601],
      [998, 2635, 1134, 2764],
      [150, 2635, 286, 2764],
    ],
  },
  {
    page: 376,
    areas: [
      [741, 348, 877, 477],
      [1197, 511, 1333, 640],
      [448, 511, 584, 640],
      [1208, 674, 1344, 803],
      [307, 674, 443, 803],
      [959, 838, 1095, 967],
      [1155, 1001, 1291, 1130],
      [358, 1001, 494, 1130],
      [616, 1164, 752, 1293],
      [1049, 1328, 1185, 1457],
      [274, 1328, 410, 1457],
      [1101, 1491, 1237, 1620],
      [464, 1492, 600, 1621],
      [1309, 1654, 1445, 1783],
      [341, 1654, 477, 1783],
      [1157, 1818, 1293, 1947],
      [142, 1818, 278, 1947],
      [784, 1981, 920, 2110],
      [1252, 2145, 1388, 2274],
      [369, 2145, 505, 2274],
      [143, 2471, 279, 2600],
    ],
  },
  {
    page: 377,
    areas: [
      [467, 504, 603, 633],
      [1253, 668, 1389, 797],
      [985, 830, 1121, 959],
      [904, 994, 1040, 1123],
      [742, 1158, 878, 1287],
      [1108, 1321, 1244, 1450],
      [249, 1485, 385, 1614],
      [1290, 1811, 1426, 1940],
      [415, 1810, 551, 1939],
      [864, 2137, 1000, 2266],
      [1001, 2302, 1137, 2431],
      [1477, 2628, 1613, 2757],
      [148, 2627, 284, 2756],
    ],
  },
  {
    page: 378,
    areas: [
      [934, 511, 1070, 640],
      [154, 673, 290, 802],
      [355, 1002, 491, 1131],
      [160, 1164, 296, 1293],
      [1463, 1655, 1599, 1784],
      [159, 1980, 295, 2109],
      [1137, 2307, 1273, 2436],
      [799, 2471, 935, 2600],
      [164, 2635, 300, 2764],
    ],
  },
  {
    page: 379,
    areas: [
      [1104, 511, 1240, 640],
      [1035, 837, 1171, 966],
      [264, 1002, 400, 1131],
      [534, 1165, 670, 1294],
      [693, 1327, 829, 1456],
      [414, 1490, 550, 1619],
      [817, 1655, 953, 1784],
      [975, 1818, 1111, 1947],
      [164, 1818, 300, 1947],
      [1249, 2144, 1385, 2273],
      [983, 2308, 1119, 2437],
      [164, 2470, 300, 2599],
      [163, 2638, 299, 2767],
    ],
  },
  {
    page: 380,
    areas: [
      [695, 511, 831, 640],
      [253, 673, 389, 802],
      [168, 838, 304, 967],
      [1101, 1166, 1237, 1295],
      [643, 1655, 779, 1784],
      [494, 1817, 630, 1946],
      [1454, 2145, 1590, 2274],
      [1455, 2307, 1591, 2436],
      [169, 2635, 305, 2764],
    ],
  },
  {
    page: 381,
    areas: [
      [796, 511, 932, 640],
      [1169, 839, 1305, 968],
      [744, 1001, 880, 1130],
      [314, 1164, 450, 1293],
      [571, 1492, 707, 1621],
      [532, 1655, 668, 1784],
      [1454, 1981, 1590, 2110],
      [805, 2145, 941, 2274],
      [917, 2307, 1053, 2436],
      [496, 2471, 632, 2600],
      [168, 2635, 304, 2764],
    ],
  },
  {
    page: 382,
    areas: [
      [428, 511, 564, 640],
      [388, 675, 524, 804],
      [594, 838, 730, 967],
      [169, 1001, 305, 1130],
      [169, 1492, 305, 1621],
      [858, 1982, 994, 2111],
      [663, 2308, 799, 2437],
      [166, 2635, 302, 2764],
    ],
  },
  {
    page: 383,
    areas: [
      [175, 511, 311, 640],
      [1079, 837, 1215, 966],
      [646, 1001, 782, 1130],
      [483, 1165, 619, 1294],
      [173, 1329, 309, 1458],
      [1457, 1656, 1593, 1785],
      [168, 1655, 304, 1784],
      [370, 1818, 506, 1947],
      [386, 1982, 522, 2111],
      [271, 2145, 407, 2274],
      [447, 2309, 583, 2438],
      [552, 2471, 688, 2600],
      [171, 2635, 307, 2764],
    ],
  },
  {
    page: 384,
    areas: [
      [732, 348, 868, 477],
      [747, 511, 883, 640],
      [1133, 675, 1269, 804],
      [1094, 838, 1230, 967],
      [357, 1002, 493, 1131],
      [758, 1328, 894, 1457],
      [498, 1490, 634, 1619],
      [1463, 1818, 1599, 1947],
      [267, 1818, 403, 1947],
      [832, 2144, 968, 2273],
      [1274, 2471, 1410, 2600],
      [159, 2634, 295, 2763],
    ],
  },
  {
    page: 385,
    areas: [
      [166, 279, 302, 408],
      [979, 604, 1115, 733],
      [1221, 932, 1357, 1061],
      [405, 1094, 541, 1223],
      [165, 1259, 301, 1388],
      [1293, 1822, 1429, 1951],
      [493, 1822, 629, 1951],
      [245, 1986, 381, 2115],
      [1108, 2475, 1244, 2604],
      [163, 2639, 299, 2768],
    ],
  },
  {
    page: 386,
    areas: [
      [717, 510, 853, 639],
      [171, 837, 307, 966],
      [170, 1163, 306, 1292],
      [377, 1492, 513, 1621],
      [358, 1817, 494, 1946],
      [1456, 2146, 1592, 2275],
      [166, 2308, 302, 2437],
      [169, 2635, 305, 2764],
    ],
  },
  {
    page: 387,
    areas: [
      [1164, 511, 1300, 640],
      [1459, 1164, 1595, 1293],
      [1061, 1328, 1197, 1457],
      [1041, 1491, 1177, 1620],
      [1330, 1819, 1466, 1948],
      [166, 2146, 302, 2275],
      [166, 2471, 302, 2600],
      [169, 2635, 305, 2764],
    ],
  },
  {
    page: 388,
    areas: [
      [1235, 511, 1371, 640],
      [1070, 1001, 1206, 1130],
      [564, 1165, 700, 1294],
      [532, 1655, 668, 1784],
      [1449, 1982, 1585, 2111],
      [1154, 2471, 1290, 2600],
      [169, 2635, 305, 2764],
    ],
  },
  {
    page: 389,
    areas: [
      [1459, 838, 1595, 967],
      [1123, 1166, 1259, 1295],
      [1094, 1493, 1230, 1622],
      [1065, 1982, 1201, 2111],
      [1166, 2145, 1302, 2274],
      [171, 2309, 307, 2438],
      [172, 2635, 308, 2764],
    ],
  },
  {
    page: 390,
    areas: [
      [301, 512, 437, 641],
      [454, 839, 590, 968],
      [422, 1329, 558, 1458],
      [1080, 1655, 1216, 1784],
      [161, 1818, 297, 1947],
      [1078, 2145, 1214, 2274],
      [472, 2308, 608, 2437],
      [163, 2634, 299, 2763],
    ],
  },
  {
    page: 391,
    areas: [
      [1045, 512, 1181, 641],
      [567, 838, 703, 967],
      [178, 1165, 314, 1294],
      [1050, 1655, 1186, 1784],
      [1458, 2145, 1594, 2274],
      [839, 2308, 975, 2437],
      [165, 2635, 301, 2764],
    ],
  },
  {
    page: 392,
    areas: [
      [329, 347, 465, 476],
      [499, 512, 635, 641],
      [165, 675, 301, 804],
      [677, 1001, 813, 1130],
      [983, 1328, 1119, 1457],
      [390, 1491, 526, 1620],
      [832, 1981, 968, 2110],
      [639, 2309, 775, 2438],
      [168, 2635, 304, 2764],
    ],
  },
  {
    page: 393,
    areas: [
      [822, 512, 958, 641],
      [1087, 839, 1223, 968],
      [1030, 1002, 1166, 1131],
      [1173, 1330, 1309, 1459],
      [503, 1492, 639, 1621],
      [731, 1656, 867, 1785],
      [748, 1817, 884, 1946],
      [354, 1982, 490, 2111],
      [722, 2309, 858, 2438],
      [794, 2471, 930, 2600],
      [173, 2635, 309, 2764],
    ],
  },
  {
    page: 394,
    areas: [
      [186, 510, 322, 639],
      [913, 1003, 1049, 1132],
      [1441, 1329, 1577, 1458],
      [1138, 1491, 1274, 1620],
      [1183, 1819, 1319, 1948],
      [1440, 2309, 1576, 2438],
      [188, 2636, 324, 2765],
    ],
  },
  {
    page: 395,
    areas: [
      [578, 674, 714, 803],
      [390, 1003, 526, 1132],
      [392, 1329, 528, 1458],
      [725, 1654, 861, 1783],
      [847, 2145, 983, 2274],
      [1457, 2472, 1593, 2601],
      [173, 2635, 309, 2764],
    ],
  },
  {
    page: 396,
    areas: [
      [375, 441, 511, 570],
      [728, 768, 864, 897],
      [1106, 1094, 1242, 1223],
      [171, 1258, 307, 1387],
      [1349, 1820, 1485, 1949],
      [1255, 1984, 1391, 2113],
      [780, 2147, 916, 2276],
      [477, 2312, 613, 2441],
      [291, 2474, 427, 2603],
      [169, 2639, 305, 2768],
    ],
  },
  {
    page: 397,
    areas: [
      [548, 510, 684, 639],
      [173, 837, 309, 966],
      [1453, 1164, 1589, 1293],
      [1453, 1652, 1589, 1781],
      [1448, 1818, 1584, 1947],
      [853, 2144, 989, 2273],
      [1450, 2471, 1586, 2600],
      [173, 2634, 309, 2763],
    ],
  },
  {
    page: 398,
    areas: [
      [1455, 511, 1591, 640],
      [609, 675, 745, 804],
      [467, 1165, 603, 1294],
      [1212, 1492, 1348, 1621],
      [701, 1655, 837, 1784],
      [721, 1982, 857, 2111],
      [794, 2145, 930, 2274],
      [1188, 2472, 1324, 2601],
      [171, 2635, 307, 2764],
    ],
  },
  {
    page: 399,
    areas: [
      [1458, 675, 1594, 804],
      [750, 1165, 886, 1294],
      [358, 1329, 494, 1458],
      [1460, 1818, 1596, 1947],
      [169, 1982, 305, 2111],
      [1460, 2635, 1596, 2764],
      [166, 2634, 302, 2763],
    ],
  },
  {
    page: 400,
    areas: [
      [174, 511, 310, 640],
      [307, 838, 443, 967],
      [748, 1327, 884, 1456],
      [1453, 1655, 1589, 1784],
      [1453, 1817, 1589, 1946],
      [1448, 2144, 1584, 2273],
      [936, 2308, 1072, 2437],
      [169, 2635, 305, 2764],
    ],
  },
  {
    page: 401,
    areas: [
      [1473, 675, 1609, 804],
      [483, 1164, 619, 1293],
      [898, 1654, 1034, 1783],
      [364, 1817, 500, 1946],
      [1477, 2145, 1613, 2274],
      [976, 2308, 1112, 2437],
      [157, 2634, 293, 2763],
    ],
  },
  {
    page: 402,
    areas: [
      [166, 672, 302, 801],
      [1027, 1163, 1163, 1292],
      [317, 1327, 453, 1456],
      [798, 1654, 934, 1783],
      [1272, 1980, 1408, 2109],
      [1187, 2307, 1323, 2436],
      [167, 2633, 303, 2762],
    ],
  },
  {
    page: 403,
    areas: [
      [716, 509, 852, 638],
      [684, 672, 820, 801],
      [1489, 1000, 1625, 1129],
      [1489, 1162, 1625, 1291],
      [336, 1164, 472, 1293],
      [298, 1490, 434, 1619],
      [813, 1654, 949, 1783],
      [284, 1818, 420, 1947],
      [907, 2145, 1043, 2274],
      [510, 2307, 646, 2436],
      [146, 2635, 282, 2764],
    ],
  },
  {
    page: 404,
    areas: [
      [557, 443, 693, 572],
      [1156, 768, 1292, 897],
      [1204, 931, 1340, 1060],
      [1481, 1258, 1617, 1387],
      [345, 1422, 481, 1551],
      [158, 1585, 294, 1714],
      [1369, 2148, 1505, 2277],
      [937, 2149, 1073, 2278],
      [912, 2313, 1048, 2442],
      [159, 2476, 295, 2605],
      [161, 2640, 297, 2769],
    ],
  },
  {
    page: 405,
    areas: [
      [1473, 511, 1609, 640],
      [1472, 674, 1608, 803],
      [893, 1001, 1029, 1130],
      [1022, 1654, 1158, 1783],
      [264, 1818, 400, 1947],
      [410, 1981, 546, 2110],
      [881, 2143, 1017, 2272],
      [302, 2308, 438, 2437],
      [674, 2471, 810, 2600],
      [162, 2634, 298, 2763],
    ],
  },
  {
    page: 406,
    areas: [
      [916, 512, 1052, 641],
      [1071, 676, 1207, 805],
      [919, 838, 1055, 967],
      [1462, 1165, 1598, 1294],
      [1163, 1328, 1299, 1457],
      [643, 1655, 779, 1784],
      [831, 1983, 967, 2112],
      [961, 2308, 1097, 2437],
      [171, 2636, 307, 2765],
    ],
  },
  {
    page: 407,
    areas: [
      [663, 511, 799, 640],
      [759, 676, 895, 805],
      [683, 1002, 819, 1131],
      [140, 1491, 276, 1620],
      [648, 1820, 784, 1949],
      [1195, 2309, 1331, 2438],
      [645, 2473, 781, 2602],
      [147, 2636, 283, 2765],
    ],
  },
  {
    page: 408,
    areas: [
      [509, 511, 645, 640],
      [555, 674, 691, 803],
      [503, 839, 639, 968],
      [1101, 1166, 1237, 1295],
      [514, 1329, 650, 1458],
      [637, 1656, 773, 1785],
      [398, 1983, 534, 2112],
      [1161, 2473, 1297, 2602],
      [167, 2636, 303, 2765],
    ],
  },
  {
    page: 409,
    areas: [
      [888, 512, 1024, 641],
      [248, 676, 384, 805],
      [153, 839, 289, 968],
      [1249, 1166, 1385, 1295],
      [1250, 1492, 1386, 1621],
      [1248, 1819, 1384, 1948],
      [1478, 2310, 1614, 2439],
      [1481, 2472, 1617, 2601],
      [156, 2636, 292, 2765],
    ],
  },
  {
    page: 410,
    areas: [
      [1478, 512, 1614, 641],
      [1288, 675, 1424, 804],
      [704, 839, 840, 968],
      [161, 1165, 297, 1294],
      [898, 1492, 1034, 1621],
      [705, 1820, 841, 1949],
      [457, 1982, 593, 2111],
      [396, 2309, 532, 2438],
      [423, 2473, 559, 2602],
      [162, 2635, 298, 2764],
    ],
  },
  {
    page: 411,
    areas: [
      [1362, 678, 1498, 807],
      [496, 678, 632, 807],
      [1199, 842, 1335, 971],
      [999, 1005, 1135, 1134],
      [1137, 1169, 1273, 1298],
      [1131, 1497, 1267, 1626],
      [153, 1659, 289, 1788],
      [154, 1823, 290, 1952],
      [327, 1986, 463, 2115],
      [890, 2476, 1026, 2605],
      [161, 2639, 297, 2768],
    ],
  },
  {
    page: 412,
    areas: [
      [347, 511, 483, 640],
      [1123, 839, 1259, 968],
      [1145, 1165, 1281, 1294],
      [1022, 1655, 1158, 1784],
      [791, 1981, 927, 2110],
      [1128, 2309, 1264, 2438],
      [585, 2473, 721, 2602],
      [153, 2637, 289, 2766],
    ],
  },
  {
    page: 413,
    areas: [
      [640, 675, 776, 804],
      [555, 1002, 691, 1131],
      [875, 1329, 1011, 1458],
      [1485, 1656, 1621, 1785],
      [150, 1656, 286, 1785],
      [591, 1983, 727, 2112],
      [636, 2146, 772, 2275],
      [431, 2473, 567, 2602],
      [144, 2636, 280, 2765],
    ],
  },
  {
    page: 414,
    areas: [
      [1013, 675, 1149, 804],
      [368, 839, 504, 968],
      [595, 1165, 731, 1294],
      [1460, 1655, 1596, 1784],
      [1246, 2145, 1382, 2274],
      [174, 2472, 310, 2601],
    ],
  },
  {
    page: 415,
    areas: [
      [1334, 504, 1470, 633],
      [1464, 668, 1600, 797],
      [276, 833, 412, 962],
      [1232, 1322, 1368, 1451],
      [305, 1486, 441, 1615],
      [490, 1649, 626, 1778],
      [404, 1813, 540, 1942],
      [727, 1975, 863, 2104],
      [1080, 2303, 1216, 2432],
      [568, 2468, 704, 2597],
      [177, 2632, 313, 2761],
    ],
  },
  {
    page: 416,
    areas: [
      [1471, 676, 1607, 805],
      [162, 839, 298, 968],
      [424, 1165, 560, 1294],
      [640, 1493, 776, 1622],
      [1269, 1819, 1405, 1948],
      [1071, 1984, 1207, 2113],
      [1201, 2146, 1337, 2275],
      [584, 2309, 720, 2438],
      [161, 2636, 297, 2765],
    ],
  },
  {
    page: 417,
    areas: [
      [1025, 512, 1161, 641],
      [430, 677, 566, 806],
      [948, 1002, 1084, 1131],
      [375, 1166, 511, 1295],
      [1469, 1492, 1605, 1621],
      [1476, 1819, 1612, 1948],
      [160, 1983, 296, 2112],
      [162, 2146, 298, 2275],
      [1472, 2473, 1608, 2602],
      [160, 2472, 296, 2601],
    ],
  },
  {
    page: 418,
    areas: [
      [834, 669, 970, 798],
      [562, 832, 698, 961],
      [943, 996, 1079, 1125],
      [642, 1486, 778, 1615],
      [1470, 2139, 1606, 2268],
      [147, 2629, 283, 2758],
    ],
  },
  {
    page: 419,
    areas: [
      [163, 511, 299, 640],
      [1469, 838, 1605, 967],
      [1016, 1164, 1152, 1293],
      [956, 1490, 1092, 1619],
      [1130, 1654, 1266, 1783],
      [584, 1817, 720, 1946],
      [1353, 2308, 1489, 2437],
      [681, 2474, 817, 2603],
      [164, 2636, 300, 2765],
    ],
  },
  {
    page: 420,
    areas: [
      [1007, 511, 1143, 640],
      [1097, 838, 1233, 967],
      [360, 1002, 496, 1131],
      [570, 1656, 706, 1785],
      [999, 2145, 1135, 2274],
      [173, 2309, 309, 2438],
      [170, 2635, 306, 2764],
    ],
  },
  {
    page: 421,
    areas: [
      [371, 512, 507, 641],
      [481, 838, 617, 967],
      [865, 1165, 1001, 1294],
      [607, 1491, 743, 1620],
      [1201, 1818, 1337, 1947],
      [1157, 2146, 1293, 2275],
      [173, 2310, 309, 2439],
      [175, 2635, 311, 2764],
    ],
  },
  {
    page: 422,
    areas: [
      [458, 512, 594, 641],
      [326, 839, 462, 968],
      [1201, 1492, 1337, 1621],
      [170, 1654, 306, 1783],
      [168, 2637, 304, 2766],
    ],
  },
  {
    page: 423,
    areas: [
      [1356, 676, 1492, 805],
      [1500, 1493, 1636, 1622],
      [374, 1655, 510, 1784],
      [1192, 1983, 1328, 2112],
      [137, 2146, 273, 2275],
      [486, 2308, 622, 2437],
      [1279, 2472, 1415, 2601],
      [141, 2636, 277, 2765],
    ],
  },
  {
    page: 424,
    areas: [
      [335, 349, 471, 478],
      [370, 511, 506, 640],
      [757, 676, 893, 805],
      [959, 840, 1095, 969],
      [174, 1002, 310, 1131],
      [583, 1492, 719, 1621],
      [173, 2635, 309, 2764],
    ],
  },
  {
    page: 425,
    areas: [
      [461, 839, 597, 968],
      [1464, 1329, 1600, 1458],
      [250, 2472, 386, 2601],
      [170, 2636, 306, 2765],
    ],
  },
  {
    page: 426,
    areas: [
      [1474, 837, 1610, 966],
      [556, 1001, 692, 1130],
      [1288, 1329, 1424, 1458],
      [161, 1492, 297, 1621],
      [988, 1982, 1124, 2111],
      [464, 2308, 600, 2437],
      [541, 2472, 677, 2601],
      [163, 2635, 299, 2764],
    ],
  },
  {
    page: 427,
    areas: [
      [818, 512, 954, 641],
      [1211, 675, 1347, 804],
      [1472, 838, 1608, 967],
      [1076, 1002, 1212, 1131],
      [1007, 1165, 1143, 1294],
      [1017, 1328, 1153, 1457],
      [172, 1492, 308, 1621],
      [327, 1656, 463, 1785],
      [965, 1982, 1101, 2111],
      [711, 2308, 847, 2437],
      [171, 2636, 307, 2765],
    ],
  },
  {
    page: 428,
    areas: [
      [717, 842, 853, 971],
      [1047, 1169, 1183, 1298],
      [562, 1660, 698, 1789],
      [1171, 1987, 1307, 2116],
      [792, 2150, 928, 2279],
      [1089, 2477, 1225, 2606],
      [173, 2640, 309, 2769],
    ],
  },
  {
    page: 429,
    areas: [
      [804, 512, 940, 641],
      [921, 1001, 1057, 1130],
      [386, 1166, 522, 1295],
      [1293, 1492, 1429, 1621],
      [170, 1819, 306, 1948],
      [1210, 2308, 1346, 2437],
      [167, 2635, 303, 2764],
    ],
  },
  {
    page: 430,
    areas: [
      [1472, 676, 1608, 805],
      [1477, 1002, 1613, 1131],
      [158, 1001, 294, 1130],
      [156, 1328, 292, 1457],
      [1271, 1819, 1407, 1948],
      [991, 1984, 1127, 2113],
      [952, 2310, 1088, 2439],
      [157, 2635, 293, 2764],
    ],
  },
  {
    page: 431,
    areas: [
      [1478, 676, 1614, 805],
      [243, 839, 379, 968],
      [233, 1002, 369, 1131],
      [1479, 1330, 1615, 1459],
      [1039, 1495, 1175, 1624],
      [151, 1656, 287, 1785],
      [154, 1819, 290, 1948],
      [1482, 2146, 1618, 2275],
      [154, 2635, 290, 2764],
    ],
  },
  {
    page: 432,
    areas: [
      [445, 512, 581, 641],
      [684, 1165, 820, 1294],
      [170, 1329, 306, 1458],
      [167, 1492, 303, 1621],
      [1227, 1820, 1363, 1949],
      [625, 2146, 761, 2275],
      [254, 2310, 390, 2439],
      [168, 2637, 304, 2766],
    ],
  },
  {
    page: 433,
    areas: [
      [1275, 511, 1411, 640],
      [491, 675, 627, 804],
      [823, 1003, 959, 1132],
      [716, 1493, 852, 1622],
      [377, 1657, 513, 1786],
      [1014, 1982, 1150, 2111],
      [143, 2309, 279, 2438],
      [1142, 2636, 1278, 2765],
      [147, 2636, 283, 2765],
    ],
  },
  {
    page: 433,
    areas: [
      [1275, 511, 1411, 640],
      [491, 675, 627, 804],
      [823, 1003, 959, 1132],
      [716, 1493, 852, 1622],
      [377, 1657, 513, 1786],
      [1014, 1982, 1150, 2111],
      [143, 2309, 279, 2438],
      [1142, 2636, 1278, 2765],
      [147, 2636, 283, 2765],
    ],
  },
  {
    page: 434,
    areas: [
      [485, 278, 621, 407],
      [1153, 605, 1289, 734],
      [1161, 769, 1297, 898],
      [1161, 932, 1297, 1061],
      [866, 1096, 1002, 1225],
      [155, 1259, 291, 1388],
      [1231, 2150, 1367, 2279],
      [295, 2313, 431, 2442],
      [156, 2639, 292, 2768],
    ],
  },
  {
    page: 435,
    areas: [
      [1481, 511, 1617, 640],
      [895, 676, 1031, 805],
      [288, 838, 424, 967],
      [1048, 1166, 1184, 1295],
      [551, 1493, 687, 1622],
      [976, 1819, 1112, 1948],
      [1482, 2309, 1618, 2438],
      [167, 2636, 303, 2765],
    ],
  },
  {
    page: 436,
    areas: [
      [913, 838, 1049, 967],
      [252, 1329, 388, 1458],
      [1455, 1819, 1591, 1948],
      [1247, 1981, 1383, 2110],
      [171, 1982, 307, 2111],
      [897, 2146, 1033, 2275],
      [173, 2636, 309, 2765],
    ],
  },
  {
    page: 437,
    areas: [
      [767, 349, 903, 478],
      [1476, 511, 1612, 640],
      [838, 511, 974, 640],
      [226, 674, 362, 803],
      [1173, 838, 1309, 967],
      [970, 1002, 1106, 1131],
      [1309, 1329, 1445, 1458],
      [157, 1329, 293, 1458],
      [1093, 1819, 1229, 1948],
      [899, 2146, 1035, 2275],
      [708, 2472, 844, 2601],
      [158, 2636, 294, 2765],
    ],
  },
  {
    page: 438,
    areas: [
      [627, 512, 763, 641],
      [937, 1002, 1073, 1131],
      [161, 1166, 297, 1295],
      [1253, 1492, 1389, 1621],
      [657, 1655, 793, 1784],
      [563, 1983, 699, 2112],
      [637, 2473, 773, 2602],
      [162, 2637, 298, 2766],
    ],
  },
  {
    page: 439,
    areas: [
      [1038, 675, 1174, 804],
      [882, 1165, 1018, 1294],
      [989, 1492, 1125, 1621],
      [1010, 1820, 1146, 1949],
      [1459, 2309, 1595, 2438],
      [173, 2636, 309, 2765],
    ],
  },
  {
    page: 440,
    areas: [
      [152, 605, 288, 734],
      [1382, 1168, 1518, 1297],
      [819, 1169, 955, 1298],
      [245, 1168, 381, 1297],
      [1103, 1332, 1239, 1461],
      [490, 1331, 626, 1460],
      [813, 1496, 949, 1625],
      [1111, 1660, 1247, 1789],
      [909, 1824, 1045, 1953],
      [323, 1986, 459, 2115],
      [427, 2149, 563, 2278],
      [1051, 2476, 1187, 2605],
      [155, 2640, 291, 2769],
    ],
  },
  {
    page: 441,
    areas: [
      [150, 349, 286, 478],
      [1089, 676, 1225, 805],
      [581, 838, 717, 967],
      [1082, 1002, 1218, 1131],
      [145, 1002, 281, 1131],
      [1066, 1329, 1202, 1458],
      [873, 1492, 1009, 1621],
      [451, 1656, 587, 1785],
      [668, 1820, 804, 1949],
      [884, 1983, 1020, 2112],
      [1269, 2309, 1405, 2438],
      [553, 2309, 689, 2438],
      [996, 2473, 1132, 2602],
      [1247, 2636, 1383, 2765],
      [148, 2637, 284, 2766],
    ],
  },
  {
    page: 442,
    areas: [
      [1203, 512, 1339, 641],
      [1462, 675, 1598, 804],
      [1191, 839, 1327, 968],
      [947, 1003, 1083, 1132],
      [1463, 1165, 1599, 1294],
      [1022, 1329, 1158, 1458],
      [655, 1492, 791, 1621],
      [518, 1654, 654, 1783],
      [1029, 1982, 1165, 2111],
      [1020, 2146, 1156, 2275],
      [828, 2310, 964, 2439],
      [921, 2473, 1057, 2602],
      [174, 2636, 310, 2765],
    ],
  },
  {
    page: 443,
    areas: [
      [337, 347, 473, 476],
      [921, 511, 1057, 640],
      [1106, 678, 1242, 807],
      [278, 674, 414, 803],
      [158, 839, 294, 968],
      [1476, 1166, 1612, 1295],
      [1164, 1492, 1300, 1621],
      [1475, 1656, 1611, 1785],
      [1474, 1819, 1610, 1948],
      [159, 1820, 295, 1949],
      [1467, 2146, 1603, 2275],
      [820, 2309, 956, 2438],
      [547, 2475, 683, 2604],
      [163, 2637, 299, 2766],
    ],
  },
  {
    page: 444,
    areas: [
      [469, 348, 605, 477],
      [691, 511, 827, 640],
      [1237, 675, 1373, 804],
      [481, 674, 617, 803],
      [1115, 837, 1251, 966],
      [516, 1001, 652, 1130],
      [936, 1166, 1072, 1295],
      [1007, 1329, 1143, 1458],
      [1482, 1492, 1618, 1621],
      [464, 1492, 600, 1621],
      [1215, 1820, 1351, 1949],
      [789, 1983, 925, 2112],
      [1482, 2308, 1618, 2437],
      [153, 2309, 289, 2438],
      [1481, 2636, 1617, 2765],
      [152, 2636, 288, 2765],
    ],
  },
  {
    page: 445,
    areas: [
      [1251, 511, 1387, 640],
      [175, 512, 311, 641],
      [405, 674, 541, 803],
      [497, 839, 633, 968],
      [690, 1002, 826, 1131],
      [680, 1165, 816, 1294],
      [454, 1329, 590, 1458],
      [176, 1492, 312, 1621],
      [1459, 1819, 1595, 1948],
      [1123, 1983, 1259, 2112],
      [173, 2146, 309, 2275],
      [175, 2309, 311, 2438],
      [177, 2471, 313, 2600],
    ],
  },
  {
    page: 446,
    areas: [
      [1110, 511, 1246, 640],
      [609, 511, 745, 640],
      [130, 511, 266, 640],
      [1113, 676, 1249, 805],
      [1279, 837, 1415, 966],
      [320, 837, 456, 966],
      [1047, 1001, 1183, 1130],
      [1201, 1166, 1337, 1295],
      [457, 1165, 593, 1294],
      [804, 1329, 940, 1458],
      [533, 1492, 669, 1621],
      [1265, 1655, 1401, 1784],
      [681, 1656, 817, 1785],
      [1496, 1818, 1632, 1947],
      [725, 1819, 861, 1948],
      [1189, 1983, 1325, 2112],
      [654, 1982, 790, 2111],
      [1499, 2146, 1635, 2275],
      [530, 2147, 666, 2276],
      [1276, 2309, 1412, 2438],
      [128, 2309, 264, 2438],
      [342, 2473, 478, 2602],
      [793, 2636, 929, 2765],
      [138, 2636, 274, 2765],
    ],
  },
  {
    page: 447,
    areas: [
      [1052, 347, 1188, 476],
      [443, 349, 579, 478],
      [1049, 510, 1185, 639],
      [158, 511, 294, 640],
      [857, 676, 993, 805],
      [1022, 838, 1158, 967],
      [159, 838, 295, 967],
      [923, 1002, 1059, 1131],
      [1473, 1165, 1609, 1294],
      [684, 1164, 820, 1293],
      [848, 1329, 984, 1458],
      [1154, 1492, 1290, 1621],
      [282, 1492, 418, 1621],
      [903, 1655, 1039, 1784],
      [1474, 1819, 1610, 1948],
      [804, 1818, 940, 1947],
      [158, 1818, 294, 1947],
      [1011, 1983, 1147, 2112],
      [528, 1981, 664, 2110],
      [1471, 2145, 1607, 2274],
      [655, 2145, 791, 2274],
      [1473, 2309, 1609, 2438],
      [572, 2309, 708, 2438],
      [1167, 2473, 1303, 2602],
      [572, 2473, 708, 2602],
      [1067, 2636, 1203, 2765],
      [157, 2636, 293, 2765],
    ],
  },
  {
    page: 448,
    areas: [
      [854, 347, 990, 476],
      [1266, 512, 1402, 641],
      [631, 512, 767, 641],
      [1255, 674, 1391, 803],
      [536, 675, 672, 804],
      [927, 839, 1063, 968],
      [398, 838, 534, 967],
      [867, 1000, 1003, 1129],
      [166, 1002, 302, 1131],
      [783, 1164, 919, 1293],
      [1315, 1329, 1451, 1458],
      [522, 1329, 658, 1458],
      [898, 1492, 1034, 1621],
      [1469, 1656, 1605, 1785],
      [432, 1655, 568, 1784],
      [956, 1819, 1092, 1948],
      [159, 1819, 295, 1948],
      [851, 1983, 987, 2112],
      [162, 1982, 298, 2111],
      [620, 2146, 756, 2275],
      [1253, 2309, 1389, 2438],
      [164, 2308, 300, 2437],
      [751, 2472, 887, 2601],
      [1196, 2636, 1332, 2765],
      [166, 2637, 302, 2766],
    ],
  },
  {
    page: 449,
    areas: [
      [917, 348, 1053, 477],
      [268, 349, 404, 478],
      [1040, 511, 1176, 640],
      [304, 512, 440, 641],
      [1089, 675, 1225, 804],
      [469, 674, 605, 803],
      [1039, 838, 1175, 967],
      [327, 839, 463, 968],
      [842, 1003, 978, 1132],
      [1483, 1165, 1619, 1294],
      [798, 1165, 934, 1294],
      [151, 1165, 287, 1294],
      [1138, 1328, 1274, 1457],
      [561, 1329, 697, 1458],
      [1087, 1493, 1223, 1622],
      [539, 1492, 675, 1621],
      [1301, 1656, 1437, 1785],
      [723, 1655, 859, 1784],
      [1485, 1818, 1621, 1947],
      [743, 1817, 879, 1946],
      [1216, 1982, 1352, 2111],
      [153, 1981, 289, 2110],
      [725, 2146, 861, 2275],
      [1483, 2309, 1619, 2438],
      [808, 2308, 944, 2437],
      [150, 2636, 286, 2765],
    ],
  },
  {
    page: 450,
    areas: [
      [927, 349, 1063, 478],
      [252, 348, 388, 477],
      [476, 511, 612, 640],
      [1167, 675, 1303, 804],
      [503, 675, 639, 804],
      [1268, 838, 1404, 967],
      [720, 838, 856, 967],
      [1483, 1002, 1619, 1131],
      [735, 1002, 871, 1131],
      [1163, 1165, 1299, 1294],
      [712, 1328, 848, 1457],
      [1212, 1492, 1348, 1621],
      [1482, 1657, 1618, 1786],
      [583, 1656, 719, 1785],
      [1223, 1820, 1359, 1949],
      [313, 1819, 449, 1948],
      [902, 1983, 1038, 2112],
      [1480, 2144, 1616, 2273],
      [367, 2147, 503, 2276],
      [992, 2309, 1128, 2438],
      [150, 2310, 286, 2439],
      [898, 2472, 1034, 2601],
      [1242, 2636, 1378, 2765],
      [153, 2636, 289, 2765],
    ],
  },
  {
    page: 451,
    areas: [
      [900, 348, 1036, 477],
      [145, 349, 281, 478],
      [942, 511, 1078, 640],
      [380, 512, 516, 641],
      [1076, 676, 1212, 805],
      [374, 675, 510, 804],
      [1126, 837, 1262, 966],
      [372, 837, 508, 966],
      [1190, 1002, 1326, 1131],
      [617, 1001, 753, 1130],
      [1229, 1165, 1365, 1294],
      [537, 1165, 673, 1294],
      [1249, 1328, 1385, 1457],
      [461, 1329, 597, 1458],
      [1112, 1493, 1248, 1622],
      [399, 1492, 535, 1621],
      [1012, 1655, 1148, 1784],
      [150, 1656, 286, 1785],
      [672, 1819, 808, 1948],
      [1220, 1982, 1356, 2111],
      [150, 1983, 286, 2112],
      [830, 2147, 966, 2276],
      [1043, 2309, 1179, 2438],
      [1155, 2472, 1291, 2601],
      [245, 2473, 381, 2602],
      [981, 2636, 1117, 2765],
      [150, 2636, 286, 2765],
    ],
  },
  {
    page: 452,
    areas: [
      [1050, 351, 1186, 480],
      [646, 348, 782, 477],
      [1483, 513, 1619, 642],
      [686, 511, 822, 640],
      [532, 676, 668, 805],
      [1298, 839, 1434, 968],
      [635, 837, 771, 966],
      [144, 839, 280, 968],
      [1047, 1001, 1183, 1130],
      [380, 1002, 516, 1131],
      [1159, 1165, 1295, 1294],
      [592, 1165, 728, 1294],
      [1484, 1329, 1620, 1458],
      [974, 1330, 1110, 1459],
      [247, 1329, 383, 1458],
      [1013, 1491, 1149, 1620],
      [277, 1491, 413, 1620],
      [826, 1655, 962, 1784],
      [229, 1656, 365, 1785],
      [1059, 1820, 1195, 1949],
      [474, 1819, 610, 1948],
      [1295, 1982, 1431, 2111],
      [675, 1984, 811, 2113],
      [1089, 2146, 1225, 2275],
      [456, 2146, 592, 2275],
      [1204, 2310, 1340, 2439],
      [145, 2310, 281, 2439],
      [891, 2473, 1027, 2602],
      [139, 2473, 275, 2602],
    ],
  },
  {
    page: 453,
    areas: [
      [925, 511, 1061, 640],
      [149, 511, 285, 640],
      [300, 674, 436, 803],
      [153, 838, 289, 967],
      [440, 1003, 576, 1132],
      [148, 1164, 284, 1293],
      [310, 1329, 446, 1458],
      [1478, 1656, 1614, 1785],
      [365, 1655, 501, 1784],
      [145, 1819, 281, 1948],
      [528, 1983, 664, 2112],
      [767, 2147, 903, 2276],
      [831, 2309, 967, 2438],
      [1144, 2474, 1280, 2603],
      [1284, 2636, 1420, 2765],
      [147, 2635, 283, 2764],
    ],
  },
  {
    page: 454,
    areas: [
      [166, 348, 302, 477],
      [339, 511, 475, 640],
      [994, 675, 1130, 804],
      [1078, 837, 1214, 966],
      [1229, 1001, 1365, 1130],
      [901, 1329, 1037, 1458],
      [263, 1492, 399, 1621],
      [1386, 2144, 1522, 2273],
      [1460, 2309, 1596, 2438],
      [166, 2635, 302, 2764],
    ],
  },
  {
    page: 455,
    areas: [
      [877, 513, 1013, 642],
      [1491, 838, 1627, 967],
      [1267, 1001, 1403, 1130],
      [1490, 1166, 1626, 1295],
      [395, 1165, 531, 1294],
      [133, 1329, 269, 1458],
      [411, 1492, 547, 1621],
      [466, 1654, 602, 1783],
      [137, 1819, 273, 1948],
      [381, 1983, 517, 2112],
      [1093, 2147, 1229, 2276],
      [252, 2146, 388, 2275],
      [693, 2308, 829, 2437],
      [1351, 2473, 1487, 2602],
      [1134, 2636, 1270, 2765],
      [130, 2636, 266, 2765],
    ],
  },
  {
    page: 455,
    areas: [
      [877, 513, 1013, 642],
      [1491, 838, 1627, 967],
      [1267, 1001, 1403, 1130],
      [1490, 1166, 1626, 1295],
      [395, 1165, 531, 1294],
      [133, 1329, 269, 1458],
      [411, 1492, 547, 1621],
      [466, 1654, 602, 1783],
      [137, 1819, 273, 1948],
      [381, 1983, 517, 2112],
      [1093, 2147, 1229, 2276],
      [252, 2146, 388, 2275],
      [693, 2308, 829, 2437],
      [1351, 2473, 1487, 2602],
      [1134, 2636, 1270, 2765],
      [130, 2636, 266, 2765],
    ],
  },
  {
    page: 456,
    areas: [
      [1473, 511, 1609, 640],
      [1006, 676, 1142, 805],
      [895, 838, 1031, 967],
      [1333, 1002, 1469, 1131],
      [327, 1003, 463, 1132],
      [387, 1165, 523, 1294],
      [856, 1330, 992, 1459],
      [1481, 1491, 1617, 1620],
      [146, 1492, 282, 1621],
      [670, 1655, 806, 1784],
      [1277, 1819, 1413, 1948],
      [478, 1820, 614, 1949],
      [1009, 1982, 1145, 2111],
      [267, 1981, 403, 2110],
      [896, 2146, 1032, 2275],
      [150, 2146, 286, 2275],
      [150, 2308, 286, 2437],
      [151, 2473, 287, 2602],
      [152, 2635, 288, 2764],
    ],
  },
  {
    page: 457,
    areas: [
      [370, 349, 506, 478],
      [747, 513, 883, 642],
      [1366, 675, 1502, 804],
      [135, 675, 271, 804],
      [381, 839, 517, 968],
      [1341, 1002, 1477, 1131],
      [790, 1002, 926, 1131],
      [1220, 1166, 1356, 1295],
      [376, 1165, 512, 1294],
      [720, 1329, 856, 1458],
      [798, 1492, 934, 1621],
      [1301, 1655, 1437, 1784],
      [251, 1655, 387, 1784],
      [1249, 1983, 1385, 2112],
      [1493, 2146, 1629, 2275],
      [734, 2146, 870, 2275],
      [1345, 2308, 1481, 2437],
      [456, 2308, 592, 2437],
      [1255, 2471, 1391, 2600],
      [466, 2473, 602, 2602],
      [1048, 2637, 1184, 2766],
      [135, 2637, 271, 2766],
    ],
  },
  {
    page: 458,
    areas: [
      [925, 278, 1061, 407],
      [1171, 441, 1307, 570],
      [1466, 605, 1602, 734],
      [836, 607, 972, 736],
      [167, 607, 303, 736],
      [508, 1169, 644, 1298],
      [262, 1331, 398, 1460],
      [1177, 1985, 1313, 2114],
      [169, 2148, 305, 2277],
      [169, 2640, 305, 2769],
    ],
  },
  {
    page: 459,
    areas: [
      [591, 838, 727, 967],
      [162, 1329, 298, 1458],
      [1324, 1982, 1460, 2111],
      [587, 2309, 723, 2438],
      [168, 2636, 304, 2765],
    ],
  },
  {
    page: 460,
    areas: [
      [571, 348, 707, 477],
      [1135, 513, 1271, 642],
      [1463, 675, 1599, 804],
      [752, 674, 888, 803],
      [864, 1002, 1000, 1131],
      [163, 1166, 299, 1295],
      [1210, 1492, 1346, 1621],
      [170, 1656, 306, 1785],
      [167, 1818, 303, 1947],
      [363, 2146, 499, 2275],
      [166, 2636, 302, 2765],
    ],
  },
  {
    page: 461,
    areas: [
      [154, 510, 290, 639],
      [822, 1165, 958, 1294],
      [1474, 1493, 1610, 1622],
      [1193, 1655, 1329, 1784],
      [782, 1819, 918, 1948],
      [457, 1981, 593, 2110],
      [806, 2145, 942, 2274],
      [714, 2472, 850, 2601],
      [1471, 2636, 1607, 2765],
      [157, 2635, 293, 2764],
    ],
  },
  {
    page: 462,
    areas: [
      [346, 513, 482, 642],
      [155, 676, 291, 805],
      [159, 839, 295, 968],
      [612, 1165, 748, 1294],
      [939, 1491, 1075, 1620],
      [766, 1656, 902, 1785],
      [663, 2310, 799, 2439],
      [159, 2473, 295, 2602],
      [159, 2635, 295, 2764],
    ],
  },
  {
    page: 463,
    areas: [
      [1152, 675, 1288, 804],
      [929, 1165, 1065, 1294],
      [169, 1327, 305, 1456],
      [987, 1655, 1123, 1784],
      [802, 1980, 938, 2109],
      [804, 2307, 940, 2436],
      [171, 2635, 307, 2764],
    ],
  },
  {
    page: 464,
    areas: [
      [1159, 511, 1295, 640],
      [1020, 838, 1156, 967],
      [865, 1001, 1001, 1130],
      [983, 1329, 1119, 1458],
      [167, 1490, 303, 1619],
      [1462, 1980, 1598, 2109],
      [772, 2144, 908, 2273],
      [754, 2471, 890, 2600],
      [166, 2636, 302, 2765],
    ],
  },
  {
    page: 465,
    areas: [
      [153, 347, 289, 476],
      [1072, 676, 1208, 805],
      [501, 838, 637, 967],
      [759, 1164, 895, 1293],
      [254, 1329, 390, 1458],
      [397, 1491, 533, 1620],
      [1000, 1819, 1136, 1948],
      [1232, 1983, 1368, 2112],
      [332, 2147, 468, 2276],
      [761, 2310, 897, 2439],
      [156, 2634, 292, 2763],
    ],
  },
  {
    page: 466,
    areas: [
      [1469, 674, 1605, 803],
      [1469, 1001, 1605, 1130],
      [161, 1001, 297, 1130],
      [1466, 1817, 1602, 1946],
      [981, 1981, 1117, 2110],
      [162, 2308, 298, 2437],
      [166, 2634, 302, 2763],
    ],
  },
  {
    page: 467,
    areas: [
      [175, 441, 311, 570],
      [1336, 1005, 1472, 1134],
      [304, 1005, 440, 1134],
      [1121, 1332, 1257, 1461],
      [773, 1496, 909, 1625],
      [1006, 1987, 1142, 2116],
      [633, 2149, 769, 2278],
      [172, 2639, 308, 2768],
    ],
  },
  {
    page: 468,
    areas: [
      [1172, 676, 1308, 805],
      [242, 838, 378, 967],
      [164, 1165, 300, 1294],
      [727, 1493, 863, 1622],
      [1061, 1819, 1197, 1948],
      [169, 1982, 305, 2111],
      [168, 2146, 304, 2275],
      [667, 2472, 803, 2601],
      [171, 2635, 307, 2764],
    ],
  },
  {
    page: 469,
    areas: [
      [949, 512, 1085, 641],
      [1301, 838, 1437, 967],
      [164, 838, 300, 967],
      [594, 1166, 730, 1295],
      [569, 1655, 705, 1784],
      [901, 1981, 1037, 2110],
      [971, 2144, 1107, 2273],
      [772, 2308, 908, 2437],
      [166, 2635, 302, 2764],
    ],
  },
  {
    page: 470,
    areas: [
      [163, 510, 299, 639],
      [844, 838, 980, 967],
      [313, 1491, 449, 1620],
      [838, 1982, 974, 2111],
      [623, 2146, 759, 2275],
      [165, 2309, 301, 2438],
      [595, 2472, 731, 2601],
      [166, 2636, 302, 2765],
    ],
  },
  {
    page: 471,
    areas: [
      [1257, 838, 1393, 967],
      [438, 1166, 574, 1295],
      [365, 1329, 501, 1458],
      [487, 1818, 623, 1947],
      [165, 1982, 301, 2111],
      [1123, 2307, 1259, 2436],
      [163, 2635, 299, 2764],
    ],
  },
  {
    page: 472,
    areas: [
      [1313, 511, 1449, 640],
      [343, 674, 479, 803],
      [1463, 1165, 1599, 1294],
      [710, 1328, 846, 1457],
      [301, 1493, 437, 1622],
      [704, 1819, 840, 1948],
      [1463, 2309, 1599, 2438],
      [860, 2472, 996, 2601],
      [166, 2636, 302, 2765],
    ],
  },
  {
    page: 473,
    areas: [
      [1459, 676, 1595, 805],
      [949, 838, 1085, 967],
      [601, 1001, 737, 1130],
      [340, 1165, 476, 1294],
      [751, 1330, 887, 1459],
      [1078, 1655, 1214, 1784],
      [1208, 2144, 1344, 2273],
      [182, 2309, 318, 2438],
      [178, 2636, 314, 2765],
    ],
  },
  {
    page: 474,
    areas: [
      [1112, 510, 1248, 639],
      [1148, 839, 1284, 968],
      [445, 1165, 581, 1294],
      [1445, 1492, 1581, 1621],
      [1448, 1655, 1584, 1784],
      [1097, 2145, 1233, 2274],
      [349, 2309, 485, 2438],
      [184, 2636, 320, 2765],
    ],
  },
  {
    page: 475,
    areas: [
      [865, 838, 1001, 967],
      [610, 1002, 746, 1131],
      [570, 1165, 706, 1294],
      [1460, 1492, 1596, 1621],
      [167, 1491, 303, 1620],
      [583, 1654, 719, 1783],
      [1048, 1819, 1184, 1948],
      [171, 1982, 307, 2111],
      [1223, 2308, 1359, 2437],
      [984, 2472, 1120, 2601],
      [167, 2636, 303, 2765],
    ],
  },
  {
    page: 476,
    areas: [
      [906, 838, 1042, 967],
      [552, 1002, 688, 1131],
      [945, 1329, 1081, 1458],
      [1116, 1492, 1252, 1621],
      [1468, 1981, 1604, 2110],
      [288, 2144, 424, 2273],
      [1229, 2472, 1365, 2601],
      [158, 2633, 294, 2762],
    ],
  },
  {
    page: 477,
    areas: [
      [1327, 679, 1463, 808],
      [541, 678, 677, 807],
      [627, 842, 763, 971],
      [766, 1005, 902, 1134],
      [987, 1332, 1123, 1461],
      [1190, 1659, 1326, 1788],
      [1190, 1821, 1326, 1950],
      [1057, 1985, 1193, 2114],
      [154, 2148, 290, 2277],
      [875, 2476, 1011, 2605],
      [151, 2639, 287, 2768],
    ],
  },
  {
    page: 478,
    areas: [
      [1280, 676, 1416, 805],
      [1182, 837, 1318, 966],
      [827, 1166, 963, 1295],
      [1460, 1656, 1596, 1785],
      [1180, 1982, 1316, 2111],
      [1463, 2309, 1599, 2438],
      [508, 2310, 644, 2439],
      [689, 2472, 825, 2601],
      [170, 2635, 306, 2764],
    ],
  },
  {
    page: 479,
    areas: [
      [163, 512, 299, 641],
      [1465, 1002, 1601, 1131],
      [1097, 1164, 1233, 1293],
      [656, 1328, 792, 1457],
      [1126, 1818, 1262, 1947],
      [877, 1982, 1013, 2111],
      [428, 2145, 564, 2274],
      [1464, 2472, 1600, 2601],
      [164, 2636, 300, 2765],
    ],
  },
  {
    page: 480,
    areas: [
      [846, 675, 982, 804],
      [838, 1002, 974, 1131],
      [175, 1002, 311, 1131],
      [965, 1328, 1101, 1457],
      [1153, 1655, 1289, 1784],
      [943, 1818, 1079, 1947],
      [489, 1981, 625, 2110],
      [1019, 2472, 1155, 2601],
      [172, 2636, 308, 2765],
    ],
  },
  {
    page: 481,
    areas: [
      [1345, 675, 1481, 804],
      [954, 1002, 1090, 1131],
      [996, 1165, 1132, 1294],
      [768, 1328, 904, 1457],
      [152, 1490, 288, 1619],
      [808, 2145, 944, 2274],
      [539, 2472, 675, 2601],
      [154, 2636, 290, 2765],
    ],
  },
  {
    page: 482,
    areas: [
      [358, 674, 494, 803],
      [160, 838, 296, 967],
      [1276, 1165, 1412, 1294],
      [701, 1655, 837, 1784],
      [1471, 1982, 1607, 2111],
      [458, 2146, 594, 2275],
      [378, 2473, 514, 2602],
      [162, 2636, 298, 2765],
    ],
  },
  {
    page: 483,
    areas: [
      [1335, 678, 1471, 807],
      [1038, 678, 1174, 807],
      [1042, 841, 1178, 970],
      [1137, 1005, 1273, 1134],
      [567, 1331, 703, 1460],
      [1477, 1659, 1613, 1788],
      [1286, 1985, 1422, 2114],
      [156, 2148, 292, 2277],
      [1036, 2475, 1172, 2604],
      [148, 2638, 284, 2767],
    ],
  },
  {
    page: 484,
    areas: [
      [917, 675, 1053, 804],
      [145, 838, 281, 967],
      [248, 1491, 384, 1620],
      [149, 1981, 285, 2110],
      [146, 2635, 282, 2764],
    ],
  },
  {
    page: 485,
    areas: [
      [1467, 675, 1603, 804],
      [969, 839, 1105, 968],
      [155, 1165, 291, 1294],
      [1468, 1493, 1604, 1622],
      [1269, 1820, 1405, 1949],
      [582, 2146, 718, 2275],
      [158, 2636, 294, 2765],
    ],
  },
  {
    page: 486,
    areas: [
      [688, 675, 824, 804],
      [640, 1002, 776, 1131],
      [151, 1165, 287, 1294],
      [764, 1492, 900, 1621],
      [1179, 1819, 1315, 1948],
      [607, 1982, 743, 2111],
      [1085, 2309, 1221, 2438],
      [624, 2472, 760, 2601],
      [148, 2635, 284, 2764],
    ],
  },
  {
    page: 487,
    areas: [
      [635, 348, 771, 477],
      [1477, 675, 1613, 804],
      [463, 675, 599, 804],
      [684, 837, 820, 966],
      [1280, 1165, 1416, 1294],
      [1024, 1328, 1160, 1457],
      [538, 1492, 674, 1621],
      [1072, 1655, 1208, 1784],
      [498, 1820, 634, 1949],
      [610, 1982, 746, 2111],
      [1190, 2309, 1326, 2438],
      [1478, 2472, 1614, 2601],
      [153, 2636, 289, 2765],
    ],
  },
  {
    page: 488,
    areas: [
      [1043, 838, 1179, 967],
      [425, 1001, 561, 1130],
      [489, 1328, 625, 1457],
      [455, 1819, 591, 1948],
      [832, 2146, 968, 2275],
      [466, 2309, 602, 2438],
      [158, 2636, 294, 2765],
    ],
  },
  {
    page: 489,
    areas: [
      [639, 606, 775, 735],
      [161, 770, 297, 899],
      [1314, 1333, 1450, 1462],
      [735, 1332, 871, 1461],
      [916, 1495, 1052, 1624],
      [1097, 1659, 1233, 1788],
      [743, 1822, 879, 1951],
      [1237, 1985, 1373, 2114],
      [1465, 2150, 1601, 2279],
      [1462, 2313, 1598, 2442],
      [865, 2476, 1001, 2605],
      [167, 2640, 303, 2769],
    ],
  },
  {
    page: 490,
    areas: [
      [1002, 512, 1138, 641],
      [648, 676, 784, 805],
      [423, 1003, 559, 1132],
      [1236, 1166, 1372, 1295],
      [1121, 1328, 1257, 1457],
      [1258, 1493, 1394, 1622],
      [631, 1656, 767, 1785],
      [649, 1819, 785, 1948],
      [974, 2146, 1110, 2275],
      [405, 2310, 541, 2439],
      [397, 2474, 533, 2603],
      [152, 2636, 288, 2765],
    ],
  },
  {
    page: 491,
    areas: [
      [155, 510, 291, 639],
      [862, 838, 998, 967],
      [935, 1001, 1071, 1130],
      [937, 1164, 1073, 1293],
      [1466, 1327, 1602, 1456],
      [251, 1329, 387, 1458],
      [153, 1492, 289, 1621],
      [317, 1655, 453, 1784],
      [275, 1819, 411, 1948],
      [320, 2309, 456, 2438],
      [153, 2635, 289, 2764],
    ],
  },
  {
    page: 492,
    areas: [
      [489, 347, 625, 476],
      [1250, 675, 1386, 804],
      [1172, 837, 1308, 966],
      [1117, 1001, 1253, 1130],
      [748, 1165, 884, 1294],
      [524, 1327, 660, 1456],
      [146, 1492, 282, 1621],
      [559, 1655, 695, 1784],
      [727, 1819, 863, 1948],
      [747, 1982, 883, 2111],
      [1082, 2146, 1218, 2275],
      [457, 2308, 593, 2437],
      [1168, 2636, 1304, 2765],
      [149, 2636, 285, 2765],
    ],
  },
  {
    page: 493,
    areas: [
      [862, 511, 998, 640],
      [561, 675, 697, 804],
      [795, 838, 931, 967],
      [981, 1164, 1117, 1293],
      [1125, 1329, 1261, 1458],
      [614, 1492, 750, 1621],
      [585, 1656, 721, 1785],
      [476, 1819, 612, 1948],
      [799, 1982, 935, 2111],
      [628, 2146, 764, 2275],
      [153, 2310, 289, 2439],
      [1479, 2637, 1615, 2766],
      [148, 2636, 284, 2765],
    ],
  },
  {
    page: 494,
    areas: [
      [1248, 511, 1384, 640],
      [1458, 674, 1594, 803],
      [1461, 1002, 1597, 1131],
      [1464, 1165, 1600, 1294],
      [960, 1329, 1096, 1458],
      [609, 1490, 745, 1619],
      [547, 1655, 683, 1784],
      [647, 1818, 783, 1947],
      [946, 1983, 1082, 2112],
      [1191, 2145, 1327, 2274],
      [1154, 2472, 1290, 2601],
      [1172, 2635, 1308, 2764],
      [160, 2635, 296, 2764],
    ],
  },
  {
    page: 495,
    areas: [
      [595, 349, 731, 478],
      [1177, 512, 1313, 641],
      [162, 510, 298, 639],
      [283, 675, 419, 804],
      [461, 838, 597, 967],
      [1181, 1002, 1317, 1131],
      [902, 1165, 1038, 1294],
      [1244, 1329, 1380, 1458],
      [1157, 1492, 1293, 1621],
      [1100, 1655, 1236, 1784],
      [891, 1819, 1027, 1948],
      [1455, 2144, 1591, 2273],
      [796, 2309, 932, 2438],
      [890, 2473, 1026, 2602],
      [1227, 2636, 1363, 2765],
      [164, 2636, 300, 2765],
    ],
  },
  {
    page: 496,
    areas: [
      [1348, 678, 1484, 807],
      [668, 679, 804, 808],
      [896, 842, 1032, 971],
      [160, 842, 296, 971],
      [728, 1005, 864, 1134],
      [1074, 1169, 1210, 1298],
      [978, 1332, 1114, 1461],
      [806, 1496, 942, 1625],
      [1472, 1659, 1608, 1788],
      [356, 1659, 492, 1788],
      [814, 1822, 950, 1951],
      [1211, 1986, 1347, 2115],
      [156, 1985, 292, 2114],
      [715, 2150, 851, 2279],
      [1144, 2313, 1280, 2442],
      [1461, 2475, 1597, 2604],
      [1248, 2639, 1384, 2768],
      [162, 2640, 298, 2769],
    ],
  },
  {
    page: 497,
    areas: [
      [408, 348, 544, 477],
      [951, 512, 1087, 641],
      [275, 512, 411, 641],
      [820, 675, 956, 804],
      [1277, 839, 1413, 968],
      [254, 839, 390, 968],
      [936, 1002, 1072, 1131],
      [314, 1003, 450, 1132],
      [1039, 1165, 1175, 1294],
      [145, 1165, 281, 1294],
      [265, 1328, 401, 1457],
      [566, 1491, 702, 1620],
      [902, 1654, 1038, 1783],
      [1253, 1818, 1389, 1947],
      [1479, 1983, 1615, 2112],
      [858, 1981, 994, 2110],
      [1142, 2145, 1278, 2274],
      [259, 2146, 395, 2275],
      [1480, 2472, 1616, 2601],
      [145, 2473, 281, 2602],
      [140, 2636, 276, 2765],
    ],
  },
  {
    page: 498,
    areas: [
      [556, 347, 692, 476],
      [600, 511, 736, 640],
      [871, 675, 1007, 804],
      [175, 675, 311, 804],
      [1093, 837, 1229, 966],
      [322, 838, 458, 967],
      [1201, 1001, 1337, 1130],
      [249, 1001, 385, 1130],
      [464, 1164, 600, 1293],
      [849, 1329, 985, 1458],
      [1452, 1492, 1588, 1621],
      [681, 1491, 817, 1620],
      [1454, 1655, 1590, 1784],
      [174, 1655, 310, 1784],
      [630, 1819, 766, 1948],
      [935, 1982, 1071, 2111],
      [328, 2146, 464, 2275],
      [649, 2308, 785, 2437],
      [911, 2471, 1047, 2600],
      [177, 2471, 313, 2600],
    ],
  },
  {
    page: 499,
    areas: [
      [1362, 512, 1498, 641],
      [457, 512, 593, 641],
      [897, 675, 1033, 804],
      [1180, 838, 1316, 967],
      [1297, 1165, 1433, 1294],
      [992, 1328, 1128, 1457],
      [447, 1329, 583, 1458],
      [1459, 1655, 1595, 1784],
      [1315, 1819, 1451, 1948],
      [293, 1983, 429, 2112],
      [165, 2145, 301, 2274],
      [903, 2473, 1039, 2602],
      [166, 2636, 302, 2765],
    ],
  },
  {
    page: 500,
    areas: [
      [897, 510, 1033, 639],
      [451, 675, 587, 804],
      [878, 1002, 1014, 1131],
      [1473, 1491, 1609, 1620],
      [881, 1655, 1017, 1784],
      [1468, 1982, 1604, 2111],
      [1470, 2146, 1606, 2275],
      [1086, 2473, 1222, 2602],
      [151, 2636, 287, 2765],
    ],
  },
  {
    page: 501,
    areas: [
      [1270, 675, 1406, 804],
      [374, 838, 510, 967],
      [1118, 1165, 1254, 1294],
      [396, 1328, 532, 1457],
      [1463, 1655, 1599, 1784],
      [1286, 1819, 1422, 1948],
      [1084, 1982, 1220, 2111],
      [296, 2146, 432, 2275],
      [1281, 2472, 1417, 2601],
      [159, 2635, 295, 2764],
    ],
  },
  {
    page: 502,
    areas: [
      [165, 279, 301, 408],
      [1111, 605, 1247, 734],
      [166, 769, 302, 898],
      [254, 931, 390, 1060],
      [177, 1095, 313, 1224],
      [1345, 1659, 1481, 1788],
      [361, 1659, 497, 1788],
      [766, 1986, 902, 2115],
      [1161, 2476, 1297, 2605],
      [183, 2639, 319, 2768],
    ],
  },
  {
    page: 503,
    areas: [
      [279, 348, 415, 477],
      [1216, 675, 1352, 804],
      [811, 1002, 947, 1131],
      [1144, 1328, 1280, 1457],
      [688, 1656, 824, 1785],
      [754, 1982, 890, 2111],
      [638, 2309, 774, 2438],
      [163, 2472, 299, 2601],
      [164, 2635, 300, 2764],
    ],
  },
  {
    page: 504,
    areas: [
      [492, 1002, 628, 1131],
      [502, 1328, 638, 1457],
      [837, 1819, 973, 1948],
      [1267, 2145, 1403, 2274],
      [1236, 2310, 1372, 2439],
      [153, 2636, 289, 2765],
    ],
  },
  {
    page: 505,
    areas: [
      [1036, 675, 1172, 804],
      [670, 838, 806, 967],
      [165, 1002, 301, 1131],
      [405, 1328, 541, 1457],
      [1074, 1656, 1210, 1785],
      [327, 2145, 463, 2274],
      [1460, 2472, 1596, 2601],
      [177, 2635, 313, 2764],
    ],
  },
  {
    page: 506,
    areas: [
      [1458, 675, 1594, 804],
      [1459, 1002, 1595, 1131],
      [709, 1165, 845, 1294],
      [1117, 1493, 1253, 1622],
      [951, 1819, 1087, 1948],
      [1104, 2146, 1240, 2275],
      [173, 2473, 309, 2602],
    ],
  },
  {
    page: 507,
    areas: [
      [372, 511, 508, 640],
      [646, 839, 782, 968],
      [1069, 1165, 1205, 1294],
      [378, 1655, 514, 1784],
      [1186, 1818, 1322, 1947],
      [453, 1819, 589, 1948],
      [444, 1982, 580, 2111],
      [858, 2145, 994, 2274],
      [1089, 2309, 1225, 2438],
      [148, 2472, 284, 2601],
      [139, 2635, 275, 2764],
    ],
  },
  {
    page: 508,
    areas: [
      [1084, 675, 1220, 804],
      [571, 839, 707, 968],
      [382, 1001, 518, 1130],
      [702, 1655, 838, 1784],
      [297, 1983, 433, 2112],
      [554, 2145, 690, 2274],
      [1260, 2473, 1396, 2602],
      [154, 2636, 290, 2765],
    ],
  },
  {
    page: 509,
    areas: [
      [1464, 839, 1600, 968],
      [1083, 999, 1219, 1128],
      [734, 1165, 870, 1294],
      [774, 1328, 910, 1457],
      [924, 1492, 1060, 1621],
      [1330, 1819, 1466, 1948],
      [1465, 2145, 1601, 2274],
      [1177, 2309, 1313, 2438],
      [428, 2473, 564, 2602],
      [157, 2635, 293, 2764],
    ],
  },
  {
    page: 510,
    areas: [
      [691, 512, 827, 641],
      [420, 675, 556, 804],
      [163, 1002, 299, 1131],
      [1236, 1329, 1372, 1458],
      [811, 1492, 947, 1621],
      [317, 1655, 453, 1784],
      [910, 1982, 1046, 2111],
      [800, 2144, 936, 2273],
      [171, 2636, 307, 2765],
    ],
  },
  {
    page: 511,
    areas: [
      [960, 678, 1096, 807],
      [166, 841, 302, 970],
      [915, 1006, 1051, 1135],
      [762, 1332, 898, 1461],
      [470, 1658, 606, 1787],
      [429, 2148, 565, 2277],
      [492, 2312, 628, 2441],
      [782, 2475, 918, 2604],
      [166, 2638, 302, 2767],
    ],
  },
  {
    page: 512,
    areas: [
      [807, 675, 943, 804],
      [1310, 1328, 1446, 1457],
      [959, 1656, 1095, 1785],
      [860, 1819, 996, 1948],
      [1273, 2146, 1409, 2275],
      [165, 2635, 301, 2764],
    ],
  },
  {
    page: 513,
    areas: [
      [288, 675, 424, 804],
      [776, 1164, 912, 1293],
      [402, 1491, 538, 1620],
      [471, 1655, 607, 1784],
      [1209, 2145, 1345, 2274],
      [706, 2309, 842, 2438],
      [367, 2472, 503, 2601],
      [171, 2635, 307, 2764],
    ],
  },
  {
    page: 514,
    areas: [
      [287, 512, 423, 641],
      [782, 1328, 918, 1457],
      [172, 1818, 308, 1947],
      [1142, 2470, 1278, 2599],
      [176, 2636, 312, 2765],
    ],
  },
  {
    page: 515,
    areas: [
      [162, 1095, 298, 1224],
      [1082, 1823, 1218, 1952],
      [398, 2149, 534, 2278],
      [438, 2475, 574, 2604],
      [159, 2638, 295, 2767],
    ],
  },
  {
    page: 516,
    areas: [
      [1305, 512, 1441, 641],
      [158, 674, 294, 803],
      [157, 1164, 293, 1293],
      [498, 1329, 634, 1458],
      [1471, 1981, 1607, 2110],
      [1118, 2144, 1254, 2273],
      [159, 2636, 295, 2765],
    ],
  },
  {
    page: 517,
    areas: [
      [1342, 838, 1478, 967],
      [1230, 1165, 1366, 1294],
      [169, 1492, 305, 1621],
      [1067, 1982, 1203, 2111],
      [1464, 2309, 1600, 2438],
      [329, 2472, 465, 2601],
      [167, 2635, 303, 2764],
    ],
  },
  {
    page: 518,
    areas: [
      [937, 678, 1073, 807],
      [720, 841, 856, 970],
      [1226, 1006, 1362, 1135],
      [1282, 1168, 1418, 1297],
      [1477, 1332, 1613, 1461],
      [1071, 1495, 1207, 1624],
      [786, 1659, 922, 1788],
      [1323, 1821, 1459, 1950],
      [1116, 1985, 1252, 2114],
      [152, 1985, 288, 2114],
      [321, 2149, 457, 2278],
      [631, 2312, 767, 2441],
      [1357, 2475, 1493, 2604],
      [1477, 2639, 1613, 2768],
      [150, 2639, 286, 2768],
    ],
  },
  {
    page: 519,
    areas: [
      [1119, 512, 1255, 641],
      [1477, 675, 1613, 804],
      [527, 674, 663, 803],
      [649, 838, 785, 967],
      [1221, 1002, 1357, 1131],
      [285, 1001, 421, 1130],
      [1480, 1329, 1616, 1458],
      [694, 1328, 830, 1457],
      [1360, 1490, 1496, 1619],
      [688, 1491, 824, 1620],
      [798, 1655, 934, 1784],
      [902, 1819, 1038, 1948],
      [1161, 1983, 1297, 2112],
      [144, 1982, 280, 2111],
      [372, 2145, 508, 2274],
      [942, 2308, 1078, 2437],
      [1483, 2472, 1619, 2601],
      [378, 2472, 514, 2601],
      [984, 2635, 1120, 2764],
      [149, 2636, 285, 2765],
    ],
  },
  {
    page: 520,
    areas: [
      [907, 441, 1043, 570],
      [553, 605, 689, 734],
      [1232, 932, 1368, 1061],
      [607, 1095, 743, 1224],
      [1105, 1259, 1241, 1388],
      [1491, 1422, 1627, 1551],
      [254, 1422, 390, 1551],
      [666, 1584, 802, 1713],
      [599, 1749, 735, 1878],
      [136, 1912, 272, 2041],
      [1119, 2475, 1255, 2604],
      [615, 2476, 751, 2605],
      [133, 2476, 269, 2605],
      [1123, 2639, 1259, 2768],
      [570, 2639, 706, 2768],
      [132, 2639, 268, 2768],
    ],
  },
  {
    page: 521,
    areas: [
      [451, 348, 587, 477],
      [1055, 348, 1191, 477],
      [1384, 512, 1520, 641],
      [917, 512, 1053, 641],
      [140, 512, 276, 641],
      [987, 675, 1123, 804],
      [306, 675, 442, 804],
      [623, 838, 759, 967],
      [1319, 1001, 1455, 1130],
      [1494, 1164, 1630, 1293],
      [680, 1164, 816, 1293],
      [1490, 1328, 1626, 1457],
      [545, 1328, 681, 1457],
      [1279, 1491, 1415, 1620],
      [529, 1492, 665, 1621],
      [1194, 1656, 1330, 1785],
      [1298, 1818, 1434, 1947],
      [134, 1818, 270, 1947],
      [349, 1982, 485, 2111],
      [895, 2146, 1031, 2275],
      [1491, 2310, 1627, 2439],
      [1493, 2472, 1629, 2601],
      [1490, 2635, 1626, 2764],
      [135, 2636, 271, 2765],
    ],
  },
  {
    page: 522,
    areas: [
      [689, 347, 825, 476],
      [1317, 511, 1453, 640],
      [532, 510, 668, 639],
      [1265, 674, 1401, 803],
      [365, 674, 501, 803],
      [873, 837, 1009, 966],
      [1133, 1002, 1269, 1131],
      [1373, 1165, 1509, 1294],
      [511, 1164, 647, 1293],
      [865, 1329, 1001, 1458],
      [1328, 1491, 1464, 1620],
      [145, 1491, 281, 1620],
      [629, 1654, 765, 1783],
      [689, 1818, 825, 1947],
      [1023, 1981, 1159, 2110],
      [1284, 2144, 1420, 2273],
      [345, 2145, 481, 2274],
      [864, 2308, 1000, 2437],
      [1119, 2471, 1255, 2600],
      [146, 2471, 282, 2600],
      [146, 2635, 282, 2764],
    ],
  },
  {
    page: 523,
    areas: [
      [1483, 441, 1619, 570],
      [635, 442, 771, 571],
      [1309, 604, 1445, 733],
      [262, 606, 398, 735],
      [629, 768, 765, 897],
      [967, 931, 1103, 1060],
      [1483, 1095, 1619, 1224],
      [1482, 1259, 1618, 1388],
      [144, 1258, 280, 1387],
      [1299, 1822, 1435, 1951],
      [787, 1822, 923, 1951],
      [346, 1821, 482, 1950],
      [1275, 1984, 1411, 2113],
      [735, 1985, 871, 2114],
      [221, 1985, 357, 2114],
      [1048, 2149, 1184, 2278],
      [565, 2148, 701, 2277],
      [1361, 2311, 1497, 2440],
      [708, 2312, 844, 2441],
      [1484, 2476, 1620, 2605],
      [668, 2475, 804, 2604],
      [1183, 2639, 1319, 2768],
      [143, 2639, 279, 2768],
    ],
  },
  {
    page: 524,
    areas: [
      [606, 348, 742, 477],
      [161, 511, 297, 640],
      [778, 674, 914, 803],
      [726, 838, 862, 967],
      [1147, 1001, 1283, 1130],
      [1224, 1163, 1360, 1292],
      [1338, 1494, 1474, 1623],
      [343, 1491, 479, 1620],
      [749, 1655, 885, 1784],
      [899, 1819, 1035, 1948],
      [1463, 1981, 1599, 2110],
      [452, 1980, 588, 2109],
      [716, 2144, 852, 2273],
      [799, 2309, 935, 2438],
      [908, 2472, 1044, 2601],
      [1283, 2635, 1419, 2764],
      [164, 2635, 300, 2764],
    ],
  },
  {
    page: 525,
    areas: [
      [535, 348, 671, 477],
      [1157, 511, 1293, 640],
      [1478, 675, 1614, 804],
      [397, 675, 533, 804],
      [642, 838, 778, 967],
      [893, 1001, 1029, 1130],
      [893, 1164, 1029, 1293],
      [149, 1164, 285, 1293],
      [637, 1328, 773, 1457],
      [1283, 1491, 1419, 1620],
      [150, 1491, 286, 1620],
      [533, 1654, 669, 1783],
      [624, 1818, 760, 1947],
      [855, 1982, 991, 2111],
      [1107, 2144, 1243, 2273],
      [1086, 2309, 1222, 2438],
      [1024, 2472, 1160, 2601],
      [151, 2472, 287, 2601],
    ],
  },
  {
    page: 526,
    areas: [
      [1115, 510, 1251, 639],
      [368, 512, 504, 641],
      [1274, 674, 1410, 803],
      [673, 674, 809, 803],
      [127, 675, 263, 804],
      [1126, 838, 1262, 967],
      [559, 837, 695, 966],
      [126, 837, 262, 966],
      [905, 1000, 1041, 1129],
      [129, 1001, 265, 1130],
      [983, 1165, 1119, 1294],
      [368, 1164, 504, 1293],
      [1249, 1327, 1385, 1456],
      [679, 1328, 815, 1457],
      [127, 1327, 263, 1456],
      [943, 1491, 1079, 1620],
      [323, 1490, 459, 1619],
      [967, 1655, 1103, 1784],
      [298, 1655, 434, 1784],
      [1109, 1818, 1245, 1947],
      [442, 1817, 578, 1946],
      [1319, 1982, 1455, 2111],
      [804, 2308, 940, 2437],
      [245, 2308, 381, 2437],
      [1107, 2472, 1243, 2601],
      [127, 2635, 263, 2764],
    ],
  },
  {
    page: 527,
    areas: [
      [133, 347, 269, 476],
      [1259, 674, 1395, 803],
      [1339, 837, 1475, 966],
      [759, 1001, 895, 1130],
      [1282, 1328, 1418, 1457],
      [1260, 1818, 1396, 1947],
      [649, 1818, 785, 1947],
      [1496, 1982, 1632, 2111],
      [715, 1982, 851, 2111],
      [1323, 2144, 1459, 2273],
      [673, 2144, 809, 2273],
      [1495, 2309, 1631, 2438],
      [612, 2308, 748, 2437],
      [1380, 2472, 1516, 2601],
      [650, 2472, 786, 2601],
      [1489, 2635, 1625, 2764],
      [774, 2635, 910, 2764],
      [138, 2635, 274, 2764],
    ],
  },
  {
    page: 528,
    areas: [
      [735, 279, 871, 408],
      [246, 279, 382, 408],
      [1044, 441, 1180, 570],
      [492, 442, 628, 571],
      [1297, 606, 1433, 735],
      [604, 606, 740, 735],
      [143, 606, 279, 735],
      [438, 769, 574, 898],
      [1339, 931, 1475, 1060],
      [830, 931, 966, 1060],
      [142, 931, 278, 1060],
      [880, 1096, 1016, 1225],
      [456, 1095, 592, 1224],
      [1120, 1258, 1256, 1387],
      [410, 1258, 546, 1387],
      [1256, 1423, 1392, 1552],
      [808, 1422, 944, 1551],
      [145, 1422, 281, 1551],
      [697, 1983, 833, 2112],
      [917, 2147, 1053, 2276],
      [867, 2310, 1003, 2439],
      [1015, 2475, 1151, 2604],
      [1485, 2639, 1621, 2768],
      [143, 2639, 279, 2768],
    ],
  },
  {
    page: 529,
    areas: [
      [149, 347, 285, 476],
      [383, 512, 519, 641],
      [290, 675, 426, 804],
      [913, 838, 1049, 967],
      [1479, 1003, 1615, 1132],
      [147, 1002, 283, 1131],
      [791, 1165, 927, 1294],
      [1373, 1328, 1509, 1457],
      [462, 1327, 598, 1456],
      [1193, 1492, 1329, 1621],
      [1479, 1654, 1615, 1783],
      [511, 1655, 647, 1784],
      [720, 1819, 856, 1948],
      [1219, 1982, 1355, 2111],
      [550, 1982, 686, 2111],
      [1010, 2145, 1146, 2274],
      [446, 2144, 582, 2273],
      [537, 2308, 673, 2437],
      [846, 2472, 982, 2601],
      [1334, 2635, 1470, 2764],
      [147, 2635, 283, 2764],
    ],
  },
  {
    page: 530,
    areas: [
      [485, 347, 621, 476],
      [1165, 512, 1301, 641],
      [518, 512, 654, 641],
      [593, 675, 729, 804],
      [1003, 838, 1139, 967],
      [370, 837, 506, 966],
      [555, 1000, 691, 1129],
      [930, 1165, 1066, 1294],
      [1305, 1328, 1441, 1457],
      [1185, 1491, 1321, 1620],
      [157, 1491, 293, 1620],
      [1015, 1655, 1151, 1784],
      [1472, 1819, 1608, 1948],
      [732, 1819, 868, 1948],
      [1057, 1982, 1193, 2111],
      [1282, 2145, 1418, 2274],
      [507, 2144, 643, 2273],
      [1134, 2308, 1270, 2437],
      [1476, 2472, 1612, 2601],
      [641, 2472, 777, 2601],
      [807, 2635, 943, 2764],
      [156, 2635, 292, 2764],
    ],
  },
  {
    page: 531,
    areas: [
      [577, 278, 713, 407],
      [661, 444, 797, 573],
      [1231, 606, 1367, 735],
      [418, 606, 554, 735],
      [1126, 767, 1262, 896],
      [160, 769, 296, 898],
      [1252, 1333, 1388, 1462],
      [765, 1334, 901, 1463],
      [167, 1332, 303, 1461],
      [1135, 1495, 1271, 1624],
      [364, 1495, 500, 1624],
      [1012, 1657, 1148, 1786],
      [1469, 1822, 1605, 1951],
      [803, 1822, 939, 1951],
      [960, 1985, 1096, 2114],
      [165, 1986, 301, 2115],
      [627, 2147, 763, 2276],
      [1185, 2311, 1321, 2440],
      [359, 2311, 495, 2440],
      [570, 2475, 706, 2604],
      [1002, 2638, 1138, 2767],
      [167, 2637, 303, 2766],
    ],
  },
  {
    page: 532,
    areas: [
      [882, 348, 1018, 477],
      [150, 347, 286, 476],
      [1014, 511, 1150, 640],
      [373, 511, 509, 640],
      [1131, 676, 1267, 805],
      [276, 676, 412, 805],
      [986, 839, 1122, 968],
      [1488, 1001, 1624, 1130],
      [757, 1002, 893, 1131],
      [285, 1001, 421, 1130],
      [761, 1166, 897, 1295],
      [1481, 1328, 1617, 1457],
      [260, 1328, 396, 1457],
      [989, 1491, 1125, 1620],
      [272, 1491, 408, 1620],
      [964, 1655, 1100, 1784],
      [1209, 1982, 1345, 2111],
      [456, 1982, 592, 2111],
      [625, 2144, 761, 2273],
      [1258, 2310, 1394, 2439],
      [1480, 2473, 1616, 2602],
      [702, 2473, 838, 2602],
      [1104, 2636, 1240, 2765],
      [148, 2635, 284, 2764],
    ],
  },
  {
    page: 533,
    areas: [
      [264, 347, 400, 476],
      [998, 511, 1134, 640],
      [1487, 675, 1623, 804],
      [704, 674, 840, 803],
      [1481, 839, 1617, 968],
      [713, 838, 849, 967],
      [1486, 1002, 1622, 1131],
      [1086, 1001, 1222, 1130],
      [387, 1001, 523, 1130],
      [1331, 1165, 1467, 1294],
      [599, 1165, 735, 1294],
      [1309, 1327, 1445, 1456],
      [582, 1329, 718, 1458],
      [558, 1492, 694, 1621],
      [1284, 1656, 1420, 1785],
      [1271, 1819, 1407, 1948],
      [513, 1819, 649, 1948],
      [1234, 1981, 1370, 2110],
      [448, 1982, 584, 2111],
      [871, 2145, 1007, 2274],
      [1486, 2309, 1622, 2438],
      [846, 2309, 982, 2438],
      [1483, 2473, 1619, 2602],
      [1080, 2473, 1216, 2602],
      [294, 2472, 430, 2601],
      [1044, 2636, 1180, 2765],
      [140, 2636, 276, 2765],
    ],
  },
  {
    page: 534,
    areas: [
      [914, 278, 1050, 407],
      [147, 280, 283, 409],
      [1026, 443, 1162, 572],
      [268, 442, 404, 571],
      [960, 605, 1096, 734],
      [146, 605, 282, 734],
      [739, 769, 875, 898],
      [1480, 932, 1616, 1061],
      [283, 931, 419, 1060],
      [1021, 1096, 1157, 1225],
      [144, 1095, 280, 1224],
      [1057, 1659, 1193, 1788],
      [489, 1658, 625, 1787],
      [1482, 1821, 1618, 1950],
      [823, 1822, 959, 1951],
      [143, 1821, 279, 1950],
      [1029, 1985, 1165, 2114],
      [421, 1985, 557, 2114],
      [810, 2148, 946, 2277],
      [1278, 2311, 1414, 2440],
      [673, 2312, 809, 2441],
      [147, 2311, 283, 2440],
      [1081, 2475, 1217, 2604],
      [557, 2476, 693, 2605],
      [1482, 2638, 1618, 2767],
      [941, 2638, 1077, 2767],
      [144, 2639, 280, 2768],
    ],
  },
  {
    page: 535,
    areas: [
      [851, 348, 987, 477],
      [1490, 510, 1626, 639],
      [706, 511, 842, 640],
      [1490, 674, 1626, 803],
      [853, 674, 989, 803],
      [466, 675, 602, 804],
      [1285, 838, 1421, 967],
      [641, 838, 777, 967],
      [1353, 1001, 1489, 1130],
      [758, 1001, 894, 1130],
      [1328, 1165, 1464, 1294],
      [842, 1165, 978, 1294],
      [393, 1163, 529, 1292],
      [1492, 1327, 1628, 1456],
      [993, 1327, 1129, 1456],
      [507, 1328, 643, 1457],
      [1321, 1490, 1457, 1619],
      [874, 1491, 1010, 1620],
      [356, 1492, 492, 1621],
      [1340, 1655, 1476, 1784],
      [967, 1656, 1103, 1785],
      [383, 1655, 519, 1784],
      [1299, 1819, 1435, 1948],
      [742, 1819, 878, 1948],
      [1330, 1981, 1466, 2110],
      [802, 1982, 938, 2111],
      [304, 1983, 440, 2112],
      [1276, 2145, 1412, 2274],
      [425, 2145, 561, 2274],
      [1050, 2309, 1186, 2438],
      [911, 2471, 1047, 2600],
      [305, 2472, 441, 2601],
      [1025, 2635, 1161, 2764],
      [134, 2635, 270, 2764],
    ],
  },
  {
    page: 536,
    areas: [
      [787, 347, 923, 476],
      [159, 348, 295, 477],
      [1033, 510, 1169, 639],
      [386, 512, 522, 641],
      [1205, 675, 1341, 804],
      [634, 674, 770, 803],
      [1241, 837, 1377, 966],
      [729, 837, 865, 966],
      [1252, 1002, 1388, 1131],
      [165, 1002, 301, 1131],
      [307, 1165, 443, 1294],
      [631, 1328, 767, 1457],
      [1462, 1491, 1598, 1620],
      [579, 1492, 715, 1621],
      [891, 1654, 1027, 1783],
      [492, 1654, 628, 1783],
      [1464, 1818, 1600, 1947],
      [699, 1818, 835, 1947],
      [1100, 1982, 1236, 2111],
      [1457, 2144, 1593, 2273],
      [739, 2144, 875, 2273],
      [1024, 2308, 1160, 2437],
      [1459, 2472, 1595, 2601],
      [620, 2472, 756, 2601],
      [1081, 2635, 1217, 2764],
      [163, 2636, 299, 2765],
    ],
  },
  {
    page: 537,
    areas: [
      [1097, 277, 1233, 406],
      [510, 278, 646, 407],
      [1194, 442, 1330, 571],
      [506, 442, 642, 571],
      [1165, 605, 1301, 734],
      [289, 606, 425, 735],
      [1076, 768, 1212, 897],
      [416, 769, 552, 898],
      [765, 932, 901, 1061],
      [1476, 1096, 1612, 1225],
      [731, 1095, 867, 1224],
      [1475, 1259, 1611, 1388],
      [689, 1260, 825, 1389],
      [1312, 1422, 1448, 1551],
      [502, 1422, 638, 1551],
      [994, 1586, 1130, 1715],
      [500, 1585, 636, 1714],
      [1475, 1749, 1611, 1878],
      [791, 1749, 927, 1878],
      [152, 1750, 288, 1879],
      [323, 2313, 459, 2442],
      [151, 2476, 287, 2605],
      [151, 2638, 287, 2767],
    ],
  },
  {
    page: 538,
    areas: [
      [1301, 838, 1437, 967],
      [1453, 1003, 1589, 1132],
      [1198, 1166, 1334, 1295],
      [170, 1329, 306, 1458],
      [803, 1657, 939, 1786],
      [1140, 1981, 1276, 2110],
      [359, 2473, 495, 2602],
      [174, 2636, 310, 2765],
    ],
  },
  {
    page: 539,
    areas: [
      [1093, 675, 1229, 804],
      [1255, 1166, 1391, 1295],
      [887, 1490, 1023, 1619],
      [1445, 1819, 1581, 1948],
      [181, 2145, 317, 2274],
      [1068, 2473, 1204, 2602],
      [180, 2636, 316, 2765],
    ],
  },
  {
    page: 540,
    areas: [
      [633, 674, 769, 803],
      [165, 1328, 301, 1457],
      [371, 1819, 507, 1948],
      [370, 2145, 506, 2274],
      [814, 2473, 950, 2602],
      [170, 2637, 306, 2766],
    ],
  },
  {
    page: 541,
    areas: [
      [809, 839, 945, 968],
      [783, 1166, 919, 1295],
      [940, 1982, 1076, 2111],
      [390, 2309, 526, 2438],
      [173, 2635, 309, 2764],
    ],
  },
  {
    page: 542,
    areas: [
      [554, 843, 690, 972],
      [1081, 1331, 1217, 1460],
      [833, 1659, 969, 1788],
      [926, 2150, 1062, 2279],
      [1107, 2476, 1243, 2605],
      [175, 2640, 311, 2769],
    ],
  },
  {
    page: 543,
    areas: [
      [489, 838, 625, 967],
      [732, 1491, 868, 1620],
      [455, 1818, 591, 1947],
      [470, 2144, 606, 2273],
      [167, 2636, 303, 2765],
    ],
  },
  {
    page: 544,
    areas: [
      [1466, 676, 1602, 805],
      [757, 1001, 893, 1130],
      [1166, 1328, 1302, 1457],
      [1283, 1492, 1419, 1621],
      [1150, 1656, 1286, 1785],
      [425, 1818, 561, 1947],
      [1032, 2145, 1168, 2274],
      [1464, 2472, 1600, 2601],
      [163, 2472, 299, 2601],
      [164, 2636, 300, 2765],
    ],
  },
  {
    page: 545,
    areas: [
      [179, 1095, 315, 1224],
      [1458, 1822, 1594, 1951],
      [828, 2475, 964, 2604],
      [173, 2640, 309, 2769],
    ],
  },
  {
    page: 546,
    areas: [
      [1221, 511, 1357, 640],
      [429, 675, 565, 804],
      [1335, 1166, 1471, 1295],
      [178, 1656, 314, 1785],
      [1117, 2145, 1253, 2274],
      [179, 2636, 315, 2765],
    ],
  },
  {
    page: 547,
    areas: [
      [471, 675, 607, 804],
      [1451, 1328, 1587, 1457],
      [174, 1492, 310, 1621],
      [1068, 1819, 1204, 1948],
      [169, 2146, 305, 2275],
      [1357, 2473, 1493, 2602],
      [164, 2636, 300, 2765],
    ],
  },
  {
    page: 548,
    areas: [
      [1183, 513, 1319, 642],
      [1451, 840, 1587, 969],
      [987, 1002, 1123, 1131],
      [454, 1166, 590, 1295],
      [1456, 1656, 1592, 1785],
      [923, 1819, 1059, 1948],
      [1456, 2309, 1592, 2438],
      [174, 2473, 310, 2602],
    ],
  },
  {
    page: 549,
    areas: [
      [234, 1166, 370, 1295],
      [872, 1492, 1008, 1621],
      [272, 1656, 408, 1785],
      [444, 2473, 580, 2602],
      [163, 2637, 299, 2766],
    ],
  },
  {
    page: 550,
    areas: [
      [695, 511, 831, 640],
      [1449, 838, 1585, 967],
      [1449, 1165, 1585, 1294],
      [1150, 1493, 1286, 1622],
      [422, 2309, 558, 2438],
      [175, 2636, 311, 2765],
    ],
  },
  {
    page: 551,
    areas: [
      [1457, 932, 1593, 1061],
      [170, 1095, 306, 1224],
      [1456, 1823, 1592, 1952],
      [170, 1823, 306, 1952],
      [250, 1986, 386, 2115],
      [1058, 2313, 1194, 2442],
      [174, 2640, 310, 2769],
    ],
  },
  {
    page: 552,
    areas: [
      [674, 675, 810, 804],
      [1461, 1002, 1597, 1131],
      [1258, 1165, 1394, 1294],
      [769, 1329, 905, 1458],
      [803, 1491, 939, 1620],
      [163, 1654, 299, 1783],
      [616, 1983, 752, 2112],
      [691, 2147, 827, 2276],
      [151, 2635, 301, 2781],
    ],
  },
  {
    page: 553,
    areas: [
      [1274, 842, 1410, 971],
      [897, 1170, 1033, 1299],
      [1024, 1332, 1160, 1461],
      [975, 1495, 1111, 1624],
      [179, 1822, 315, 1951],
      [455, 2149, 591, 2278],
      [325, 2312, 461, 2441],
      [179, 2640, 315, 2769],
    ],
  },
  {
    page: 554,
    areas: [
      [1237, 605, 1373, 734],
      [1451, 931, 1587, 1060],
      [174, 1094, 310, 1223],
      [173, 1820, 309, 1949],
      [1248, 2147, 1384, 2276],
      [1067, 2310, 1203, 2439],
      [167, 2636, 303, 2765],
    ],
  },
  {
    page: 555,
    areas: [
      [569, 511, 705, 640],
      [596, 838, 732, 967],
      [1448, 1328, 1584, 1457],
      [852, 1654, 988, 1783],
      [684, 1980, 820, 2109],
      [277, 2307, 413, 2436],
      [179, 2470, 315, 2599],
    ],
  },
  {
    page: 556,
    areas: [
      [957, 675, 1093, 804],
      [580, 838, 716, 967],
      [173, 1001, 309, 1130],
      [978, 1328, 1114, 1457],
      [683, 1493, 819, 1622],
      [1014, 1819, 1150, 1948],
      [425, 1983, 561, 2112],
      [266, 2145, 402, 2274],
      [170, 2637, 306, 2766],
    ],
  },
  {
    page: 557,
    areas: [
      [563, 512, 699, 641],
      [1145, 838, 1281, 967],
      [444, 1002, 580, 1131],
      [353, 1166, 489, 1295],
      [811, 1656, 947, 1785],
      [714, 1820, 850, 1949],
      [437, 2146, 573, 2275],
      [1256, 2473, 1392, 2602],
      [188, 2472, 324, 2601],
    ],
  },
  {
    page: 558,
    areas: [
      [831, 1164, 967, 1293],
      [329, 1654, 465, 1783],
      [474, 1980, 610, 2109],
      [601, 2470, 737, 2599],
      [159, 2632, 295, 2761],
    ],
  },
  {
    page: 559,
    areas: [
      [796, 837, 932, 966],
      [518, 1164, 654, 1293],
      [1232, 1491, 1368, 1620],
      [150, 1490, 286, 1619],
      [968, 1817, 1104, 1946],
      [480, 2307, 616, 2436],
      [164, 2633, 300, 2762],
    ],
  },
  {
    page: 560,
    areas: [
      [1200, 843, 1336, 972],
      [1069, 1004, 1205, 1133],
      [1458, 1496, 1594, 1625],
      [1062, 1823, 1198, 1952],
      [1116, 2150, 1252, 2279],
      [359, 2476, 495, 2605],
      [166, 2641, 302, 2770],
    ],
  },
  {
    page: 561,
    areas: [
      [169, 1003, 305, 1132],
      [634, 1330, 770, 1459],
      [171, 1819, 307, 1948],
      [447, 2309, 583, 2438],
      [168, 2636, 304, 2765],
    ],
  },
  {
    page: 562,
    areas: [
      [409, 678, 545, 807],
      [164, 842, 300, 971],
      [585, 1169, 721, 1298],
      [587, 1332, 723, 1461],
      [1286, 1660, 1422, 1789],
      [1466, 1822, 1602, 1951],
      [453, 1823, 589, 1952],
      [163, 1986, 299, 2115],
      [1075, 2313, 1211, 2442],
      [1293, 2476, 1429, 2605],
      [166, 2476, 302, 2605],
      [164, 2639, 300, 2768],
    ],
  },
  {
    page: 563,
    areas: [
      [265, 348, 401, 477],
      [704, 511, 840, 640],
      [1453, 839, 1589, 968],
      [1322, 1002, 1458, 1131],
      [958, 1165, 1094, 1294],
      [1247, 1328, 1383, 1457],
      [475, 1492, 611, 1621],
      [1452, 1819, 1588, 1948],
      [1313, 1983, 1449, 2112],
      [1056, 2146, 1192, 2275],
      [588, 2310, 724, 2439],
      [899, 2473, 1035, 2602],
      [1253, 2636, 1389, 2765],
      [169, 2637, 305, 2766],
    ],
  },
  {
    page: 564,
    areas: [
      [1021, 441, 1157, 570],
      [364, 605, 500, 734],
      [1463, 931, 1599, 1060],
      [163, 932, 299, 1061],
      [891, 1494, 1027, 1623],
      [167, 1495, 303, 1624],
      [848, 1657, 984, 1786],
      [164, 1656, 300, 1785],
      [1002, 1820, 1138, 1949],
      [478, 1821, 614, 1950],
      [348, 1984, 484, 2113],
      [1202, 2147, 1338, 2276],
      [453, 2146, 589, 2275],
      [1137, 2311, 1273, 2440],
      [575, 2310, 711, 2439],
      [1344, 2473, 1480, 2602],
      [698, 2472, 834, 2601],
      [1458, 2637, 1594, 2766],
      [168, 2637, 304, 2766],
    ],
  },
  {
    page: 565,
    areas: [
      [1052, 348, 1188, 477],
      [1088, 512, 1224, 641],
      [707, 511, 843, 640],
      [1244, 675, 1380, 804],
      [714, 674, 850, 803],
      [219, 674, 355, 803],
      [791, 838, 927, 967],
      [144, 837, 280, 966],
      [799, 1000, 935, 1129],
      [232, 1000, 368, 1129],
      [1008, 1164, 1144, 1293],
      [542, 1163, 678, 1292],
      [1127, 1327, 1263, 1456],
      [311, 1328, 447, 1457],
      [916, 1490, 1052, 1619],
      [283, 1490, 419, 1619],
      [601, 1653, 737, 1782],
      [902, 1818, 1038, 1947],
      [1478, 1981, 1614, 2110],
      [763, 1980, 899, 2109],
      [210, 1980, 346, 2109],
      [994, 2144, 1130, 2273],
      [437, 2142, 573, 2271],
      [482, 2306, 618, 2435],
      [1239, 2470, 1375, 2599],
      [144, 2470, 280, 2599],
      [150, 2634, 286, 2763],
    ],
  },
  {
    page: 566,
    areas: [
      [1475, 441, 1611, 570],
      [1250, 604, 1386, 733],
      [602, 605, 738, 734],
      [1106, 768, 1242, 897],
      [300, 767, 436, 896],
      [255, 931, 391, 1060],
      [441, 1094, 577, 1223],
      [1010, 1257, 1146, 1386],
      [732, 1420, 868, 1549],
      [151, 1420, 287, 1549],
      [1325, 1983, 1461, 2112],
      [997, 1981, 1133, 2110],
      [444, 1983, 580, 2112],
      [1122, 2148, 1258, 2277],
      [250, 2145, 386, 2274],
      [518, 2309, 654, 2438],
      [865, 2635, 1001, 2764],
      [154, 2636, 290, 2765],
    ],
  },
  {
    page: 567,
    areas: [
      [462, 348, 598, 477],
      [892, 511, 1028, 640],
      [1479, 673, 1615, 802],
      [516, 675, 652, 804],
      [1187, 836, 1323, 965],
      [144, 837, 280, 966],
      [945, 1001, 1081, 1130],
      [1487, 1164, 1623, 1293],
      [1483, 1327, 1619, 1456],
      [497, 1326, 633, 1455],
      [585, 1489, 721, 1618],
      [1297, 1653, 1433, 1782],
      [701, 1654, 837, 1783],
      [144, 1653, 280, 1782],
      [1148, 1816, 1284, 1945],
      [1327, 1979, 1463, 2108],
      [1488, 2143, 1624, 2272],
      [948, 2143, 1084, 2272],
      [341, 2144, 477, 2273],
      [1269, 2307, 1405, 2436],
      [743, 2306, 879, 2435],
      [348, 2306, 484, 2435],
      [1360, 2469, 1496, 2598],
      [245, 2469, 381, 2598],
      [941, 2632, 1077, 2761],
      [137, 2632, 273, 2761],
    ],
  },
  {
    page: 568,
    areas: [
      [959, 279, 1095, 408],
      [354, 279, 490, 408],
      [1176, 441, 1312, 570],
      [609, 442, 745, 571],
      [162, 441, 298, 570],
      [1010, 606, 1146, 735],
      [155, 605, 291, 734],
      [822, 770, 958, 899],
      [240, 768, 376, 897],
      [928, 932, 1064, 1061],
      [383, 932, 519, 1061],
      [1249, 1096, 1385, 1225],
      [451, 1096, 587, 1225],
      [1287, 1260, 1423, 1389],
      [540, 1259, 676, 1388],
      [1267, 1423, 1403, 1552],
      [782, 1422, 918, 1551],
      [156, 1423, 292, 1552],
      [952, 1986, 1088, 2115],
      [302, 1986, 438, 2115],
      [1045, 2150, 1181, 2279],
      [685, 2312, 821, 2441],
      [156, 2313, 292, 2442],
      [1095, 2476, 1231, 2605],
      [725, 2476, 861, 2605],
      [1469, 2639, 1605, 2768],
      [809, 2639, 945, 2768],
      [156, 2640, 292, 2769],
    ],
  },
  {
    page: 569,
    areas: [
      [151, 347, 287, 476],
      [1034, 512, 1170, 641],
      [470, 512, 606, 641],
      [1133, 675, 1269, 804],
      [722, 676, 858, 805],
      [285, 675, 421, 804],
      [1184, 839, 1320, 968],
      [782, 838, 918, 967],
      [1479, 1001, 1615, 1130],
      [909, 1003, 1045, 1132],
      [236, 1002, 372, 1131],
      [1269, 1166, 1405, 1295],
      [452, 1165, 588, 1294],
      [1075, 1329, 1211, 1458],
      [516, 1329, 652, 1458],
      [1263, 1492, 1399, 1621],
      [376, 1492, 512, 1621],
      [1122, 1655, 1258, 1784],
      [336, 1657, 472, 1786],
      [433, 1820, 569, 1949],
      [914, 1982, 1050, 2111],
      [1470, 2147, 1606, 2276],
      [799, 2146, 935, 2275],
      [1477, 2309, 1613, 2438],
      [792, 2309, 928, 2438],
      [1474, 2472, 1610, 2601],
      [740, 2471, 876, 2600],
      [997, 2637, 1133, 2766],
      [152, 2636, 288, 2765],
    ],
  },
  {
    page: 570,
    areas: [
      [587, 278, 723, 407],
      [1070, 443, 1206, 572],
      [1283, 605, 1419, 734],
      [1467, 769, 1603, 898],
      [157, 767, 293, 896],
      [1195, 1496, 1331, 1625],
      [432, 1496, 568, 1625],
      [952, 1659, 1088, 1788],
      [1465, 1986, 1601, 2115],
      [619, 1986, 755, 2115],
      [1339, 2150, 1475, 2279],
      [1462, 2476, 1598, 2605],
      [768, 2476, 904, 2605],
      [1221, 2640, 1357, 2769],
      [158, 2640, 294, 2769],
    ],
  },
  {
    page: 571,
    areas: [
      [831, 349, 967, 478],
      [800, 512, 936, 641],
      [167, 511, 303, 640],
      [1020, 676, 1156, 805],
      [1324, 838, 1460, 967],
      [169, 839, 305, 968],
      [790, 1003, 926, 1132],
      [1301, 1165, 1437, 1294],
      [474, 1165, 610, 1294],
      [1177, 1329, 1313, 1458],
      [939, 1491, 1075, 1620],
      [314, 1492, 450, 1621],
      [1308, 1820, 1444, 1949],
      [169, 1818, 305, 1947],
      [1183, 2146, 1319, 2275],
      [1331, 2312, 1467, 2441],
      [1230, 2474, 1366, 2603],
      [166, 2637, 302, 2766],
    ],
  },
  {
    page: 572,
    areas: [
      [1358, 843, 1494, 972],
      [172, 842, 308, 971],
      [450, 1006, 586, 1135],
      [789, 1169, 925, 1298],
      [1037, 1333, 1173, 1462],
      [942, 1497, 1078, 1626],
      [1252, 1660, 1388, 1789],
      [1116, 1824, 1252, 1953],
      [696, 1986, 832, 2115],
      [606, 2150, 742, 2279],
      [733, 2313, 869, 2442],
      [726, 2477, 862, 2606],
      [170, 2640, 306, 2769],
    ],
  },
  {
    page: 573,
    areas: [
      [1171, 512, 1307, 641],
      [164, 511, 300, 640],
      [350, 674, 486, 803],
      [285, 838, 421, 967],
      [629, 1001, 765, 1130],
      [780, 1165, 916, 1294],
      [1265, 1329, 1401, 1458],
      [329, 1329, 465, 1458],
      [341, 1492, 477, 1621],
      [1032, 1819, 1168, 1948],
      [717, 1983, 853, 2112],
      [637, 2145, 773, 2274],
      [915, 2309, 1051, 2438],
      [607, 2473, 743, 2602],
      [160, 2637, 296, 2766],
    ],
  },
  {
    page: 574,
    areas: [
      [1175, 678, 1311, 807],
      [710, 678, 846, 807],
      [1471, 842, 1607, 971],
      [668, 841, 804, 970],
      [1338, 1005, 1474, 1134],
      [378, 1005, 514, 1134],
      [1046, 1169, 1182, 1298],
      [156, 1169, 292, 1298],
      [360, 1332, 496, 1461],
      [587, 1495, 723, 1624],
      [871, 1660, 1007, 1789],
      [160, 1659, 296, 1788],
      [821, 1823, 957, 1952],
      [904, 1986, 1040, 2115],
      [717, 2150, 853, 2279],
      [993, 2313, 1129, 2442],
      [1162, 2478, 1298, 2607],
      [162, 2476, 298, 2605],
      [158, 2638, 294, 2767],
    ],
  },
  {
    page: 575,
    areas: [
      [143, 1261, 279, 1390],
      [1216, 1823, 1352, 1952],
      [917, 1823, 1053, 1952],
      [535, 1823, 671, 1952],
      [139, 1824, 275, 1953],
      [1172, 1986, 1308, 2115],
      [637, 1986, 773, 2115],
      [144, 1986, 280, 2115],
      [1106, 2148, 1242, 2277],
      [463, 2150, 599, 2279],
      [1291, 2313, 1427, 2442],
      [554, 2313, 690, 2442],
      [1309, 2476, 1445, 2605],
      [909, 2476, 1045, 2605],
      [333, 2475, 469, 2604],
      [1327, 2640, 1463, 2769],
      [656, 2639, 792, 2768],
      [134, 2640, 270, 2769],
    ],
  },
  {
    page: 576,
    areas: [
      [1182, 349, 1318, 478],
      [755, 349, 891, 478],
      [292, 350, 428, 479],
      [1473, 512, 1609, 641],
      [1063, 512, 1199, 641],
      [593, 511, 729, 640],
      [1377, 676, 1513, 805],
      [780, 676, 916, 805],
      [363, 678, 499, 807],
      [1328, 838, 1464, 967],
      [894, 839, 1030, 968],
      [528, 838, 664, 967],
      [1476, 1001, 1612, 1130],
      [253, 1656, 389, 1785],
      [1333, 1818, 1469, 1947],
      [934, 1820, 1070, 1949],
      [448, 1819, 584, 1948],
      [1355, 1982, 1491, 2111],
      [1017, 1982, 1153, 2111],
      [222, 1982, 358, 2111],
      [1014, 2145, 1150, 2274],
      [546, 2146, 682, 2275],
      [1475, 2310, 1611, 2439],
      [1056, 2309, 1192, 2438],
      [487, 2310, 623, 2439],
      [1271, 2472, 1407, 2601],
      [605, 2473, 741, 2602],
      [1251, 2636, 1387, 2765],
      [653, 2637, 789, 2766],
      [155, 2636, 291, 2765],
    ],
  },
  {
    page: 577,
    areas: [
      [818, 278, 954, 407],
      [1490, 441, 1626, 570],
      [847, 441, 983, 570],
      [337, 443, 473, 572],
      [602, 605, 738, 734],
      [1288, 769, 1424, 898],
      [721, 769, 857, 898],
      [141, 767, 277, 896],
      [141, 932, 277, 1061],
      [1011, 1494, 1147, 1623],
      [336, 1493, 472, 1622],
      [958, 1657, 1094, 1786],
      [207, 1657, 343, 1786],
      [950, 1818, 1086, 1947],
      [412, 1820, 548, 1949],
      [1489, 1984, 1625, 2113],
      [1050, 1984, 1186, 2113],
      [516, 1984, 652, 2113],
      [1313, 2147, 1449, 2276],
      [954, 2146, 1090, 2275],
      [393, 2147, 529, 2276],
      [1056, 2311, 1192, 2440],
      [326, 2309, 462, 2438],
      [1295, 2472, 1431, 2601],
      [487, 2473, 623, 2602],
      [1305, 2634, 1441, 2763],
      [683, 2634, 819, 2763],
      [137, 2636, 273, 2765],
    ],
  },
  {
    page: 578,
    areas: [
      [1091, 279, 1227, 408],
      [641, 279, 777, 408],
      [141, 278, 277, 407],
      [1224, 442, 1360, 571],
      [693, 442, 829, 571],
      [141, 442, 277, 571],
      [1094, 609, 1230, 738],
      [718, 608, 854, 737],
      [300, 605, 436, 734],
      [1182, 770, 1318, 899],
      [511, 768, 647, 897],
      [1487, 931, 1623, 1060],
      [977, 932, 1113, 1061],
      [324, 931, 460, 1060],
      [1373, 1095, 1509, 1224],
      [894, 1094, 1030, 1223],
      [137, 1095, 273, 1224],
      [987, 1258, 1123, 1387],
      [343, 1257, 479, 1386],
      [993, 1422, 1129, 1551],
      [138, 1421, 274, 1550],
      [140, 1984, 276, 2113],
      [1333, 2310, 1469, 2439],
      [140, 2311, 276, 2440],
      [213, 2472, 349, 2601],
      [143, 2636, 279, 2765],
    ],
  },
  {
    page: 579,
    areas: [
      [591, 348, 727, 477],
      [974, 511, 1110, 640],
      [1193, 675, 1329, 804],
      [1472, 839, 1608, 968],
      [593, 838, 729, 967],
      [869, 1003, 1005, 1132],
      [1474, 1166, 1610, 1295],
      [151, 1166, 287, 1295],
      [566, 1328, 702, 1457],
      [862, 1492, 998, 1621],
      [153, 1493, 289, 1622],
      [648, 1655, 784, 1784],
      [1472, 1820, 1608, 1949],
      [1477, 1982, 1613, 2111],
      [592, 1983, 728, 2112],
      [1320, 2309, 1456, 2438],
      [222, 2310, 358, 2439],
      [762, 2472, 898, 2601],
      [1031, 2636, 1167, 2765],
      [147, 2636, 283, 2765],
    ],
  },
  {
    page: 580,
    areas: [
      [335, 278, 471, 407],
      [277, 442, 413, 571],
      [1471, 769, 1607, 898],
      [154, 769, 290, 898],
      [154, 933, 290, 1062],
      [152, 1097, 288, 1226],
      [1123, 1660, 1259, 1789],
      [601, 1660, 737, 1789],
      [153, 1659, 289, 1788],
      [1167, 1823, 1303, 1952],
      [689, 1823, 825, 1952],
      [275, 1822, 411, 1951],
      [1167, 1986, 1303, 2115],
      [584, 1986, 720, 2115],
      [1471, 2149, 1607, 2278],
      [961, 2149, 1097, 2278],
      [468, 2151, 604, 2280],
      [1470, 2313, 1606, 2442],
      [1063, 2313, 1199, 2442],
      [372, 2313, 508, 2442],
      [1211, 2476, 1347, 2605],
      [662, 2477, 798, 2606],
      [1472, 2640, 1608, 2769],
      [762, 2639, 898, 2768],
      [154, 2641, 290, 2770],
    ],
  },
  {
    page: 581,
    areas: [
      [942, 349, 1078, 478],
      [324, 349, 460, 478],
      [1332, 514, 1468, 643],
      [729, 511, 865, 640],
      [148, 513, 284, 642],
      [1004, 676, 1140, 805],
      [561, 675, 697, 804],
      [788, 839, 924, 968],
      [149, 839, 285, 968],
      [753, 1002, 889, 1131],
      [1346, 1166, 1482, 1295],
      [574, 1165, 710, 1294],
      [1296, 1330, 1432, 1459],
      [756, 1328, 892, 1457],
      [150, 1328, 286, 1457],
      [1051, 1492, 1187, 1621],
      [370, 1492, 506, 1621],
      [1240, 1655, 1376, 1784],
      [340, 1656, 476, 1785],
      [1076, 1819, 1212, 1948],
      [503, 1819, 639, 1948],
      [1166, 1983, 1302, 2112],
      [582, 1983, 718, 2112],
      [1091, 2147, 1227, 2276],
      [381, 2145, 517, 2274],
      [1239, 2310, 1375, 2439],
      [413, 2309, 549, 2438],
      [1168, 2473, 1304, 2602],
      [270, 2472, 406, 2601],
      [1082, 2636, 1218, 2765],
      [149, 2635, 285, 2764],
    ],
  },
  {
    page: 582,
    areas: [
      [1208, 672, 1344, 801],
      [718, 679, 854, 808],
      [147, 679, 283, 808],
      [1215, 842, 1351, 971],
      [775, 842, 911, 971],
      [146, 842, 282, 971],
      [1149, 1006, 1285, 1135],
      [627, 1005, 763, 1134],
      [1477, 1169, 1613, 1298],
      [921, 1168, 1057, 1297],
      [336, 1169, 472, 1298],
      [1014, 1330, 1150, 1459],
      [350, 1332, 486, 1461],
      [921, 1496, 1057, 1625],
      [340, 1495, 476, 1624],
      [1349, 1659, 1485, 1788],
      [614, 1659, 750, 1788],
      [1169, 1822, 1305, 1951],
      [354, 1822, 490, 1951],
      [1019, 1985, 1155, 2114],
      [352, 1985, 488, 2114],
      [1374, 2149, 1510, 2278],
      [832, 2150, 968, 2279],
      [1481, 2312, 1617, 2441],
      [928, 2312, 1064, 2441],
      [468, 2312, 604, 2441],
      [1075, 2476, 1211, 2605],
      [439, 2476, 575, 2605],
      [1020, 2639, 1156, 2768],
      [144, 2639, 280, 2768],
    ],
  },
  {
    page: 583,
    areas: [
      [1115, 279, 1251, 408],
      [679, 278, 815, 407],
      [276, 278, 412, 407],
      [1339, 441, 1475, 570],
      [580, 443, 716, 572],
      [1325, 606, 1461, 735],
      [1235, 769, 1371, 898],
      [686, 933, 822, 1062],
      [901, 1096, 1037, 1225],
      [145, 1261, 281, 1390],
      [1146, 1823, 1282, 1952],
      [597, 1823, 733, 1952],
      [1476, 1986, 1612, 2115],
      [983, 1986, 1119, 2115],
      [533, 1986, 669, 2115],
      [1476, 2150, 1612, 2279],
      [1021, 2150, 1157, 2279],
      [401, 2150, 537, 2279],
      [1299, 2313, 1435, 2442],
      [385, 2313, 521, 2442],
      [1182, 2477, 1318, 2606],
      [483, 2477, 619, 2606],
      [1325, 2641, 1461, 2770],
      [797, 2639, 933, 2768],
      [150, 2641, 286, 2770],
    ],
  },
  {
    page: 584,
    areas: [
      [806, 349, 942, 478],
      [139, 350, 275, 479],
      [975, 511, 1111, 640],
      [284, 511, 420, 640],
      [1177, 675, 1313, 804],
      [716, 675, 852, 804],
      [316, 675, 452, 804],
      [1351, 839, 1487, 968],
      [768, 840, 904, 969],
      [1486, 1002, 1622, 1131],
      [777, 1003, 913, 1132],
      [1486, 1166, 1622, 1295],
      [907, 1165, 1043, 1294],
      [139, 1166, 275, 1295],
      [892, 1329, 1028, 1458],
      [137, 1330, 273, 1459],
      [1138, 1493, 1274, 1622],
      [529, 1492, 665, 1621],
      [1295, 1655, 1431, 1784],
      [534, 1655, 670, 1784],
      [1304, 1820, 1440, 1949],
      [905, 1819, 1041, 1948],
      [383, 1820, 519, 1949],
      [1235, 1982, 1371, 2111],
      [1493, 2146, 1629, 2275],
      [852, 2146, 988, 2275],
      [1490, 2310, 1626, 2439],
      [971, 2310, 1107, 2439],
      [482, 2310, 618, 2439],
      [1226, 2473, 1362, 2602],
      [135, 2472, 271, 2601],
    ],
  },
  {
    page: 585,
    areas: [
      [1251, 511, 1387, 640],
      [782, 511, 918, 640],
      [188, 511, 324, 640],
      [1010, 675, 1146, 804],
      [594, 674, 730, 803],
      [120, 676, 256, 805],
      [1133, 839, 1269, 968],
      [578, 839, 714, 968],
      [222, 839, 358, 968],
      [1299, 1000, 1435, 1129],
      [872, 1001, 1008, 1130],
      [457, 1001, 593, 1130],
      [1507, 1164, 1643, 1293],
      [1045, 1165, 1181, 1294],
      [669, 1164, 805, 1293],
      [364, 1166, 500, 1295],
      [1331, 1328, 1467, 1457],
      [820, 1328, 956, 1457],
      [198, 1328, 334, 1457],
      [1209, 1493, 1345, 1622],
      [754, 1492, 890, 1621],
      [286, 1492, 422, 1621],
      [1229, 1656, 1365, 1785],
      [539, 1656, 675, 1785],
      [1505, 1819, 1641, 1948],
      [920, 1819, 1056, 1948],
      [504, 1818, 640, 1947],
      [121, 1819, 257, 1948],
      [1198, 1983, 1334, 2112],
      [766, 1982, 902, 2111],
      [346, 1981, 482, 2110],
      [1265, 2144, 1401, 2273],
      [711, 2144, 847, 2273],
      [122, 2145, 258, 2274],
      [1236, 2309, 1372, 2438],
      [747, 2309, 883, 2438],
      [1394, 2471, 1530, 2600],
      [848, 2472, 984, 2601],
      [273, 2471, 409, 2600],
      [1143, 2634, 1279, 2763],
      [752, 2635, 888, 2764],
      [120, 2635, 256, 2764],
    ],
  },
  {
    page: 586,
    areas: [
      [1081, 665, 1217, 794],
      [426, 665, 562, 794],
      [1316, 828, 1452, 957],
      [669, 827, 805, 956],
      [1488, 991, 1624, 1120],
      [871, 991, 1007, 1120],
      [258, 991, 394, 1120],
      [1109, 1154, 1245, 1283],
      [617, 1155, 753, 1284],
      [1490, 1318, 1626, 1447],
      [941, 1318, 1077, 1447],
      [375, 1318, 511, 1447],
      [1328, 1481, 1464, 1610],
      [660, 1482, 796, 1611],
      [139, 1482, 275, 1611],
      [1176, 1646, 1312, 1775],
      [658, 1645, 794, 1774],
      [136, 1645, 272, 1774],
      [1035, 1809, 1171, 1938],
      [267, 1808, 403, 1937],
      [1321, 1972, 1457, 2101],
      [687, 1972, 823, 2101],
      [1488, 2135, 1624, 2264],
      [809, 2134, 945, 2263],
      [138, 2134, 274, 2263],
      [1207, 2298, 1343, 2427],
      [539, 2298, 675, 2427],
      [1304, 2460, 1440, 2589],
      [137, 2462, 273, 2591],
    ],
  },
  {
    page: 587,
    areas: [
      [1001, 451, 1137, 580],
      [391, 450, 527, 579],
      [1285, 613, 1421, 742],
      [733, 613, 869, 742],
      [1278, 776, 1414, 905],
      [273, 776, 409, 905],
      [894, 941, 1030, 1070],
      [139, 939, 275, 1068],
      [946, 1103, 1082, 1232],
      [302, 1103, 438, 1232],
      [1310, 1266, 1446, 1395],
      [798, 1267, 934, 1396],
      [239, 1266, 375, 1395],
      [1060, 1430, 1196, 1559],
      [536, 1431, 672, 1560],
      [1457, 1593, 1593, 1722],
      [799, 1594, 935, 1723],
      [1453, 1758, 1589, 1887],
      [140, 1758, 276, 1887],
      [1094, 2310, 1230, 2439],
      [152, 2308, 288, 2437],
      [689, 2472, 825, 2601],
      [1265, 2637, 1401, 2766],
      [906, 2636, 1042, 2765],
      [153, 2635, 289, 2764],
    ],
  },
  {
    page: 588,
    areas: [
      [764, 349, 900, 478],
      [258, 350, 394, 479],
      [1345, 511, 1481, 640],
      [764, 512, 900, 641],
      [152, 512, 288, 641],
      [771, 676, 907, 805],
      [1297, 841, 1433, 970],
      [331, 840, 467, 969],
      [898, 1001, 1034, 1130],
      [302, 1002, 438, 1131],
      [910, 1165, 1046, 1294],
      [1472, 1329, 1608, 1458],
      [900, 1327, 1036, 1456],
      [501, 1328, 637, 1457],
      [1471, 1491, 1607, 1620],
      [932, 1492, 1068, 1621],
      [359, 1493, 495, 1622],
      [936, 1656, 1072, 1785],
      [154, 1655, 290, 1784],
      [360, 1818, 496, 1947],
      [1244, 1982, 1380, 2111],
      [434, 1982, 570, 2111],
      [508, 2145, 644, 2274],
      [1231, 2308, 1367, 2437],
      [157, 2309, 293, 2438],
      [581, 2473, 717, 2602],
      [1246, 2635, 1382, 2764],
      [155, 2636, 291, 2765],
    ],
  },
  {
    page: 589,
    areas: [
      [995, 279, 1131, 408],
      [151, 279, 287, 408],
      [1081, 843, 1217, 972],
      [556, 842, 692, 971],
      [1481, 1006, 1617, 1135],
      [889, 1007, 1025, 1136],
      [322, 1005, 458, 1134],
      [491, 1168, 627, 1297],
      [1140, 1332, 1276, 1461],
      [337, 1332, 473, 1461],
      [1097, 1496, 1233, 1625],
      [327, 1495, 463, 1624],
      [1235, 1659, 1371, 1788],
      [798, 1658, 934, 1787],
      [148, 1659, 284, 1788],
      [1066, 1822, 1202, 1951],
      [374, 1821, 510, 1950],
      [1272, 1986, 1408, 2115],
      [709, 1986, 845, 2115],
      [149, 1985, 285, 2114],
      [959, 2149, 1095, 2278],
      [1479, 2476, 1615, 2605],
      [388, 2160, 524, 2289],
      [770, 2321, 906, 2450],
      [787, 2475, 923, 2604],
      [146, 2476, 282, 2605],
      [153, 2639, 289, 2768],
    ],
  },
  {
    page: 590,
    areas: [
      [1036, 623, 1172, 752],
      [552, 623, 688, 752],
      [1475, 786, 1611, 915],
      [861, 785, 997, 914],
      [377, 787, 513, 916],
      [1351, 950, 1487, 1079],
      [390, 950, 526, 1079],
      [531, 1113, 667, 1242],
      [407, 1276, 543, 1405],
      [1140, 1603, 1276, 1732],
      [367, 1766, 503, 1895],
      [1212, 1930, 1348, 2059],
      [647, 1930, 783, 2059],
      [153, 1929, 289, 2058],
      [1093, 2094, 1229, 2223],
      [680, 2094, 816, 2223],
      [1474, 2257, 1610, 2386],
      [1044, 2257, 1180, 2386],
      [329, 2258, 465, 2387],
      [1162, 2420, 1298, 2549],
      [626, 2420, 762, 2549],
      [161, 2420, 297, 2549],
    ],
  },
  {
    page: 591,
    areas: [
      [1176, 463, 1312, 592],
      [654, 463, 790, 592],
      [257, 464, 393, 593],
      [1072, 627, 1208, 756],
      [443, 626, 579, 755],
      [1379, 791, 1515, 920],
      [646, 790, 782, 919],
      [146, 791, 282, 920],
      [1177, 953, 1313, 1082],
      [593, 953, 729, 1082],
      [140, 954, 276, 1083],
      [1034, 1117, 1170, 1246],
      [599, 1117, 735, 1246],
      [228, 1118, 364, 1247],
      [1211, 1280, 1347, 1409],
      [849, 1280, 985, 1409],
      [145, 1279, 281, 1408],
      [997, 1800, 1133, 1929],
      [524, 1801, 660, 1930],
      [1480, 1963, 1616, 2092],
      [918, 1965, 1054, 2094],
      [374, 1964, 510, 2093],
      [1281, 2129, 1417, 2258],
      [339, 2128, 475, 2257],
      [1307, 2292, 1443, 2421],
      [650, 2292, 786, 2421],
      [146, 2291, 282, 2420],
      [1077, 2455, 1213, 2584],
      [411, 2454, 547, 2583],
      [1207, 2619, 1343, 2748],
      [736, 2619, 872, 2748],
      [145, 2618, 281, 2747],
    ],
  },
  {
    page: 592,
    areas: [
      [846, 279, 982, 408],
      [241, 277, 377, 406],
      [802, 442, 938, 571],
      [152, 441, 288, 570],
      [824, 1005, 960, 1134],
      [164, 1005, 300, 1134],
      [1178, 1168, 1314, 1297],
      [709, 1169, 845, 1298],
      [156, 1169, 292, 1298],
      [839, 1333, 975, 1462],
      [155, 1332, 291, 1461],
      [1046, 1495, 1182, 1624],
      [578, 1495, 714, 1624],
      [150, 1495, 286, 1624],
      [1068, 1659, 1204, 1788],
      [623, 1658, 759, 1787],
      [154, 1659, 290, 1788],
      [1097, 1821, 1233, 1950],
      [604, 1821, 740, 1950],
      [149, 1820, 285, 1949],
      [556, 1986, 692, 2115],
      [1325, 2149, 1461, 2278],
      [583, 2148, 719, 2277],
      [1251, 2311, 1387, 2440],
      [507, 2311, 643, 2440],
      [1238, 2475, 1374, 2604],
      [655, 2474, 791, 2603],
      [1286, 2638, 1422, 2767],
      [789, 2639, 925, 2768],
      [152, 2639, 288, 2768],
    ],
  },
  {
    page: 593,
    areas: [
      [1307, 679, 1443, 808],
      [924, 677, 1060, 806],
      [477, 678, 613, 807],
      [1467, 841, 1603, 970],
      [740, 841, 876, 970],
      [1467, 1005, 1603, 1134],
      [961, 1005, 1097, 1134],
      [158, 1005, 294, 1134],
      [712, 1169, 848, 1298],
      [159, 1169, 295, 1298],
      [973, 1333, 1109, 1462],
      [337, 1332, 473, 1461],
      [855, 1496, 991, 1625],
      [262, 1496, 398, 1625],
      [1461, 1822, 1597, 1951],
      [165, 1822, 301, 1951],
      [858, 1986, 994, 2115],
      [1222, 2149, 1358, 2278],
      [171, 2149, 307, 2278],
      [809, 2312, 945, 2441],
      [1386, 2475, 1522, 2604],
      [522, 2475, 658, 2604],
      [160, 2639, 296, 2768],
    ],
  },
  {
    page: 594,
    areas: [
      [886, 334, 1022, 463],
      [149, 333, 285, 462],
      [1021, 497, 1157, 626],
      [324, 497, 460, 626],
      [1014, 662, 1150, 791],
      [536, 661, 672, 790],
      [154, 662, 290, 791],
      [1016, 1148, 1152, 1277],
      [452, 1149, 588, 1278],
      [1470, 1311, 1606, 1440],
      [730, 1312, 866, 1441],
      [1374, 1475, 1510, 1604],
      [664, 1474, 800, 1603],
      [1476, 1638, 1612, 1767],
      [924, 1637, 1060, 1766],
      [357, 1636, 493, 1765],
      [1274, 1800, 1410, 1929],
      [750, 1800, 886, 1929],
      [153, 1801, 289, 1930],
      [1277, 1964, 1413, 2093],
      [513, 1964, 649, 2093],
      [1476, 2127, 1612, 2256],
      [886, 2127, 1022, 2256],
      [890, 2290, 1026, 2419],
      [296, 2291, 432, 2420],
      [659, 2454, 795, 2583],
      [154, 2453, 290, 2582],
    ],
  },
  {
    page: 595,
    areas: [
      [1096, 462, 1232, 591],
      [624, 463, 760, 592],
      [143, 463, 279, 592],
      [1083, 627, 1219, 756],
      [575, 626, 711, 755],
      [1483, 790, 1619, 919],
      [945, 790, 1081, 919],
      [236, 789, 372, 918],
      [1116, 953, 1252, 1082],
      [460, 953, 596, 1082],
      [1245, 1117, 1381, 1246],
      [655, 1115, 791, 1244],
      [1003, 1279, 1139, 1408],
      [692, 1443, 828, 1572],
      [142, 1444, 278, 1573],
      [1172, 1940, 1308, 2069],
      [730, 1941, 866, 2070],
      [147, 1940, 283, 2069],
      [1167, 2104, 1303, 2233],
      [625, 2103, 761, 2232],
      [143, 2103, 279, 2232],
      [1088, 2267, 1224, 2396],
      [482, 2267, 618, 2396],
      [1484, 2430, 1620, 2559],
      [966, 2430, 1102, 2559],
      [306, 2430, 442, 2559],
      [1325, 2594, 1461, 2723],
      [704, 2594, 840, 2723],
      [148, 2593, 284, 2722],
    ],
  },
  {
    page: 596,
    areas: [
      [984, 347, 1120, 476],
      [454, 349, 590, 478],
      [1277, 512, 1413, 641],
      [640, 511, 776, 640],
      [1211, 675, 1347, 804],
      [569, 675, 705, 804],
      [171, 674, 307, 803],
      [1234, 1163, 1370, 1292],
      [764, 1162, 900, 1291],
      [174, 1162, 310, 1291],
      [789, 1325, 925, 1454],
      [1284, 1488, 1420, 1617],
      [557, 1487, 693, 1616],
      [1256, 1652, 1392, 1781],
      [618, 1652, 754, 1781],
      [1452, 1817, 1588, 1946],
      [833, 1817, 969, 1946],
      [172, 1816, 308, 1945],
      [980, 2311, 1116, 2440],
      [300, 2312, 436, 2441],
      [1173, 2480, 1309, 2609],
      [712, 2478, 848, 2607],
      [229, 2478, 365, 2607],
      [1180, 2642, 1316, 2771],
      [651, 2640, 787, 2769],
      [164, 2640, 300, 2769],
    ],
  },
  {
    page: 597,
    areas: [
      [1140, 672, 1276, 801],
      [739, 672, 875, 801],
      [144, 672, 280, 801],
      [727, 835, 863, 964],
      [1488, 998, 1624, 1127],
      [140, 997, 276, 1126],
      [909, 1162, 1045, 1291],
      [150, 1161, 286, 1290],
      [944, 1659, 1080, 1788],
      [360, 1659, 496, 1788],
      [1338, 1823, 1474, 1952],
      [871, 1821, 1007, 1950],
      [289, 1822, 425, 1951],
      [1191, 1985, 1327, 2114],
      [760, 1985, 896, 2114],
      [267, 1986, 403, 2115],
      [1266, 2148, 1402, 2277],
      [864, 2149, 1000, 2278],
      [267, 2150, 403, 2279],
      [1332, 2312, 1468, 2441],
      [753, 2312, 889, 2441],
      [271, 2312, 407, 2441],
      [1014, 2475, 1150, 2604],
      [450, 2475, 586, 2604],
      [1474, 2638, 1610, 2767],
      [1028, 2638, 1164, 2767],
      [155, 2638, 291, 2767],
    ],
  },
  {
    page: 598,
    areas: [
      [871, 672, 1007, 801],
      [171, 672, 307, 801],
      [746, 834, 882, 963],
      [869, 998, 1005, 1127],
      [171, 998, 307, 1127],
      [1041, 1657, 1177, 1786],
      [175, 1659, 311, 1788],
      [1066, 1822, 1202, 1951],
      [974, 1984, 1110, 2113],
      [1268, 2312, 1404, 2441],
      [322, 2475, 458, 2604],
      [171, 2638, 307, 2767],
    ],
  },
  {
    page: 599,
    areas: [
      [147, 511, 283, 640],
      [834, 998, 970, 1127],
      [1478, 1160, 1614, 1289],
      [871, 1162, 1007, 1291],
      [149, 1162, 285, 1291],
      [954, 1325, 1090, 1454],
      [1046, 1488, 1182, 1617],
      [1314, 1651, 1450, 1780],
      [153, 1652, 289, 1781],
      [1049, 2148, 1185, 2277],
      [530, 2149, 666, 2278],
      [1480, 2312, 1616, 2441],
      [1020, 2312, 1156, 2441],
      [497, 2312, 633, 2441],
      [1162, 2474, 1298, 2603],
      [473, 2475, 609, 2604],
      [1143, 2638, 1279, 2767],
      [151, 2638, 287, 2767],
    ],
  },
  {
    page: 600,
    areas: [
      [937, 349, 1073, 478],
      [167, 348, 303, 477],
      [1164, 835, 1300, 964],
      [737, 834, 873, 963],
      [1461, 998, 1597, 1127],
      [164, 998, 300, 1127],
      [302, 1161, 438, 1290],
      [868, 1324, 1004, 1453],
      [1461, 1489, 1597, 1618],
      [644, 1489, 780, 1618],
      [1363, 1651, 1499, 1780],
      [724, 1652, 860, 1781],
      [263, 1653, 399, 1782],
      [1049, 2148, 1185, 2277],
      [462, 2148, 598, 2277],
      [1246, 2313, 1382, 2442],
      [554, 2312, 690, 2441],
      [1157, 2476, 1293, 2605],
      [487, 2476, 623, 2605],
      [1097, 2638, 1233, 2767],
      [172, 2639, 308, 2768],
    ],
  },
  {
    page: 601,
    areas: [
      [1286, 657, 1422, 786],
      [582, 657, 718, 786],
      [135, 821, 271, 950],
      [862, 1318, 998, 1447],
      [139, 1318, 275, 1447],
      [873, 1481, 1009, 1610],
      [136, 1481, 272, 1610],
      [931, 1645, 1067, 1774],
      [368, 1644, 504, 1773],
      [1200, 1808, 1336, 1937],
      [615, 1808, 751, 1937],
      [137, 1809, 273, 1938],
      [542, 2306, 678, 2435],
      [1224, 2469, 1360, 2598],
      [388, 2470, 524, 2599],
      [981, 2631, 1117, 2760],
      [132, 2633, 268, 2762],
    ],
  },
  {
    page: 602,
    areas: [
      [1022, 664, 1158, 793],
      [1465, 827, 1601, 956],
      [635, 828, 771, 957],
      [449, 992, 585, 1121],
      [646, 1489, 782, 1618],
      [1076, 1652, 1212, 1781],
      [166, 1652, 302, 1781],
      [943, 1816, 1079, 1945],
      [1459, 1980, 1595, 2109],
      [793, 1980, 929, 2109],
      [168, 1979, 304, 2108],
      [820, 2476, 956, 2605],
      [166, 2476, 302, 2605],
      [464, 2639, 600, 2768],
    ],
  },
  {
    page: 603,
    areas: [
      [816, 659, 952, 788],
      [166, 658, 302, 787],
      [804, 822, 940, 951],
      [168, 822, 304, 951],
      [811, 986, 947, 1115],
      [173, 985, 309, 1114],
      [650, 1482, 786, 1611],
      [665, 1646, 801, 1775],
      [400, 1810, 536, 1939],
      [795, 2307, 931, 2436],
      [1159, 2471, 1295, 2600],
      [433, 2471, 569, 2600],
      [984, 2636, 1120, 2765],
      [167, 2634, 303, 2763],
    ],
  },
  {
    page: 604,
    areas: [
      [969, 677, 1105, 806],
      [448, 679, 584, 808],
      [1117, 843, 1253, 972],
      [180, 844, 316, 973],
      [866, 1341, 1002, 1470],
      [334, 1339, 470, 1468],
      [899, 1504, 1035, 1633],
      [1125, 1668, 1261, 1797],
      [288, 1669, 424, 1798],
      [882, 2164, 1018, 2293],
      [345, 2164, 481, 2293],
      [1235, 2327, 1371, 2456],
      [335, 2328, 471, 2457],
      [368, 2491, 504, 2620],
      [498, 2654, 634, 2783],
    ],
  },
];

module.exports = areas;
