import React from "react";
import { useHistory } from "react-router-dom";
import surahs from "../utility/surahs";
import { Card } from 'semantic-ui-react';
import juz from "../utility/juz";

function Home() {
  let history = useHistory();
  const travel = (
    to_page
  ) => {
    history.push("/test")
    window.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      "/#/page?page_no=" +
      to_page +
      "&translation=" 
  };




  return (
    <React.Fragment>
      <div className="home-container">
        <div className="surahwrapper">
          <Card.Group>

            {
              surahs.map((surahBrowse) => {

                return (
                  <Card onClick={()=>{
                    travel(surahBrowse.page)
                  }}>
                    <Card.Content>
                      <Card.Header>{surahBrowse.text}</Card.Header>
                      <Card.Meta>Starts on page {surahBrowse.page}</Card.Meta>
                    </Card.Content>
                  </Card>
                )
              })
            }
          </Card.Group>
        </div>

        <h1>Smart Mushaf</h1>
        <h3>Read the Mushaf in an interactive way</h3>
        <div>
          <h4>History</h4>
          <p>
            Many muslims would like to read the Holy Qur'an from their electronic
            devices. There are many websites and apps that have been efficiently
            performing this purpose. However, when it comes to memorizing the Holy
            Qur'an, there are  few resources that provides Mushaf with high
            resolution pages and with the functionality of an app. The Majeedi
            script (Indo/Pak script) Mushafs typically have some of these helpful
            printing style implmeneted (such as horizontal line to separate
            ayahs), however it is rare to find Madina script with similar
            features. After many attempts to find an electronic version of the
            Mushaf that gives the feeling of reading from a printed Mushaf yet
            the functionality of a digital app, Smart Mushaf app was evolved.
          </p>
        </div>
        <br />
        <div>
          <h4>UI Elements</h4>
          <p>
            Smart Mushaf was created with the implementation of giving the user
            the feeling of reading from a physical Mushaf. In this app, the
            Madina script vector files were used to generate high resolution
            pictures. Visual styles were applied to each ayah numbers. Various
            translations of different languages and various reciters have been
            added. In addition, an auto scroll feature has been implemented
            where the ayah will be played with translation shown and the app
            will auto scroll to the next ayah.
          </p>
        </div>
        <br />
        <div>
          <h4>How this app can help to memorize the Qur'an</h4>
          <p>
            The app uses Madina Script 15 lines version. Whether on a mobile
            device or a large monitor the App is responsive. Therefore the lines
            begins and ends at the same regardless of the screen size. This is a
            recommendation from many users who have sucessfully memorized the
            Qur'an
          </p>
        </div>
        <br />
      </div>
    </React.Fragment>
  );
}

export default Home;
