const page = [
  { text: "1 (Al-Faatiha:1)", surah_number: 1, value: 1, key: 1 },
  { text: "2 (Al-Baqara:1)", surah_number: 2, value: 2, key: 2 },
  { text: "3 (Al-Baqara:6)", surah_number: 2, value: 3, key: 3 },
  { text: "4 (Al-Baqara:17)", surah_number: 2, value: 4, key: 4 },
  { text: "5 (Al-Baqara:25)", surah_number: 2, value: 5, key: 5 },
  { text: "6 (Al-Baqara:30)", surah_number: 2, value: 6, key: 6 },
  { text: "7 (Al-Baqara:38)", surah_number: 2, value: 7, key: 7 },
  { text: "8 (Al-Baqara:49)", surah_number: 2, value: 8, key: 8 },
  { text: "9 (Al-Baqara:58)", surah_number: 2, value: 9, key: 9 },
  { text: "10 (Al-Baqara:62)", surah_number: 2, value: 10, key: 10 },
  { text: "11 (Al-Baqara:70)", surah_number: 2, value: 11, key: 11 },
  { text: "12 (Al-Baqara:77)", surah_number: 2, value: 12, key: 12 },
  { text: "13 (Al-Baqara:84)", surah_number: 2, value: 13, key: 13 },
  { text: "14 (Al-Baqara:89)", surah_number: 2, value: 14, key: 14 },
  { text: "15 (Al-Baqara:94)", surah_number: 2, value: 15, key: 15 },
  { text: "16 (Al-Baqara:102)", surah_number: 2, value: 16, key: 16 },
  { text: "17 (Al-Baqara:106)", surah_number: 2, value: 17, key: 17 },
  { text: "18 (Al-Baqara:113)", surah_number: 2, value: 18, key: 18 },
  { text: "19 (Al-Baqara:120)", surah_number: 2, value: 19, key: 19 },
  { text: "20 (Al-Baqara:127)", surah_number: 2, value: 20, key: 20 },
  { text: "21 (Al-Baqara:135)", surah_number: 2, value: 21, key: 21 },
  { text: "22 (Al-Baqara:142)", surah_number: 2, value: 22, key: 22 },
  { text: "23 (Al-Baqara:146)", surah_number: 2, value: 23, key: 23 },
  { text: "24 (Al-Baqara:154)", surah_number: 2, value: 24, key: 24 },
  { text: "25 (Al-Baqara:164)", surah_number: 2, value: 25, key: 25 },
  { text: "26 (Al-Baqara:170)", surah_number: 2, value: 26, key: 26 },
  { text: "27 (Al-Baqara:177)", surah_number: 2, value: 27, key: 27 },
  { text: "28 (Al-Baqara:182)", surah_number: 2, value: 28, key: 28 },
  { text: "29 (Al-Baqara:187)", surah_number: 2, value: 29, key: 29 },
  { text: "30 (Al-Baqara:191)", surah_number: 2, value: 30, key: 30 },
  { text: "31 (Al-Baqara:197)", surah_number: 2, value: 31, key: 31 },
  { text: "32 (Al-Baqara:203)", surah_number: 2, value: 32, key: 32 },
  { text: "33 (Al-Baqara:211)", surah_number: 2, value: 33, key: 33 },
  { text: "34 (Al-Baqara:216)", surah_number: 2, value: 34, key: 34 },
  { text: "35 (Al-Baqara:220)", surah_number: 2, value: 35, key: 35 },
  { text: "36 (Al-Baqara:225)", surah_number: 2, value: 36, key: 36 },
  { text: "37 (Al-Baqara:231)", surah_number: 2, value: 37, key: 37 },
  { text: "38 (Al-Baqara:234)", surah_number: 2, value: 38, key: 38 },
  { text: "39 (Al-Baqara:238)", surah_number: 2, value: 39, key: 39 },
  { text: "40 (Al-Baqara:246)", surah_number: 2, value: 40, key: 40 },
  { text: "41 (Al-Baqara:249)", surah_number: 2, value: 41, key: 41 },
  { text: "42 (Al-Baqara:253)", surah_number: 2, value: 42, key: 42 },
  { text: "43 (Al-Baqara:257)", surah_number: 2, value: 43, key: 43 },
  { text: "44 (Al-Baqara:260)", surah_number: 2, value: 44, key: 44 },
  { text: "45 (Al-Baqara:265)", surah_number: 2, value: 45, key: 45 },
  { text: "46 (Al-Baqara:270)", surah_number: 2, value: 46, key: 46 },
  { text: "47 (Al-Baqara:275)", surah_number: 2, value: 47, key: 47 },
  { text: "48 (Al-Baqara:282)", surah_number: 2, value: 48, key: 48 },
  { text: "49 (Al-Baqara:283)", surah_number: 2, value: 49, key: 49 },
  { text: "50 (Aal-i-Imraan:1)", surah_number: 3, value: 50, key: 50 },
  { text: "51 (Aal-i-Imraan:10)", surah_number: 3, value: 51, key: 51 },
  { text: "52 (Aal-i-Imraan:16)", surah_number: 3, value: 52, key: 52 },
  { text: "53 (Aal-i-Imraan:23)", surah_number: 3, value: 53, key: 53 },
  { text: "54 (Aal-i-Imraan:30)", surah_number: 3, value: 54, key: 54 },
  { text: "55 (Aal-i-Imraan:38)", surah_number: 3, value: 55, key: 55 },
  { text: "56 (Aal-i-Imraan:46)", surah_number: 3, value: 56, key: 56 },
  { text: "57 (Aal-i-Imraan:53)", surah_number: 3, value: 57, key: 57 },
  { text: "58 (Aal-i-Imraan:62)", surah_number: 3, value: 58, key: 58 },
  { text: "59 (Aal-i-Imraan:71)", surah_number: 3, value: 59, key: 59 },
  { text: "60 (Aal-i-Imraan:78)", surah_number: 3, value: 60, key: 60 },
  { text: "61 (Aal-i-Imraan:84)", surah_number: 3, value: 61, key: 61 },
  { text: "62 (Aal-i-Imraan:92)", surah_number: 3, value: 62, key: 62 },
  { text: "63 (Aal-i-Imraan:101)", surah_number: 3, value: 63, key: 63 },
  { text: "64 (Aal-i-Imraan:109)", surah_number: 3, value: 64, key: 64 },
  { text: "65 (Aal-i-Imraan:116)", surah_number: 3, value: 65, key: 65 },
  { text: "66 (Aal-i-Imraan:122)", surah_number: 3, value: 66, key: 66 },
  { text: "67 (Aal-i-Imraan:133)", surah_number: 3, value: 67, key: 67 },
  { text: "68 (Aal-i-Imraan:141)", surah_number: 3, value: 68, key: 68 },
  { text: "69 (Aal-i-Imraan:149)", surah_number: 3, value: 69, key: 69 },
  { text: "70 (Aal-i-Imraan:154)", surah_number: 3, value: 70, key: 70 },
  { text: "71 (Aal-i-Imraan:158)", surah_number: 3, value: 71, key: 71 },
  { text: "72 (Aal-i-Imraan:166)", surah_number: 3, value: 72, key: 72 },
  { text: "73 (Aal-i-Imraan:174)", surah_number: 3, value: 73, key: 73 },
  { text: "74 (Aal-i-Imraan:181)", surah_number: 3, value: 74, key: 74 },
  { text: "75 (Aal-i-Imraan:187)", surah_number: 3, value: 75, key: 75 },
  { text: "76 (Aal-i-Imraan:195)", surah_number: 3, value: 76, key: 76 },
  { text: "77 (An-Nisaa:1)", surah_number: 4, value: 77, key: 77 },
  { text: "78 (An-Nisaa:7)", surah_number: 4, value: 78, key: 78 },
  { text: "79 (An-Nisaa:12)", surah_number: 4, value: 79, key: 79 },
  { text: "80 (An-Nisaa:15)", surah_number: 4, value: 80, key: 80 },
  { text: "81 (An-Nisaa:20)", surah_number: 4, value: 81, key: 81 },
  { text: "82 (An-Nisaa:24)", surah_number: 4, value: 82, key: 82 },
  { text: "83 (An-Nisaa:27)", surah_number: 4, value: 83, key: 83 },
  { text: "84 (An-Nisaa:34)", surah_number: 4, value: 84, key: 84 },
  { text: "85 (An-Nisaa:38)", surah_number: 4, value: 85, key: 85 },
  { text: "86 (An-Nisaa:45)", surah_number: 4, value: 86, key: 86 },
  { text: "87 (An-Nisaa:52)", surah_number: 4, value: 87, key: 87 },
  { text: "88 (An-Nisaa:60)", surah_number: 4, value: 88, key: 88 },
  { text: "89 (An-Nisaa:66)", surah_number: 4, value: 89, key: 89 },
  { text: "90 (An-Nisaa:75)", surah_number: 4, value: 90, key: 90 },
  { text: "91 (An-Nisaa:80)", surah_number: 4, value: 91, key: 91 },
  { text: "92 (An-Nisaa:87)", surah_number: 4, value: 92, key: 92 },
  { text: "93 (An-Nisaa:92)", surah_number: 4, value: 93, key: 93 },
  { text: "94 (An-Nisaa:95)", surah_number: 4, value: 94, key: 94 },
  { text: "95 (An-Nisaa:102)", surah_number: 4, value: 95, key: 95 },
  { text: "96 (An-Nisaa:106)", surah_number: 4, value: 96, key: 96 },
  { text: "97 (An-Nisaa:114)", surah_number: 4, value: 97, key: 97 },
  { text: "98 (An-Nisaa:122)", surah_number: 4, value: 98, key: 98 },
  { text: "99 (An-Nisaa:128)", surah_number: 4, value: 99, key: 99 },
  { text: "100 (An-Nisaa:135)", surah_number: 4, value: 100, key: 100 },
  { text: "101 (An-Nisaa:141)", surah_number: 4, value: 101, key: 101 },
  { text: "102 (An-Nisaa:148)", surah_number: 4, value: 102, key: 102 },
  { text: "103 (An-Nisaa:155)", surah_number: 4, value: 103, key: 103 },
  { text: "104 (An-Nisaa:163)", surah_number: 4, value: 104, key: 104 },
  { text: "105 (An-Nisaa:171)", surah_number: 4, value: 105, key: 105 },
  { text: "106 (An-Nisaa:176)", surah_number: 4, value: 106, key: 106 },
  { text: "107 (Al-Maaida:3)", surah_number: 5, value: 107, key: 107 },
  { text: "108 (Al-Maaida:6)", surah_number: 5, value: 108, key: 108 },
  { text: "109 (Al-Maaida:10)", surah_number: 5, value: 109, key: 109 },
  { text: "110 (Al-Maaida:14)", surah_number: 5, value: 110, key: 110 },
  { text: "111 (Al-Maaida:18)", surah_number: 5, value: 111, key: 111 },
  { text: "112 (Al-Maaida:24)", surah_number: 5, value: 112, key: 112 },
  { text: "113 (Al-Maaida:32)", surah_number: 5, value: 113, key: 113 },
  { text: "114 (Al-Maaida:37)", surah_number: 5, value: 114, key: 114 },
  { text: "115 (Al-Maaida:42)", surah_number: 5, value: 115, key: 115 },
  { text: "116 (Al-Maaida:46)", surah_number: 5, value: 116, key: 116 },
  { text: "117 (Al-Maaida:51)", surah_number: 5, value: 117, key: 117 },
  { text: "118 (Al-Maaida:58)", surah_number: 5, value: 118, key: 118 },
  { text: "119 (Al-Maaida:65)", surah_number: 5, value: 119, key: 119 },
  { text: "120 (Al-Maaida:71)", surah_number: 5, value: 120, key: 120 },
  { text: "121 (Al-Maaida:77)", surah_number: 5, value: 121, key: 121 },
  { text: "122 (Al-Maaida:83)", surah_number: 5, value: 122, key: 122 },
  { text: "123 (Al-Maaida:90)", surah_number: 5, value: 123, key: 123 },
  { text: "124 (Al-Maaida:96)", surah_number: 5, value: 124, key: 124 },
  { text: "125 (Al-Maaida:104)", surah_number: 5, value: 125, key: 125 },
  { text: "126 (Al-Maaida:109)", surah_number: 5, value: 126, key: 126 },
  { text: "127 (Al-Maaida:114)", surah_number: 5, value: 127, key: 127 },
  { text: "128 (Al-An'aam:1)", surah_number: 6, value: 128, key: 128 },
  { text: "129 (Al-An'aam:9)", surah_number: 6, value: 129, key: 129 },
  { text: "130 (Al-An'aam:19)", surah_number: 6, value: 130, key: 130 },
  { text: "131 (Al-An'aam:28)", surah_number: 6, value: 131, key: 131 },
  { text: "132 (Al-An'aam:36)", surah_number: 6, value: 132, key: 132 },
  { text: "133 (Al-An'aam:45)", surah_number: 6, value: 133, key: 133 },
  { text: "134 (Al-An'aam:53)", surah_number: 6, value: 134, key: 134 },
  { text: "135 (Al-An'aam:60)", surah_number: 6, value: 135, key: 135 },
  { text: "136 (Al-An'aam:69)", surah_number: 6, value: 136, key: 136 },
  { text: "137 (Al-An'aam:74)", surah_number: 6, value: 137, key: 137 },
  { text: "138 (Al-An'aam:82)", surah_number: 6, value: 138, key: 138 },
  { text: "139 (Al-An'aam:91)", surah_number: 6, value: 139, key: 139 },
  { text: "140 (Al-An'aam:95)", surah_number: 6, value: 140, key: 140 },
  { text: "141 (Al-An'aam:102)", surah_number: 6, value: 141, key: 141 },
  { text: "142 (Al-An'aam:111)", surah_number: 6, value: 142, key: 142 },
  { text: "143 (Al-An'aam:119)", surah_number: 6, value: 143, key: 143 },
  { text: "144 (Al-An'aam:125)", surah_number: 6, value: 144, key: 144 },
  { text: "145 (Al-An'aam:132)", surah_number: 6, value: 145, key: 145 },
  { text: "146 (Al-An'aam:138)", surah_number: 6, value: 146, key: 146 },
  { text: "147 (Al-An'aam:143)", surah_number: 6, value: 147, key: 147 },
  { text: "148 (Al-An'aam:147)", surah_number: 6, value: 148, key: 148 },
  { text: "149 (Al-An'aam:152)", surah_number: 6, value: 149, key: 149 },
  { text: "150 (Al-An'aam:158)", surah_number: 6, value: 150, key: 150 },
  { text: "151 (Al-A'raaf:1)", surah_number: 7, value: 151, key: 151 },
  { text: "152 (Al-A'raaf:12)", surah_number: 7, value: 152, key: 152 },
  { text: "153 (Al-A'raaf:23)", surah_number: 7, value: 153, key: 153 },
  { text: "154 (Al-A'raaf:31)", surah_number: 7, value: 154, key: 154 },
  { text: "155 (Al-A'raaf:38)", surah_number: 7, value: 155, key: 155 },
  { text: "156 (Al-A'raaf:44)", surah_number: 7, value: 156, key: 156 },
  { text: "157 (Al-A'raaf:52)", surah_number: 7, value: 157, key: 157 },
  { text: "158 (Al-A'raaf:58)", surah_number: 7, value: 158, key: 158 },
  { text: "159 (Al-A'raaf:68)", surah_number: 7, value: 159, key: 159 },
  { text: "160 (Al-A'raaf:74)", surah_number: 7, value: 160, key: 160 },
  { text: "161 (Al-A'raaf:82)", surah_number: 7, value: 161, key: 161 },
  { text: "162 (Al-A'raaf:88)", surah_number: 7, value: 162, key: 162 },
  { text: "163 (Al-A'raaf:96)", surah_number: 7, value: 163, key: 163 },
  { text: "164 (Al-A'raaf:105)", surah_number: 7, value: 164, key: 164 },
  { text: "165 (Al-A'raaf:121)", surah_number: 7, value: 165, key: 165 },
  { text: "166 (Al-A'raaf:131)", surah_number: 7, value: 166, key: 166 },
  { text: "167 (Al-A'raaf:138)", surah_number: 7, value: 167, key: 167 },
  { text: "168 (Al-A'raaf:144)", surah_number: 7, value: 168, key: 168 },
  { text: "169 (Al-A'raaf:150)", surah_number: 7, value: 169, key: 169 },
  { text: "170 (Al-A'raaf:156)", surah_number: 7, value: 170, key: 170 },
  { text: "171 (Al-A'raaf:160)", surah_number: 7, value: 171, key: 171 },
  { text: "172 (Al-A'raaf:164)", surah_number: 7, value: 172, key: 172 },
  { text: "173 (Al-A'raaf:171)", surah_number: 7, value: 173, key: 173 },
  { text: "174 (Al-A'raaf:179)", surah_number: 7, value: 174, key: 174 },
  { text: "175 (Al-A'raaf:188)", surah_number: 7, value: 175, key: 175 },
  { text: "176 (Al-A'raaf:196)", surah_number: 7, value: 176, key: 176 },
  { text: "177 (Al-Anfaal:1)", surah_number: 8, value: 177, key: 177 },
  { text: "178 (Al-Anfaal:9)", surah_number: 8, value: 178, key: 178 },
  { text: "179 (Al-Anfaal:17)", surah_number: 8, value: 179, key: 179 },
  { text: "180 (Al-Anfaal:26)", surah_number: 8, value: 180, key: 180 },
  { text: "181 (Al-Anfaal:34)", surah_number: 8, value: 181, key: 181 },
  { text: "182 (Al-Anfaal:41)", surah_number: 8, value: 182, key: 182 },
  { text: "183 (Al-Anfaal:46)", surah_number: 8, value: 183, key: 183 },
  { text: "184 (Al-Anfaal:53)", surah_number: 8, value: 184, key: 184 },
  { text: "185 (Al-Anfaal:62)", surah_number: 8, value: 185, key: 185 },
  { text: "186 (Al-Anfaal:70)", surah_number: 8, value: 186, key: 186 },
  { text: "187 (At-Tawba:1)", surah_number: 9, value: 187, key: 187 },
  { text: "188 (At-Tawba:7)", surah_number: 9, value: 188, key: 188 },
  { text: "189 (At-Tawba:14)", surah_number: 9, value: 189, key: 189 },
  { text: "190 (At-Tawba:21)", surah_number: 9, value: 190, key: 190 },
  { text: "191 (At-Tawba:27)", surah_number: 9, value: 191, key: 191 },
  { text: "192 (At-Tawba:32)", surah_number: 9, value: 192, key: 192 },
  { text: "193 (At-Tawba:37)", surah_number: 9, value: 193, key: 193 },
  { text: "194 (At-Tawba:41)", surah_number: 9, value: 194, key: 194 },
  { text: "195 (At-Tawba:48)", surah_number: 9, value: 195, key: 195 },
  { text: "196 (At-Tawba:55)", surah_number: 9, value: 196, key: 196 },
  { text: "197 (At-Tawba:62)", surah_number: 9, value: 197, key: 197 },
  { text: "198 (At-Tawba:69)", surah_number: 9, value: 198, key: 198 },
  { text: "199 (At-Tawba:73)", surah_number: 9, value: 199, key: 199 },
  { text: "200 (At-Tawba:80)", surah_number: 9, value: 200, key: 200 },
  { text: "201 (At-Tawba:87)", surah_number: 9, value: 201, key: 201 },
  { text: "202 (At-Tawba:94)", surah_number: 9, value: 202, key: 202 },
  { text: "203 (At-Tawba:100)", surah_number: 9, value: 203, key: 203 },
  { text: "204 (At-Tawba:107)", surah_number: 9, value: 204, key: 204 },
  { text: "205 (At-Tawba:112)", surah_number: 9, value: 205, key: 205 },
  { text: "206 (At-Tawba:118)", surah_number: 9, value: 206, key: 206 },
  { text: "207 (At-Tawba:123)", surah_number: 9, value: 207, key: 207 },
  { text: "208 (Yunus:1)", surah_number: 10, value: 208, key: 208 },
  { text: "209 (Yunus:7)", surah_number: 10, value: 209, key: 209 },
  { text: "210 (Yunus:15)", surah_number: 10, value: 210, key: 210 },
  { text: "211 (Yunus:21)", surah_number: 10, value: 211, key: 211 },
  { text: "212 (Yunus:26)", surah_number: 10, value: 212, key: 212 },
  { text: "213 (Yunus:34)", surah_number: 10, value: 213, key: 213 },
  { text: "214 (Yunus:43)", surah_number: 10, value: 214, key: 214 },
  { text: "215 (Yunus:54)", surah_number: 10, value: 215, key: 215 },
  { text: "216 (Yunus:62)", surah_number: 10, value: 216, key: 216 },
  { text: "217 (Yunus:71)", surah_number: 10, value: 217, key: 217 },
  { text: "218 (Yunus:79)", surah_number: 10, value: 218, key: 218 },
  { text: "219 (Yunus:89)", surah_number: 10, value: 219, key: 219 },
  { text: "220 (Yunus:98)", surah_number: 10, value: 220, key: 220 },
  { text: "221 (Yunus:107)", surah_number: 10, value: 221, key: 221 },
  { text: "222 (Hud:6)", surah_number: 11, value: 222, key: 222 },
  { text: "223 (Hud:13)", surah_number: 11, value: 223, key: 223 },
  { text: "224 (Hud:20)", surah_number: 11, value: 224, key: 224 },
  { text: "225 (Hud:29)", surah_number: 11, value: 225, key: 225 },
  { text: "226 (Hud:38)", surah_number: 11, value: 226, key: 226 },
  { text: "227 (Hud:46)", surah_number: 11, value: 227, key: 227 },
  { text: "228 (Hud:54)", surah_number: 11, value: 228, key: 228 },
  { text: "229 (Hud:63)", surah_number: 11, value: 229, key: 229 },
  { text: "230 (Hud:72)", surah_number: 11, value: 230, key: 230 },
  { text: "231 (Hud:82)", surah_number: 11, value: 231, key: 231 },
  { text: "232 (Hud:89)", surah_number: 11, value: 232, key: 232 },
  { text: "233 (Hud:98)", surah_number: 11, value: 233, key: 233 },
  { text: "234 (Hud:109)", surah_number: 11, value: 234, key: 234 },
  { text: "235 (Hud:118)", surah_number: 11, value: 235, key: 235 },
  { text: "236 (Yusuf:5)", surah_number: 12, value: 236, key: 236 },
  { text: "237 (Yusuf:15)", surah_number: 12, value: 237, key: 237 },
  { text: "238 (Yusuf:23)", surah_number: 12, value: 238, key: 238 },
  { text: "239 (Yusuf:31)", surah_number: 12, value: 239, key: 239 },
  { text: "240 (Yusuf:38)", surah_number: 12, value: 240, key: 240 },
  { text: "241 (Yusuf:44)", surah_number: 12, value: 241, key: 241 },
  { text: "242 (Yusuf:53)", surah_number: 12, value: 242, key: 242 },
  { text: "243 (Yusuf:64)", surah_number: 12, value: 243, key: 243 },
  { text: "244 (Yusuf:70)", surah_number: 12, value: 244, key: 244 },
  { text: "245 (Yusuf:79)", surah_number: 12, value: 245, key: 245 },
  { text: "246 (Yusuf:87)", surah_number: 12, value: 246, key: 246 },
  { text: "247 (Yusuf:96)", surah_number: 12, value: 247, key: 247 },
  { text: "248 (Yusuf:104)", surah_number: 12, value: 248, key: 248 },
  { text: "249 (Ar-Ra'd:1)", surah_number: 13, value: 249, key: 249 },
  { text: "250 (Ar-Ra'd:6)", surah_number: 13, value: 250, key: 250 },
  { text: "251 (Ar-Ra'd:14)", surah_number: 13, value: 251, key: 251 },
  { text: "252 (Ar-Ra'd:19)", surah_number: 13, value: 252, key: 252 },
  { text: "253 (Ar-Ra'd:29)", surah_number: 13, value: 253, key: 253 },
  { text: "254 (Ar-Ra'd:35)", surah_number: 13, value: 254, key: 254 },
  { text: "255 (Ar-Ra'd:43)", surah_number: 13, value: 255, key: 255 },
  { text: "256 (Ibrahim:6)", surah_number: 14, value: 256, key: 256 },
  { text: "257 (Ibrahim:11)", surah_number: 14, value: 257, key: 257 },
  { text: "258 (Ibrahim:19)", surah_number: 14, value: 258, key: 258 },
  { text: "259 (Ibrahim:25)", surah_number: 14, value: 259, key: 259 },
  { text: "260 (Ibrahim:34)", surah_number: 14, value: 260, key: 260 },
  { text: "261 (Ibrahim:43)", surah_number: 14, value: 261, key: 261 },
  { text: "262 (Al-Hijr:1)", surah_number: 15, value: 262, key: 262 },
  { text: "263 (Al-Hijr:16)", surah_number: 15, value: 263, key: 263 },
  { text: "264 (Al-Hijr:32)", surah_number: 15, value: 264, key: 264 },
  { text: "265 (Al-Hijr:52)", surah_number: 15, value: 265, key: 265 },
  { text: "266 (Al-Hijr:71)", surah_number: 15, value: 266, key: 266 },
  { text: "267 (Al-Hijr:91)", surah_number: 15, value: 267, key: 267 },
  { text: "268 (An-Nahl:7)", surah_number: 16, value: 268, key: 268 },
  { text: "269 (An-Nahl:15)", surah_number: 16, value: 269, key: 269 },
  { text: "270 (An-Nahl:27)", surah_number: 16, value: 270, key: 270 },
  { text: "271 (An-Nahl:35)", surah_number: 16, value: 271, key: 271 },
  { text: "272 (An-Nahl:43)", surah_number: 16, value: 272, key: 272 },
  { text: "273 (An-Nahl:55)", surah_number: 16, value: 273, key: 273 },
  { text: "274 (An-Nahl:65)", surah_number: 16, value: 274, key: 274 },
  { text: "275 (An-Nahl:73)", surah_number: 16, value: 275, key: 275 },
  { text: "276 (An-Nahl:80)", surah_number: 16, value: 276, key: 276 },
  { text: "277 (An-Nahl:88)", surah_number: 16, value: 277, key: 277 },
  { text: "278 (An-Nahl:94)", surah_number: 16, value: 278, key: 278 },
  { text: "279 (An-Nahl:103)", surah_number: 16, value: 279, key: 279 },
  { text: "280 (An-Nahl:111)", surah_number: 16, value: 280, key: 280 },
  { text: "281 (An-Nahl:119)", surah_number: 16, value: 281, key: 281 },
  { text: "282 (Al-Israa:1)", surah_number: 17, value: 282, key: 282 },
  { text: "283 (Al-Israa:8)", surah_number: 17, value: 283, key: 283 },
  { text: "284 (Al-Israa:18)", surah_number: 17, value: 284, key: 284 },
  { text: "285 (Al-Israa:28)", surah_number: 17, value: 285, key: 285 },
  { text: "286 (Al-Israa:39)", surah_number: 17, value: 286, key: 286 },
  { text: "287 (Al-Israa:50)", surah_number: 17, value: 287, key: 287 },
  { text: "288 (Al-Israa:59)", surah_number: 17, value: 288, key: 288 },
  { text: "289 (Al-Israa:67)", surah_number: 17, value: 289, key: 289 },
  { text: "290 (Al-Israa:76)", surah_number: 17, value: 290, key: 290 },
  { text: "291 (Al-Israa:87)", surah_number: 17, value: 291, key: 291 },
  { text: "292 (Al-Israa:97)", surah_number: 17, value: 292, key: 292 },
  { text: "293 (Al-Israa:105)", surah_number: 17, value: 293, key: 293 },
  { text: "294 (Al-Kahf:5)", surah_number: 18, value: 294, key: 294 },
  { text: "295 (Al-Kahf:16)", surah_number: 18, value: 295, key: 295 },
  { text: "296 (Al-Kahf:21)", surah_number: 18, value: 296, key: 296 },
  { text: "297 (Al-Kahf:28)", surah_number: 18, value: 297, key: 297 },
  { text: "298 (Al-Kahf:35)", surah_number: 18, value: 298, key: 298 },
  { text: "299 (Al-Kahf:46)", surah_number: 18, value: 299, key: 299 },
  { text: "300 (Al-Kahf:54)", surah_number: 18, value: 300, key: 300 },
  { text: "301 (Al-Kahf:62)", surah_number: 18, value: 301, key: 301 },
  { text: "302 (Al-Kahf:75)", surah_number: 18, value: 302, key: 302 },
  { text: "303 (Al-Kahf:84)", surah_number: 18, value: 303, key: 303 },
  { text: "304 (Al-Kahf:98)", surah_number: 18, value: 304, key: 304 },
  { text: "305 (Maryam:1)", surah_number: 19, value: 305, key: 305 },
  { text: "306 (Maryam:12)", surah_number: 19, value: 306, key: 306 },
  { text: "307 (Maryam:26)", surah_number: 19, value: 307, key: 307 },
  { text: "308 (Maryam:39)", surah_number: 19, value: 308, key: 308 },
  { text: "309 (Maryam:52)", surah_number: 19, value: 309, key: 309 },
  { text: "310 (Maryam:65)", surah_number: 19, value: 310, key: 310 },
  { text: "311 (Maryam:77)", surah_number: 19, value: 311, key: 311 },
  { text: "312 (Maryam:96)", surah_number: 19, value: 312, key: 312 },
  { text: "313 (Taa-Haa:13)", surah_number: 20, value: 313, key: 313 },
  { text: "314 (Taa-Haa:38)", surah_number: 20, value: 314, key: 314 },
  { text: "315 (Taa-Haa:52)", surah_number: 20, value: 315, key: 315 },
  { text: "316 (Taa-Haa:65)", surah_number: 20, value: 316, key: 316 },
  { text: "317 (Taa-Haa:77)", surah_number: 20, value: 317, key: 317 },
  { text: "318 (Taa-Haa:88)", surah_number: 20, value: 318, key: 318 },
  { text: "319 (Taa-Haa:99)", surah_number: 20, value: 319, key: 319 },
  { text: "320 (Taa-Haa:114)", surah_number: 20, value: 320, key: 320 },
  { text: "321 (Taa-Haa:126)", surah_number: 20, value: 321, key: 321 },
  { text: "322 (Al-Anbiyaa:1)", surah_number: 21, value: 322, key: 322 },
  { text: "323 (Al-Anbiyaa:11)", surah_number: 21, value: 323, key: 323 },
  { text: "324 (Al-Anbiyaa:25)", surah_number: 21, value: 324, key: 324 },
  { text: "325 (Al-Anbiyaa:36)", surah_number: 21, value: 325, key: 325 },
  { text: "326 (Al-Anbiyaa:45)", surah_number: 21, value: 326, key: 326 },
  { text: "327 (Al-Anbiyaa:58)", surah_number: 21, value: 327, key: 327 },
  { text: "328 (Al-Anbiyaa:73)", surah_number: 21, value: 328, key: 328 },
  { text: "329 (Al-Anbiyaa:82)", surah_number: 21, value: 329, key: 329 },
  { text: "330 (Al-Anbiyaa:91)", surah_number: 21, value: 330, key: 330 },
  { text: "331 (Al-Anbiyaa:102)", surah_number: 21, value: 331, key: 331 },
  { text: "332 (Al-Hajj:1)", surah_number: 22, value: 332, key: 332 },
  { text: "333 (Al-Hajj:6)", surah_number: 22, value: 333, key: 333 },
  { text: "334 (Al-Hajj:16)", surah_number: 22, value: 334, key: 334 },
  { text: "335 (Al-Hajj:24)", surah_number: 22, value: 335, key: 335 },
  { text: "336 (Al-Hajj:31)", surah_number: 22, value: 336, key: 336 },
  { text: "337 (Al-Hajj:39)", surah_number: 22, value: 337, key: 337 },
  { text: "338 (Al-Hajj:47)", surah_number: 22, value: 338, key: 338 },
  { text: "339 (Al-Hajj:56)", surah_number: 22, value: 339, key: 339 },
  { text: "340 (Al-Hajj:65)", surah_number: 22, value: 340, key: 340 },
  { text: "341 (Al-Hajj:73)", surah_number: 22, value: 341, key: 341 },
  { text: "342 (Al-Muminoon:1)", surah_number: 23, value: 342, key: 342 },
  { text: "343 (Al-Muminoon:18)", surah_number: 23, value: 343, key: 343 },
  { text: "344 (Al-Muminoon:28)", surah_number: 23, value: 344, key: 344 },
  { text: "345 (Al-Muminoon:43)", surah_number: 23, value: 345, key: 345 },
  { text: "346 (Al-Muminoon:60)", surah_number: 23, value: 346, key: 346 },
  { text: "347 (Al-Muminoon:75)", surah_number: 23, value: 347, key: 347 },
  { text: "348 (Al-Muminoon:90)", surah_number: 23, value: 348, key: 348 },
  { text: "349 (Al-Muminoon:105)", surah_number: 23, value: 349, key: 349 },
  { text: "350 (An-Noor:1)", surah_number: 24, value: 350, key: 350 },
  { text: "351 (An-Noor:11)", surah_number: 24, value: 351, key: 351 },
  { text: "352 (An-Noor:21)", surah_number: 24, value: 352, key: 352 },
  { text: "353 (An-Noor:28)", surah_number: 24, value: 353, key: 353 },
  { text: "354 (An-Noor:32)", surah_number: 24, value: 354, key: 354 },
  { text: "355 (An-Noor:37)", surah_number: 24, value: 355, key: 355 },
  { text: "356 (An-Noor:44)", surah_number: 24, value: 356, key: 356 },
  { text: "357 (An-Noor:54)", surah_number: 24, value: 357, key: 357 },
  { text: "358 (An-Noor:59)", surah_number: 24, value: 358, key: 358 },
  { text: "359 (An-Noor:62)", surah_number: 24, value: 359, key: 359 },
  { text: "360 (Al-Furqaan:3)", surah_number: 25, value: 360, key: 360 },
  { text: "361 (Al-Furqaan:12)", surah_number: 25, value: 361, key: 361 },
  { text: "362 (Al-Furqaan:21)", surah_number: 25, value: 362, key: 362 },
  { text: "363 (Al-Furqaan:33)", surah_number: 25, value: 363, key: 363 },
  { text: "364 (Al-Furqaan:44)", surah_number: 25, value: 364, key: 364 },
  { text: "365 (Al-Furqaan:56)", surah_number: 25, value: 365, key: 365 },
  { text: "366 (Al-Furqaan:68)", surah_number: 25, value: 366, key: 366 },
  { text: "367 (Ash-Shu'araa:1)", surah_number: 26, value: 367, key: 367 },
  { text: "368 (Ash-Shu'araa:20)", surah_number: 26, value: 368, key: 368 },
  { text: "369 (Ash-Shu'araa:40)", surah_number: 26, value: 369, key: 369 },
  { text: "370 (Ash-Shu'araa:61)", surah_number: 26, value: 370, key: 370 },
  { text: "371 (Ash-Shu'araa:84)", surah_number: 26, value: 371, key: 371 },
  { text: "372 (Ash-Shu'araa:112)", surah_number: 26, value: 372, key: 372 },
  { text: "373 (Ash-Shu'araa:137)", surah_number: 26, value: 373, key: 373 },
  { text: "374 (Ash-Shu'araa:160)", surah_number: 26, value: 374, key: 374 },
  { text: "375 (Ash-Shu'araa:184)", surah_number: 26, value: 375, key: 375 },
  { text: "376 (Ash-Shu'araa:207)", surah_number: 26, value: 376, key: 376 },
  { text: "377 (An-Naml:1)", surah_number: 27, value: 377, key: 377 },
  { text: "378 (An-Naml:14)", surah_number: 27, value: 378, key: 378 },
  { text: "379 (An-Naml:23)", surah_number: 27, value: 379, key: 379 },
  { text: "380 (An-Naml:36)", surah_number: 27, value: 380, key: 380 },
  { text: "381 (An-Naml:45)", surah_number: 27, value: 381, key: 381 },
  { text: "382 (An-Naml:56)", surah_number: 27, value: 382, key: 382 },
  { text: "383 (An-Naml:64)", surah_number: 27, value: 383, key: 383 },
  { text: "384 (An-Naml:77)", surah_number: 27, value: 384, key: 384 },
  { text: "385 (An-Naml:89)", surah_number: 27, value: 385, key: 385 },
  { text: "386 (Al-Qasas:6)", surah_number: 28, value: 386, key: 386 },
  { text: "387 (Al-Qasas:14)", surah_number: 28, value: 387, key: 387 },
  { text: "388 (Al-Qasas:22)", surah_number: 28, value: 388, key: 388 },
  { text: "389 (Al-Qasas:29)", surah_number: 28, value: 389, key: 389 },
  { text: "390 (Al-Qasas:36)", surah_number: 28, value: 390, key: 390 },
  { text: "391 (Al-Qasas:44)", surah_number: 28, value: 391, key: 391 },
  { text: "392 (Al-Qasas:51)", surah_number: 28, value: 392, key: 392 },
  { text: "393 (Al-Qasas:60)", surah_number: 28, value: 393, key: 393 },
  { text: "394 (Al-Qasas:71)", surah_number: 28, value: 394, key: 394 },
  { text: "395 (Al-Qasas:78)", surah_number: 28, value: 395, key: 395 },
  { text: "396 (Al-Qasas:85)", surah_number: 28, value: 396, key: 396 },
  { text: "397 (Al-Ankaboot:7)", surah_number: 29, value: 397, key: 397 },
  { text: "398 (Al-Ankaboot:15)", surah_number: 29, value: 398, key: 398 },
  { text: "399 (Al-Ankaboot:24)", surah_number: 29, value: 399, key: 399 },
  { text: "400 (Al-Ankaboot:31)", surah_number: 29, value: 400, key: 400 },
  { text: "401 (Al-Ankaboot:39)", surah_number: 29, value: 401, key: 401 },
  { text: "402 (Al-Ankaboot:46)", surah_number: 29, value: 402, key: 402 },
  { text: "403 (Al-Ankaboot:53)", surah_number: 29, value: 403, key: 403 },
  { text: "404 (Al-Ankaboot:64)", surah_number: 29, value: 404, key: 404 },
  { text: "405 (Ar-Room:6)", surah_number: 30, value: 405, key: 405 },
  { text: "406 (Ar-Room:16)", surah_number: 30, value: 406, key: 406 },
  { text: "407 (Ar-Room:25)", surah_number: 30, value: 407, key: 407 },
  { text: "408 (Ar-Room:33)", surah_number: 30, value: 408, key: 408 },
  { text: "409 (Ar-Room:42)", surah_number: 30, value: 409, key: 409 },
  { text: "410 (Ar-Room:51)", surah_number: 30, value: 410, key: 410 },
  { text: "411 (Luqman:1)", surah_number: 31, value: 411, key: 411 },
  { text: "412 (Luqman:12)", surah_number: 31, value: 412, key: 412 },
  { text: "413 (Luqman:20)", surah_number: 31, value: 413, key: 413 },
  { text: "414 (Luqman:29)", surah_number: 31, value: 414, key: 414 },
  { text: "415 (As-Sajda:1)", surah_number: 32, value: 415, key: 415 },
  { text: "416 (As-Sajda:12)", surah_number: 32, value: 416, key: 416 },
  { text: "417 (As-Sajda:21)", surah_number: 32, value: 417, key: 417 },
  { text: "418 (Al-Ahzaab:1)", surah_number: 33, value: 418, key: 418 },
  { text: "419 (Al-Ahzaab:7)", surah_number: 33, value: 419, key: 419 },
  { text: "420 (Al-Ahzaab:16)", surah_number: 33, value: 420, key: 420 },
  { text: "421 (Al-Ahzaab:23)", surah_number: 33, value: 421, key: 421 },
  { text: "422 (Al-Ahzaab:31)", surah_number: 33, value: 422, key: 422 },
  { text: "423 (Al-Ahzaab:36)", surah_number: 33, value: 423, key: 423 },
  { text: "424 (Al-Ahzaab:44)", surah_number: 33, value: 424, key: 424 },
  { text: "425 (Al-Ahzaab:51)", surah_number: 33, value: 425, key: 425 },
  { text: "426 (Al-Ahzaab:55)", surah_number: 33, value: 426, key: 426 },
  { text: "427 (Al-Ahzaab:63)", surah_number: 33, value: 427, key: 427 },
  { text: "428 (Saba:1)", surah_number: 34, value: 428, key: 428 },
  { text: "429 (Saba:8)", surah_number: 34, value: 429, key: 429 },
  { text: "430 (Saba:15)", surah_number: 34, value: 430, key: 430 },
  { text: "431 (Saba:23)", surah_number: 34, value: 431, key: 431 },
  { text: "432 (Saba:32)", surah_number: 34, value: 432, key: 432 },
  { text: "433 (Saba:40)", surah_number: 34, value: 433, key: 433 },
  { text: "434 (Saba:49)", surah_number: 34, value: 434, key: 434 },
  { text: "435 (Faatir:4)", surah_number: 35, value: 435, key: 435 },
  { text: "436 (Faatir:12)", surah_number: 35, value: 436, key: 436 },
  { text: "437 (Faatir:19)", surah_number: 35, value: 437, key: 437 },
  { text: "438 (Faatir:31)", surah_number: 35, value: 438, key: 438 },
  { text: "439 (Faatir:39)", surah_number: 35, value: 439, key: 439 },
  { text: "440 (Faatir:45)", surah_number: 35, value: 440, key: 440 },
  { text: "441 (Yaseen:13)", surah_number: 36, value: 441, key: 441 },
  { text: "442 (Yaseen:28)", surah_number: 36, value: 442, key: 442 },
  { text: "443 (Yaseen:41)", surah_number: 36, value: 443, key: 443 },
  { text: "444 (Yaseen:55)", surah_number: 36, value: 444, key: 444 },
  { text: "445 (Yaseen:71)", surah_number: 36, value: 445, key: 445 },
  { text: "446 (As-Saaffaat:1)", surah_number: 37, value: 446, key: 446 },
  { text: "447 (As-Saaffaat:25)", surah_number: 37, value: 447, key: 447 },
  { text: "448 (As-Saaffaat:52)", surah_number: 37, value: 448, key: 448 },
  { text: "449 (As-Saaffaat:77)", surah_number: 37, value: 449, key: 449 },
  { text: "450 (As-Saaffaat:103)", surah_number: 37, value: 450, key: 450 },
  { text: "451 (As-Saaffaat:127)", surah_number: 37, value: 451, key: 451 },
  { text: "452 (As-Saaffaat:154)", surah_number: 37, value: 452, key: 452 },
  { text: "453 (Saad:1)", surah_number: 38, value: 453, key: 453 },
  { text: "454 (Saad:17)", surah_number: 38, value: 454, key: 454 },
  { text: "455 (Saad:27)", surah_number: 38, value: 455, key: 455 },
  { text: "456 (Saad:43)", surah_number: 38, value: 456, key: 456 },
  { text: "457 (Saad:62)", surah_number: 38, value: 457, key: 457 },
  { text: "458 (Saad:84)", surah_number: 38, value: 458, key: 458 },
  { text: "459 (Az-Zumar:6)", surah_number: 39, value: 459, key: 459 },
  { text: "460 (Az-Zumar:11)", surah_number: 39, value: 460, key: 460 },
  { text: "461 (Az-Zumar:22)", surah_number: 39, value: 461, key: 461 },
  { text: "462 (Az-Zumar:32)", surah_number: 39, value: 462, key: 462 },
  { text: "463 (Az-Zumar:41)", surah_number: 39, value: 463, key: 463 },
  { text: "464 (Az-Zumar:48)", surah_number: 39, value: 464, key: 464 },
  { text: "465 (Az-Zumar:57)", surah_number: 39, value: 465, key: 465 },
  { text: "466 (Az-Zumar:68)", surah_number: 39, value: 466, key: 466 },
  { text: "467 (Az-Zumar:75)", surah_number: 39, value: 467, key: 467 },
  { text: "468 (Ghafir:8)", surah_number: 40, value: 468, key: 468 },
  { text: "469 (Ghafir:17)", surah_number: 40, value: 469, key: 469 },
  { text: "470 (Ghafir:26)", surah_number: 40, value: 470, key: 470 },
  { text: "471 (Ghafir:34)", surah_number: 40, value: 471, key: 471 },
  { text: "472 (Ghafir:41)", surah_number: 40, value: 472, key: 472 },
  { text: "473 (Ghafir:50)", surah_number: 40, value: 473, key: 473 },
  { text: "474 (Ghafir:59)", surah_number: 40, value: 474, key: 474 },
  { text: "475 (Ghafir:67)", surah_number: 40, value: 475, key: 475 },
  { text: "476 (Ghafir:78)", surah_number: 40, value: 476, key: 476 },
  { text: "477 (Fussilat:1)", surah_number: 41, value: 477, key: 477 },
  { text: "478 (Fussilat:12)", surah_number: 41, value: 478, key: 478 },
  { text: "479 (Fussilat:21)", surah_number: 41, value: 479, key: 479 },
  { text: "480 (Fussilat:30)", surah_number: 41, value: 480, key: 480 },
  { text: "481 (Fussilat:39)", surah_number: 41, value: 481, key: 481 },
  { text: "482 (Fussilat:47)", surah_number: 41, value: 482, key: 482 },
  { text: "483 (Ash-Shura:1)", surah_number: 42, value: 483, key: 483 },
  { text: "484 (Ash-Shura:11)", surah_number: 42, value: 484, key: 484 },
  { text: "485 (Ash-Shura:16)", surah_number: 42, value: 485, key: 485 },
  { text: "486 (Ash-Shura:23)", surah_number: 42, value: 486, key: 486 },
  { text: "487 (Ash-Shura:32)", surah_number: 42, value: 487, key: 487 },
  { text: "488 (Ash-Shura:45)", surah_number: 42, value: 488, key: 488 },
  { text: "489 (Ash-Shura:52)", surah_number: 42, value: 489, key: 489 },
  { text: "490 (Az-Zukhruf:11)", surah_number: 43, value: 490, key: 490 },
  { text: "491 (Az-Zukhruf:23)", surah_number: 43, value: 491, key: 491 },
  { text: "492 (Az-Zukhruf:34)", surah_number: 43, value: 492, key: 492 },
  { text: "493 (Az-Zukhruf:48)", surah_number: 43, value: 493, key: 493 },
  { text: "494 (Az-Zukhruf:61)", surah_number: 43, value: 494, key: 494 },
  { text: "495 (Az-Zukhruf:74)", surah_number: 43, value: 495, key: 495 },
  { text: "496 (Ad-Dukhaan:1)", surah_number: 44, value: 496, key: 496 },
  { text: "497 (Ad-Dukhaan:19)", surah_number: 44, value: 497, key: 497 },
  { text: "498 (Ad-Dukhaan:40)", surah_number: 44, value: 498, key: 498 },
  { text: "499 (Al-Jaathiya:1)", surah_number: 45, value: 499, key: 499 },
  { text: "500 (Al-Jaathiya:14)", surah_number: 45, value: 500, key: 500 },
  { text: "501 (Al-Jaathiya:23)", surah_number: 45, value: 501, key: 501 },
  { text: "502 (Al-Jaathiya:33)", surah_number: 45, value: 502, key: 502 },
  { text: "503 (Al-Ahqaf:6)", surah_number: 46, value: 503, key: 503 },
  { text: "504 (Al-Ahqaf:15)", surah_number: 46, value: 504, key: 504 },
  { text: "505 (Al-Ahqaf:21)", surah_number: 46, value: 505, key: 505 },
  { text: "506 (Al-Ahqaf:29)", surah_number: 46, value: 506, key: 506 },
  { text: "507 (Muhammad:1)", surah_number: 47, value: 507, key: 507 },
  { text: "508 (Muhammad:12)", surah_number: 47, value: 508, key: 508 },
  { text: "509 (Muhammad:20)", surah_number: 47, value: 509, key: 509 },
  { text: "510 (Muhammad:30)", surah_number: 47, value: 510, key: 510 },
  { text: "511 (Al-Fath:1)", surah_number: 48, value: 511, key: 511 },
  { text: "512 (Al-Fath:10)", surah_number: 48, value: 512, key: 512 },
  { text: "513 (Al-Fath:16)", surah_number: 48, value: 513, key: 513 },
  { text: "514 (Al-Fath:24)", surah_number: 48, value: 514, key: 514 },
  { text: "515 (Al-Fath:29)", surah_number: 48, value: 515, key: 515 },
  { text: "516 (Al-Hujuraat:5)", surah_number: 49, value: 516, key: 516 },
  { text: "517 (Al-Hujuraat:12)", surah_number: 49, value: 517, key: 517 },
  { text: "518 (Qaaf:1)", surah_number: 50, value: 518, key: 518 },
  { text: "519 (Qaaf:16)", surah_number: 50, value: 519, key: 519 },
  { text: "520 (Qaaf:36)", surah_number: 50, value: 520, key: 520 },
  { text: "521 (Adh-Dhaariyat:7)", surah_number: 51, value: 521, key: 521 },
  { text: "522 (Adh-Dhaariyat:31)", surah_number: 51, value: 522, key: 522 },
  { text: "523 (Adh-Dhaariyat:52)", surah_number: 51, value: 523, key: 523 },
  { text: "524 (At-Tur:15)", surah_number: 52, value: 524, key: 524 },
  { text: "525 (At-Tur:32)", surah_number: 52, value: 525, key: 525 },
  { text: "526 (An-Najm:1)", surah_number: 53, value: 526, key: 526 },
  { text: "527 (An-Najm:27)", surah_number: 53, value: 527, key: 527 },
  { text: "528 (An-Najm:45)", surah_number: 53, value: 528, key: 528 },
  { text: "529 (Al-Qamar:7)", surah_number: 54, value: 529, key: 529 },
  { text: "530 (Al-Qamar:28)", surah_number: 54, value: 530, key: 530 },
  { text: "531 (Al-Qamar:50)", surah_number: 54, value: 531, key: 531 },
  { text: "532 (Ar-Rahmaan:17)", surah_number: 55, value: 532, key: 532 },
  { text: "533 (Ar-Rahmaan:41)", surah_number: 55, value: 533, key: 533 },
  { text: "534 (Ar-Rahmaan:68)", surah_number: 55, value: 534, key: 534 },
  { text: "535 (Al-Waaqia:17)", surah_number: 56, value: 535, key: 535 },
  { text: "536 (Al-Waaqia:51)", surah_number: 56, value: 536, key: 536 },
  { text: "537 (Al-Waaqia:77)", surah_number: 56, value: 537, key: 537 },
  { text: "538 (Al-Hadid:4)", surah_number: 57, value: 538, key: 538 },
  { text: "539 (Al-Hadid:12)", surah_number: 57, value: 539, key: 539 },
  { text: "540 (Al-Hadid:19)", surah_number: 57, value: 540, key: 540 },
  { text: "541 (Al-Hadid:25)", surah_number: 57, value: 541, key: 541 },
  { text: "542 (Al-Mujaadila:1)", surah_number: 58, value: 542, key: 542 },
  { text: "543 (Al-Mujaadila:7)", surah_number: 58, value: 543, key: 543 },
  { text: "544 (Al-Mujaadila:12)", surah_number: 58, value: 544, key: 544 },
  { text: "545 (Al-Mujaadila:22)", surah_number: 58, value: 545, key: 545 },
  { text: "546 (Al-Hashr:4)", surah_number: 59, value: 546, key: 546 },
  { text: "547 (Al-Hashr:10)", surah_number: 59, value: 547, key: 547 },
  { text: "548 (Al-Hashr:17)", surah_number: 59, value: 548, key: 548 },
  { text: "549 (Al-Mumtahana:1)", surah_number: 60, value: 549, key: 549 },
  { text: "550 (Al-Mumtahana:6)", surah_number: 60, value: 550, key: 550 },
  { text: "551 (Al-Mumtahana:12)", surah_number: 60, value: 551, key: 551 },
  { text: "552 (As-Saff:6)", surah_number: 61, value: 552, key: 552 },
  { text: "553 (Al-Jumu'a:1)", surah_number: 62, value: 553, key: 553 },
  { text: "554 (Al-Jumu'a:9)", surah_number: 62, value: 554, key: 554 },
  { text: "555 (Al-Munaafiqoon:5)", surah_number: 63, value: 555, key: 555 },
  { text: "556 (At-Taghaabun:1)", surah_number: 64, value: 556, key: 556 },
  { text: "557 (At-Taghaabun:10)", surah_number: 64, value: 557, key: 557 },
  { text: "558 (At-Talaaq:1)", surah_number: 65, value: 558, key: 558 },
  { text: "559 (At-Talaaq:6)", surah_number: 65, value: 559, key: 559 },
  { text: "560 (At-Tahrim:1)", surah_number: 66, value: 560, key: 560 },
  { text: "561 (At-Tahrim:8)", surah_number: 66, value: 561, key: 561 },
  { text: "562 (Al-Mulk:1)", surah_number: 67, value: 562, key: 562 },
  { text: "563 (Al-Mulk:13)", surah_number: 67, value: 563, key: 563 },
  { text: "564 (Al-Mulk:27)", surah_number: 67, value: 564, key: 564 },
  { text: "565 (Al-Qalam:16)", surah_number: 68, value: 565, key: 565 },
  { text: "566 (Al-Qalam:43)", surah_number: 68, value: 566, key: 566 },
  { text: "567 (Al-Haaqqa:9)", surah_number: 69, value: 567, key: 567 },
  { text: "568 (Al-Haaqqa:35)", surah_number: 69, value: 568, key: 568 },
  { text: "569 (Al-Ma'aarij:11)", surah_number: 70, value: 569, key: 569 },
  { text: "570 (Al-Ma'aarij:40)", surah_number: 70, value: 570, key: 570 },
  { text: "571 (Nooh:11)", surah_number: 71, value: 571, key: 571 },
  { text: "572 (Al-Jinn:1)", surah_number: 72, value: 572, key: 572 },
  { text: "573 (Al-Jinn:14)", surah_number: 72, value: 573, key: 573 },
  { text: "574 (Al-Muzzammil:1)", surah_number: 73, value: 574, key: 574 },
  { text: "575 (Al-Muzzammil:20)", surah_number: 73, value: 575, key: 575 },
  { text: "576 (Al-Muddaththir:18)", surah_number: 74, value: 576, key: 576 },
  { text: "577 (Al-Muddaththir:48)", surah_number: 74, value: 577, key: 577 },
  { text: "578 (Al-Qiyaama:20)", surah_number: 75, value: 578, key: 578 },
  { text: "579 (Al-Insaan:6)", surah_number: 76, value: 579, key: 579 },
  { text: "580 (Al-Insaan:26)", surah_number: 76, value: 580, key: 580 },
  { text: "581 (Al-Mursalaat:20)", surah_number: 77, value: 581, key: 581 },
  { text: "582 (An-Naba:1)", surah_number: 78, value: 582, key: 582 },
  { text: "583 (An-Naba:31)", surah_number: 78, value: 583, key: 583 },
  { text: "584 (An-Naazi'aat:16)", surah_number: 79, value: 584, key: 584 },
  { text: "585 (Abasa:1)", surah_number: 80, value: 585, key: 585 },
  { text: "586 (At-Takwir:1)", surah_number: 81, value: 586, key: 586 },
  { text: "587 (Al-Infitaar:1)", surah_number: 82, value: 587, key: 587 },
  { text: "588 (Al-Mutaffifin:7)", surah_number: 83, value: 588, key: 588 },
  { text: "589 (Al-Mutaffifin:35)", surah_number: 83, value: 589, key: 589 },
  { text: "590 (Al-Burooj:1)", surah_number: 85, value: 590, key: 590 },
  { text: "591 (At-Taariq:1)", surah_number: 86, value: 591, key: 591 },
  { text: "592 (Al-A'laa:16)", surah_number: 87, value: 592, key: 592 },
  { text: "593 (Al-Fajr:1)", surah_number: 89, value: 593, key: 593 },
  { text: "594 (Al-Fajr:24)", surah_number: 89, value: 594, key: 594 },
  { text: "595 (Ash-Shams:1)", surah_number: 91, value: 595, key: 595 },
  { text: "596 (Al-Lail:15)", surah_number: 92, value: 596, key: 596 },
  { text: "597 (At-Tin:1)", surah_number: 95, value: 597, key: 597 },
  { text: "598 (Al-Qadr:1)", surah_number: 97, value: 598, key: 598 },
  { text: "599 (Al-Bayyina:8)", surah_number: 98, value: 599, key: 599 },
  { text: "600 (Al-Aadiyaat:10)", surah_number: 100, value: 600, key: 600 },
  { text: "601 (Al-Asr:1)", surah_number: 103, value: 601, key: 601 },
  { text: "602 (Quraish:1)", surah_number: 106, value: 602, key: 602 },
  { text: "603 (Al-Kaafiroon:1)", surah_number: 109, value: 603, key: 603 },
  { text: "604 (Al-Ikhlaas:1)", surah_number: 112, value: 604, key: 604 },
];

module.exports = page;
