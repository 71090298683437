import React from "react";

import Body from "./Body";
import Disclaimer from "./Disclaimer"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Main() {
  return (
    <React.Fragment>
      <Switch>
          <Route path="/" component={Body} exact/>
          <Route path="/page" component={Body}/>
          <Route path="/disclaimer" component={Disclaimer}/>
      </Switch>
    </React.Fragment>
  );
}
export default Main;
